import request from "@/api/axios";
import { apiList as api } from "./apiList";

/**
 * 发送学科网组卷内容
 */
export function sendXkwCallback(openid, paperid, uuid) {
  return request({
    url: api.tpi.zujuan + '?openid=' + openid + '&paperid=' + paperid + '&uuid=' + uuid, // '/tpi/xkw/ejt/zujuan/callback?openid=' + openid + '&paperid=' + paperid,
    method: 'get'
  })
}
/**
 * 发送学科网组卷轮询
 */
export function syn_status(openid, paperid, uuid) {
  return request({
    url: api.tpi.syn_status + '?openid=' + openid + '&paperid=' + paperid + '&uuid=' + uuid, // '/tpi/xkw/ejt/zujuan/callback?openid=' + openid + '&paperid=' + paperid,
    method: 'get'
  })
}