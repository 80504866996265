import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, unref as _unref } from "vue";
import _imports_0 from '../icons/interactCard.png';
var _hoisted_1 = {
  class: "interact-card card media-card"
};
var _hoisted_2 = {
  class: "media-card-serial"
};
var _hoisted_3 = {
  key: 0,
  class: "media-card-title"
};
var _hoisted_4 = {
  class: "card-title"
};
var _hoisted_5 = {
  class: "interact-box"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  key: 0,
  class: "interact-name"
};
var _hoisted_8 = {
  class: "interact-name"
};
var _hoisted_9 = ["innerHTML"];
var _hoisted_10 = {
  class: "interact-btn"
};
import interactDetail from '../rzy/interactDetail';
import interactTool from '../rzy/interactTool';
/* 工具 */
import { ref, computed } from "vue";
import { MessagePlugin } from 'tdesign-vue-next';
/* 变量 */

export default {
  __name: 'interactCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 组件 */

    var interactData = computed(function () {
      return JSON.parse(__props.cardData.data.item) ? JSON.parse(__props.cardData.data.item).content : {};
    });
    var interactDetailShow = ref(false);
    var interactToolShow = ref(false);

    /* 方法 */
    var interactClick = function interactClick() {
      var _interactData$value = interactData.value,
        toolBusinessId = _interactData$value.toolBusinessId,
        outlineBusinessId = _interactData$value.outlineBusinessId;
      if (!toolBusinessId || !outlineBusinessId) {
        MessagePlugin.warning("当前互动工具暂未添加工具！");
        return;
      }
      interactDetailShow.value = true;
    };
    var intoToolClick = function intoToolClick() {
      interactToolShow.value = true;
    };
    return function (_ctx, _cache) {
      var _component_play_circle_outlined = _resolveComponent("play-circle-outlined");
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      var _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.cardData.sort), 1), __props.cardData.data.title && __props.cardData.data.title !== '互动工具卡' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(__props.cardData.data.title), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createElementVNode("img", {
        src: interactData.value.thumbnailOssUrl,
        alt: ""
      }, null, 8, _hoisted_6), _createElementVNode("div", {
        class: "mask",
        onClick: interactClick
      }, [_createVNode(_component_play_circle_outlined, {
        class: "play-icon"
      })]), interactData.value.name && interactData.value.name.length <= 5 ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(interactData.value.name), 1)) : (_openBlock(), _createBlock(_component_t_tooltip, {
        key: 1,
        content: interactData.value.name,
        placement: "top"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_8, _toDisplayString(interactData.value.name), 1)];
        }),
        _: 1
      }, 8, ["content"]))]), _createElementVNode("div", {
        class: "interact-remark",
        innerHTML: interactData.value.remarks
      }, null, 8, _hoisted_9)]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_t_button, {
        onClick: intoToolClick
      }, {
        default: _withCtx(function () {
          return _cache[3] || (_cache[3] = [_createTextVNode("进入工具")]);
        }),
        _: 1
      })]), interactDetailShow.value ? (_openBlock(), _createBlock(_unref(interactDetail), {
        key: 1,
        visible: interactDetailShow.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return interactDetailShow.value = $event;
        }),
        data: interactData.value
      }, null, 8, ["visible", "data"])) : _createCommentVNode("", true), interactToolShow.value ? (_openBlock(), _createBlock(_unref(interactTool), {
        key: 2,
        visible: interactToolShow.value,
        "onUpdate:visible": _cache[1] || (_cache[1] = function ($event) {
          return interactToolShow.value = $event;
        }),
        data: interactData.value
      }, null, 8, ["visible", "data"])) : _createCommentVNode("", true)]);
    };
  }
};