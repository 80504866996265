import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive } from "vue";

/* 变量 */

export default {
  __name: 'exportPracticeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:visible"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var emit = __emit;
    var props = __props;
    var state = reactive({
      direction: 1,
      size: 1,
      column: 1
    });

    /* 事件 */
    var handleSure = function handleSure() {
      console.log("导出习练");
      handleClose();
    };
    var handleClose = function handleClose() {
      state.direction = 1;
      state.size = 1;
      state.column = 1;
      emit("update:visible", false);
    };
    return function (_ctx, _cache) {
      var _component_t_radio = _resolveComponent("t-radio");
      var _component_t_radio_group = _resolveComponent("t-radio-group");
      var _component_t_form_item = _resolveComponent("t-form-item");
      var _component_t_form = _resolveComponent("t-form");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        class: "export-practice-dialog",
        visible: props.visible,
        header: "导出设置",
        width: "523px",
        onClose: handleClose,
        onConfirm: handleSure
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_form, {
            colon: ""
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_t_form_item, {
                label: "纸张方向",
                name: "direction"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_radio_group, {
                    modelValue: state.direction,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                      return state.direction = $event;
                    })
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_t_radio, {
                        value: 1
                      }, {
                        default: _withCtx(function () {
                          return _cache[3] || (_cache[3] = [_createTextVNode("纵向")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 2
                      }, {
                        default: _withCtx(function () {
                          return _cache[4] || (_cache[4] = [_createTextVNode("横向")]);
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }, 8, ["modelValue"])];
                }),
                _: 1
              }), _createVNode(_component_t_form_item, {
                label: "纸张大小",
                name: "size"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_radio_group, {
                    modelValue: state.size,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                      return state.size = $event;
                    })
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_t_radio, {
                        value: 1
                      }, {
                        default: _withCtx(function () {
                          return _cache[5] || (_cache[5] = [_createTextVNode("A3")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 2
                      }, {
                        default: _withCtx(function () {
                          return _cache[6] || (_cache[6] = [_createTextVNode("A4")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 3
                      }, {
                        default: _withCtx(function () {
                          return _cache[7] || (_cache[7] = [_createTextVNode("A5")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 4
                      }, {
                        default: _withCtx(function () {
                          return _cache[8] || (_cache[8] = [_createTextVNode("B4")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 5
                      }, {
                        default: _withCtx(function () {
                          return _cache[9] || (_cache[9] = [_createTextVNode("B5")]);
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }, 8, ["modelValue"])];
                }),
                _: 1
              }), _createVNode(_component_t_form_item, {
                label: "栏数",
                name: "column"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_radio_group, {
                    modelValue: state.column,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
                      return state.column = $event;
                    })
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_t_radio, {
                        value: 1
                      }, {
                        default: _withCtx(function () {
                          return _cache[10] || (_cache[10] = [_createTextVNode("一栏")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 2
                      }, {
                        default: _withCtx(function () {
                          return _cache[11] || (_cache[11] = [_createTextVNode("二栏")]);
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }, 8, ["modelValue"])];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};