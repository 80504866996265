import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = ["onClick"];
import { ref } from "vue";

/* 变量 */

export default {
  __name: 'actionBox',
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    hoverBtns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    hoverAll: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var isHover = ref(false);
    var props = __props;

    /* 事件 */
    var defaultChangeHover = function defaultChangeHover(bool) {
      if (props.hoverAll) {
        isHover.value = bool;
      }
    };
    var hoverBtnClick = function hoverBtnClick(btn) {
      if (btn.disable) return;
      btn.clickEvent && btn.clickEvent();
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["action-box", {
          'show-border': isHover.value && !props.isPreview
        }])
      }, [!props.isPreview ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["btns", {
          'show-btns': isHover.value
        }]),
        onMouseenter: _cache[0] || (_cache[0] = function ($event) {
          return isHover.value = true;
        }),
        onMouseleave: _cache[1] || (_cache[1] = function ($event) {
          return isHover.value = false;
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.hoverBtns, function (btn, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass({
            'btn-disable': btn.disable
          }),
          onClick: function onClick($event) {
            return hoverBtnClick(btn);
          }
        }, _toDisplayString(btn.label), 11, _hoisted_1);
      }), 128))], 34)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "hover",
        onMouseenter: _cache[2] || (_cache[2] = function ($event) {
          return isHover.value = true;
        }),
        onMouseleave: _cache[3] || (_cache[3] = function ($event) {
          return isHover.value = false;
        })
      }, [_renderSlot(_ctx.$slots, "hover")], 32), _createElementVNode("div", {
        class: "default",
        onMouseenter: _cache[4] || (_cache[4] = function ($event) {
          return defaultChangeHover(true);
        }),
        onMouseleave: _cache[5] || (_cache[5] = function ($event) {
          return defaultChangeHover(false);
        })
      }, [_renderSlot(_ctx.$slots, "default")], 32)], 2);
    };
  }
};