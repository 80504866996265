import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "service-item"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "info"
};
import { toRefs, computed } from 'vue';
import { env_name } from '@/util/util';

/* 变量 */

export default {
  __name: 'vipCard',
  props: {
    data: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['choise'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var emit = __emit;
    var props = __props;
    var _toRefs = toRefs(props),
      data = _toRefs.data;

    /* 计算属性 */
    var logo = computed(function () {
      return require("@/assets/".concat(data.value.membershipCode.split('_')[0], "-vip.svg"));
    });

    /* 事件 */
    var choiseService = function choiseService() {
      emit('choise', data.value);
    };
    var jumpVip = function jumpVip(item) {
      var queryUrl = "?period=".concat(item.periodCode, "&subject=").concat(item.subjectCode, "&version=").concat(item.tutorialVersionCode, "&type=").concat(data.value.membershipCode.split('_')[0], "&isLogin=true&displayType=1");
      window.open(env_name ? "//".concat(env_name, "home-web.").concat(env_name.includes('edu') ? 'zhixuehuijiao' : 'huixuecloud', ".cn/detailVip") + queryUrl : '//superhome.zhixuehuijiao.cn/detailVip' + queryUrl); //跳转会员服务详情页
    };
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: logo.value,
        style: {
          "width": "100%",
          "border-radius": "6px 6px 0 0"
        }
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).strategys, function (i, index) {
        return _openBlock(), _createElementBlock("div", {
          class: "info-text",
          key: index
        }, [_createElementVNode("span", null, _toDisplayString(i.periodName + '/' + i.subjectName + '/' + i.tutorialVersionName), 1), _createElementVNode("span", null, _toDisplayString(i.price) + "元/年", 1), _createVNode(_component_t_button, {
          class: "btn",
          onClick: function onClick($event) {
            return jumpVip(i);
          }
        }, {
          default: _withCtx(function () {
            return _cache[0] || (_cache[0] = [_createTextVNode("开通")]);
          }),
          _: 2
        }, 1032, ["onClick"])]);
      }), 128))])]);
    };
  }
};