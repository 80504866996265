import { request } from "hxhy-axios";
import { apiList as api } from "./apiList";

// 新增习练
export function addPractice(data) {
  return request({
    url: api.practicePaper.addPractice,
    method: "post",
    data
  });
}

// 查询习练
export function getPracticeDetail(resId) {
  return request({
    url: api.practicePaper.getPracticeDetail + resId,
    method: "get"
  });
}

// 编辑习练
export function editPractice(data) {
  return request({
    url: api.practicePaper.editPractice,
    method: "put",
    data
  });
}

// 查询资源列表资源状态
export function getResourceState(data) {
  return request({
    url: api.practicePaper.getResourceState,
    method: "post",
    data
  });
}

// 习练的习题申请修改
export function applyEditExercise(id, data) {
  return request({
    url: `${api.practicePaper.applyEditExercise}${id}/apply/updateInfo`,
    method: "post",
    data
  });
}
