import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "book-list"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "item-right"
};
var _hoisted_5 = {
  class: "left-info"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = ["src"];
var _hoisted_8 = {
  class: "bottom"
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = ["src", "onClick"];
var _hoisted_11 = {
  class: "item-right"
};
var _hoisted_12 = {
  class: "left-info"
};
var _hoisted_13 = ["onClick"];
var _hoisted_14 = ["src"];
var _hoisted_15 = {
  class: "bottom"
};
var _hoisted_16 = {
  key: 0
};
var _hoisted_17 = {
  class: "btn-box"
};
import { toRefs, watch, ref } from 'vue';
import { fileClass, escape2Html, imgCoverMap } from '@/utils';
import openVipDialog from "@/components/openVip";
export default {
  __name: 'notAuth',
  props: {
    notAuthObj: {
      type: Object,
      default: function _default() {}
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    accountType: {
      type: [Number, String],
      default: 2
    },
    itemData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['loadUse', 'citeClose'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var visible = ref(false);
    var iData = ref({});
    var props = __props;
    var _toRefs = toRefs(props),
      notAuthObj = _toRefs.notAuthObj,
      itemData = _toRefs.itemData;
    var continueUse = function continueUse() {
      var _notAuthObj$value, _notAuthObj$value2;
      var data = {
        resourceIds: (_notAuthObj$value = notAuthObj.value) === null || _notAuthObj$value === void 0 ? void 0 : _notAuthObj$value.resource.map(function (item) {
          return item.id;
        }),
        courseIds: (_notAuthObj$value2 = notAuthObj.value) === null || _notAuthObj$value2 === void 0 ? void 0 : _notAuthObj$value2.course.map(function (item) {
          return item.id;
        })
      };
      emit('loadUse', itemData.value.id, 2, data);
    };
    var openVip = function openVip(item) {
      visible.value = true;
      iData.value = item;
    };
    var citeClose = function citeClose() {
      emit('citeClose');
    };
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_t_dialog, {
        visible: __props.dialogVisible,
        footer: false,
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        header: "抱歉，您无法引用以下资源，因为您暂未开通资源对应的会员服务",
        width: "63%",
        onClose: citeClose
      }, {
        default: _withCtx(function () {
          var _unref2, _unref3;
          return [_createElementVNode("div", _hoisted_1, [(_unref2 = _unref(notAuthObj)) !== null && _unref2 !== void 0 && _unref2.course.length ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(_unref(notAuthObj)['course'], function (item) {
            return _openBlock(), _createElementBlock("div", {
              class: "list-item",
              key: item.id
            }, [_createElementVNode("div", {
              class: "cover-box",
              onClick: function onClick($event) {
                return _ctx.showDetail(item);
              }
            }, [_createElementVNode("img", {
              src: item.cover || require('@/assets/big.svg'),
              style: {
                "height": "100px",
                "border-radius": "4px"
              }
            }, null, 8, _hoisted_3)], 8, _hoisted_2), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
              style: {
                "display": "flex",
                "align-items": "center",
                "cursor": "pointer"
              },
              onClick: function onClick($event) {
                return _ctx.showDetail(item);
              }
            }, [item.isVipResource && __props.accountType == 2 ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/vipIcon.svg'),
              style: {
                "margin-right": "8px"
              }
            }, null, 8, _hoisted_7)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.title && _unref(escape2Html)(item.title) || '暂无标题'), 1)], 8, _hoisted_6), _createElementVNode("div", _hoisted_8, [item.updateTime ? (_openBlock(), _createElementBlock("span", _hoisted_9, "更新时间： " + _toDisplayString(item.updateTime.indexOf('T') > -1 ? item.updateTime.split("T").join(" ") : item.updateTime.split(" ").join(" ")), 1)) : _createCommentVNode("", true), _createElementVNode("span", null, " 教师:  " + _toDisplayString(item.teacherName), 1), _createElementVNode("span", null, " 学校:  " + _toDisplayString(item.schoolName), 1), _createElementVNode("span", null, "ID: " + _toDisplayString(item.uniqueCode), 1)])])]), item.isVipResource && !item.hasVipRole && __props.accountType == 2 ? (_openBlock(), _createBlock(_component_t_button, {
              key: 0,
              class: "open-vip",
              onClick: function onClick($event) {
                return openVip(item);
              }
            }, {
              default: _withCtx(function () {
                return _cache[1] || (_cache[1] = [_createTextVNode("开通会员")]);
              }),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true)]);
          }), 128)) : _createCommentVNode("", true), (_unref3 = _unref(notAuthObj)) !== null && _unref3 !== void 0 && _unref3.resource.length ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(_unref(notAuthObj)['resource'], function (item) {
            return _openBlock(), _createElementBlock("div", {
              class: "list-item",
              key: item.id
            }, [_createElementVNode("img", {
              src: item.formatType && _unref(imgCoverMap)[_unref(fileClass)(item.formatType)] || require('@/assets/icon/otherIcon.png'),
              style: {
                "width": "75px",
                "cursor": "pointer",
                "border-radius": "4px"
              },
              onClick: function onClick($event) {
                return _ctx.showDetail(item);
              }
            }, null, 8, _hoisted_10), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("span", {
              style: {
                "display": "flex",
                "align-items": "center",
                "cursor": "pointer"
              },
              onClick: function onClick($event) {
                return _ctx.showDetail(item);
              }
            }, [item.isVipResource && __props.accountType == 2 ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/vipIcon.svg'),
              style: {
                "margin-right": "8px"
              }
            }, null, 8, _hoisted_14)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.title && _unref(escape2Html)(item.title) || '暂无标题'), 1)], 8, _hoisted_13), _createElementVNode("div", _hoisted_15, [item.updateTime ? (_openBlock(), _createElementBlock("span", _hoisted_16, "更新时间： " + _toDisplayString(item.updateTime.indexOf('T') > -1 ? item.updateTime.split("T").join(" ") : item.updateTime.split(" ").join(" ")), 1)) : _createCommentVNode("", true), _createElementVNode("span", null, " 教师:  " + _toDisplayString(item.teacherName), 1), _createElementVNode("span", null, " 学校:  " + _toDisplayString(item.schoolName), 1), _createElementVNode("span", null, "ID: " + _toDisplayString(item.uniqueCode), 1)])])]), item.isVipResource && !item.hasVipRole && __props.accountType == 2 ? (_openBlock(), _createBlock(_component_t_button, {
              key: 0,
              class: "open-vip",
              onClick: function onClick($event) {
                return openVip(item);
              }
            }, {
              default: _withCtx(function () {
                return _cache[2] || (_cache[2] = [_createTextVNode("开通会员")]);
              }),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true)]);
          }), 128)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_t_button, {
            type: "primary",
            onClick: continueUse
          }, {
            default: _withCtx(function () {
              return _cache[3] || (_cache[3] = [_createTextVNode("继续引用")]);
            }),
            _: 1
          })])];
        }),
        _: 1
      }, 8, ["visible"]), visible.value ? (_openBlock(), _createBlock(_unref(openVipDialog), {
        key: 0,
        dialogVisible: visible.value,
        itemData: iData.value,
        type: "curriculum",
        onHClose: _cache[0] || (_cache[0] = function ($event) {
          return visible.value = false;
        })
      }, null, 8, ["dialogVisible", "itemData"])) : _createCommentVNode("", true)]);
    };
  }
};