export function fileClass(formatType) {
  const index = formatType.lastIndexOf('.')
  index !== -1 && (formatType = formatType.slice(index + 1))
  const mimetype = {
    doc: 'file-doc',
    docx: 'file-doc',
    jpg: 'file-pic',
    png: 'file-pic',
    picture: 'file-pic',
    jpeg: 'file-pic',
    pdf: 'file-pdf',
    xls: 'file-xls',
    xlsx: 'file-xls',
    ppt: 'file-ppt',
    txt: 'file-txt',
    pptx: 'file-ppt',
    rar: 'file-zip',
    zip: 'file-zip',
    mp3: 'file-audio',
    mp4: 'file-video',
    avi: 'file-video',
    mov: 'file-video',
    rmvb: 'file-video',
    flv: 'file-video',
    vs4: 'file-video',
    wma: 'file-video'
  }
  const type = formatType && formatType.toLowerCase()
  return mimetype[type] ? mimetype[type] : 'file-other'
}

export const getFileCover = (name) => {
  const index = name.lastIndexOf('.')
  index !== -1 && (name = name.slice(index + 1))
  const imgArr = ['gif','jpg','psd','png']
	const audioArr = ['wav','mp3','wma','ra','midi','ogg','m4a','ape','aiff','au']
	const videoArr = ['RM','rmvb','mov','rm','mtv','dat','wmv','avi','mp4','3gp','amv','dmv','flv','asf','asx','mpeg','webm']
	const textArr = ['txt']
	const pdfArr = ['pdf']
	const pptArr = ['ppt', 'pptx']
  const docArr = ['doc','docx']
	const xlsArr = ['xls','xlsx']
	const rarArr = ['rar','7z','zip']
  if(imgArr.includes(name)) {
		return 'file-pic' // 图片
	} else if (audioArr.includes(name)) {
		return 'file-audio' // 音频
	} else if (videoArr.includes(name)){
		return 'file-video' // 视频
	} else if (textArr.includes(name)) {
		return 'file-txt' // 文本
	} else if (pdfArr.includes(name)) {
		return 'file-pdf' // pdf
	} else if (docArr.includes(name)) {
		return 'file-doc' // 文档
	} else if (pptArr.includes(name)) {
		return 'file-ppt' // 幻灯片
	} else if (xlsArr.includes(name)) {
		return 'file-xls' // 表格
	} else if (rarArr.includes(name)) {
		return 'file-zip' // 压缩包
	} else {
		return 'other' // 其他
	}
}

export const escape2Html = str => {
  const arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp':
    '&',
    'quot': '"',
    'mdash': '—',
    "ldquo": '“',
    "rdquo": '”',
    "rsquo": '’',
    "lsquo": '‘',
    "permil": '‰',
    "euro": '€',
    "le": '≤',
    "ge": '≥',
    "ne": '≠',
    "middot": '·',
    "trade": '™',
    "\\n": ' '
  }
  return str.replace(/&(lt|gt|nbsp|amp|quot|mdash|ldquo|rdquo|rsquo|lsquo|permil|euro|le|ge|ne|middot|trade);|(\\n)/gi, (all, t, d) => {
    return arrEntities[t] || arrEntities[d]
  })
}

// 树形数组平铺 
export const treeFlat = (tree) => {
  let arr = []
  tree.forEach(item => {
    arr.push(item)
    if(item.children) {
      arr.push.apply(arr,treeFlat(item.children))
    }
  })
  return arr
}

// 转化文件大小
export function formatBytes(size, digit = 2) {
  if (size || size === 0) {
    if (size === 0) return '0 B'
    if (size < 0) return '未知'
    const ratio = 1024,
      unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(size) / Math.log(ratio))
    return parseFloat((size / Math.pow(ratio, f)).toFixed(digit)) + ' ' + unit[f]
  } else {
    return '大小转换失败'
  }
}

// 获取时分
export function formatMinSecond(second) {
  const mins = Math.floor(second / 60);
  const seconds = Math.ceil(second - mins * 60);
  return `${mins.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
// 获取两个时间之间的天
export function getDiffDay(date_1, date_2) {
  if (date_1 && date_2) {
    let totalDays, diffDate
    let myDate_1 = Date.parse(date_1.split(' ')[0] + ' 00:00:00')
    let myDate_2 = Date.parse(date_2.split(' ')[0] + ' 00:00:00')
    diffDate = Math.abs(myDate_1 - myDate_2) 
    totalDays = Math.floor(diffDate / (1000 * 3600 * 24))
    return totalDays + 1
  }
  return 0
}

const envList = ['dev-edu', 'test', 'pre']
export const env_name = envList.find(v => {
  return location.host.includes(v)
}) || ''

//获取文件流数据
export const getFileArrayBuffer = (url) => {
  const newUrl = url + '?' + Math.random()
  return new Promise((resolve, reject) => {
    fetch(newUrl, {
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response.blob()
    }).then(data => {
      // data.arrayBuffer().then(buffer => {
      //   resolve(buffer)
      // }).catch(error => {
      //   reject('获取文件流数据失败，请重试')
      // })
      resolve(data)
    }).catch(error => {
      reject('获取文件流数据失败，请重试')
    })
  })
}

//获取文件名
export const getFileName = (fileName) => {
  const fileArr = fileName.split('.')
  console.log(fileArr)
  let newFileName = ''
  for (let i = 0; i < fileArr.length - 1; i++) {
    newFileName += fileArr[i]
    if (i < fileArr.length - 2) {
      newFileName += '.'
    }
  }
  return newFileName
}
export const getObjType = (obj) => {
  const { toString } = Object.prototype
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

/**
 * 数字索引转字母 1 -> A   26 -> Z
 * @param num
 * @returns {string}
 */
export function num2Letter(num) {
  const n = parseInt(num)
  if (!isNaN(n) && n > 0 && n < 27) {
    return String.fromCharCode(64 + n)
  }
}

// 防抖
export const debounce = (fn, delay = 500) => {
  let timer = null;
  return () => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};

// 树形扁平化
export const flatArr = (tree) => {
  const flat = []
  for(let item of tree) {
    flat.push(item)
    if(item.children && item.children.length > 0) {
      flat.push(...flatArr(item.children))
    }
  }
  return flat
}

/**
 * 阿拉伯数字转中文数字
 * @param Number
 * @returns {string}
 */
export function numberToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    return num
  }
  const AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const BB = ['', '十', '百', '千', '万', '亿', '点', '']
  var a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = ''
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break
      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) re = BB[4] + re
        break
      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }
    if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) re = AA[0] + re
    if (a[0].charAt(i) !== 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }
  if (a.length > 1) {
    //加上小数部分(如果有小数部分)
    re += BB[6]
    for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
  }
  return re
}
