import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "interact-detail"
};
var _hoisted_2 = {
  class: "main-info"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "info"
};
var _hoisted_5 = {
  class: "title"
};
var _hoisted_6 = {
  class: "material"
};
var _hoisted_7 = ["src"];
var _hoisted_8 = {
  class: "originality"
};
var _hoisted_9 = {
  class: "module-box"
};
var _hoisted_10 = {
  class: "module-content"
};
var _hoisted_11 = ["innerHTML"];
var _hoisted_12 = {
  class: "module-box"
};
var _hoisted_13 = {
  class: "module-content"
};
var _hoisted_14 = {
  key: 0,
  class: "interact-photos"
};
var _hoisted_15 = ["src"];
import { ref, onMounted } from "vue";
/* api */
import { getToolDetail } from "@/api/resources";
/* 变量 */

export default {
  __name: 'interactDetail',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:visible"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var interactData = ref({});
    var bookIcon = require("./small_book.png");
    var emit = __emit;

    /* 方法 */
    onMounted(function () {
      var _props$data = __props.data,
        toolBusinessId = _props$data.toolBusinessId,
        outlineBusinessId = _props$data.outlineBusinessId;
      getToolDetail(toolBusinessId, outlineBusinessId).then(function (res) {
        if (res.data.code === 0 && res.data.data.success === 1) {
          interactData.value = res.data.data.toolInstructionInfo;
        }
      });
    });
    var hide = function hide() {
      emit("update:visible", false);
    };
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        visible: __props.visible,
        footer: false,
        top: "0",
        width: "1344px",
        onClose: hide
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
            src: interactData.value.thumbnailUrl,
            alt: ""
          }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(interactData.value.name), 1), _createElementVNode("div", _hoisted_6, [_cache[0] || (_cache[0] = _createElementVNode("span", {
            class: "tag"
          }, "教 材", -1)), _createElementVNode("span", null, _toDisplayString(interactData.value.bookName), 1), _createElementVNode("img", {
            src: _unref(bookIcon),
            alt: ""
          }, null, 8, _hoisted_7), _createElementVNode("span", null, _toDisplayString(interactData.value.knowledgeUnit), 1)]), _createElementVNode("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "tag"
          }, "主创意", -1)), _createElementVNode("span", null, _toDisplayString(interactData.value.mainIdeaInfo), 1)])])]), _createElementVNode("div", _hoisted_9, [_cache[2] || (_cache[2] = _createElementVNode("span", {
            class: "module-title"
          }, "基本信息", -1)), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
            innerHTML: interactData.value.description
          }, null, 8, _hoisted_11)])]), _createElementVNode("div", _hoisted_12, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            class: "module-title"
          }, "工具快照", -1)), _createElementVNode("div", _hoisted_13, [interactData.value.snapshotList ? (_openBlock(), _createElementBlock("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interactData.value.snapshotList, function (item, i) {
            return _openBlock(), _createElementBlock("img", {
              key: i,
              src: item
            }, null, 8, _hoisted_15);
          }), 128))])) : _createCommentVNode("", true)])])])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};