/** 接口 */
import { getDictItem, getTutorial, getDirByTutorial } from '@/api/excersise.js'
import {getGrades, getSchoolList, getClass, getDeptAuthList} from "@/api/home"

export default function () {
  // 资源字典选择: 'period': 学段, 'subject': 学科, 'tutorial_version': 版本
  const getOptions = (type) => {
    return new Promise((resolve) => {
      getDictItem(type).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data || [])
        }
      })
    })
  }

  // 获取教材
  const getTextBook = (data) => {
    return new Promise((resolve) => {
      getTutorial(data).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data || [])
        }
      })
    })
  }

  // 获取单元
  const getUnit = (id) => {
    return new Promise((resolve) => {
      getDirByTutorial(id).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data || [])
        }
      })
    })
  }

  /**
   * 首页数据看板学校相关选项
   */

  // 获取学校列表
  const getSchoolOptions = () => {
        return new Promise((resolve) => {
          getSchoolList().then(res => {
            if (res.data.code === 0) {
              resolve(res.data.data || [])
            }
          }).catch(() => {
            resolve([])
          })
        })
      }

  // 获取学校和年级列表
  const getGradesOptions = (userId, type, deptId) => {
    return new Promise((resolve) => {
      getDeptAuthList({userId, type, deptId}).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data || [])
        }
      }).catch(() => {
        resolve([])
      })
    })
  }

  // 获取班级列表
  const getClassOptions = (userId, classType) => {
    return new Promise((resolve) => {
      getClass({userId, classType}).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data || [])
        }
      }).catch(() => {
        resolve([])
      })
    })
  }

  return {
    getOptions,
    getTextBook,
    getUnit,
    getSchoolOptions,
    getGradesOptions,
    getClassOptions
  }
}