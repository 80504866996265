import { onMounted, onUnmounted } from 'vue'
import { isSvgUrlOrBase64 } from '@/views/sourcePreview/courseDetail/const/utils'

export default function(callBack) {
  let imagePreviewList = []
  // 判断图片位置
  const hasParent = (el, className) => {
    let flag = false
    let p = el.parentNode
    while ((p !== document.body || !flag) && p) {
      if (p && p.classList && p.classList.length > 0) {
        if (Array.from(p.classList).includes(className)) {
          flag = true
        }
      }
      const o = p
      p = o.parentNode
    }
    return flag
  }

  // 监听图片被点击的处理
  const handleImageClick = (event) => {
    if (event.target.nodeName === 'IMG' && hasParent(event.target, 'card-content')) {
      if (event.target.dataset.mathml || event.target.dataset.material) return // mathml和资源封面图
      const currentSrc = event.target.src
      // const currentIndex = imagePreviewList.findIndex(item => item === currentSrc)
      callBack(currentSrc)
    }
  }

  // 获取页面所有的图片
  const getImgSrcList = () => {
    let imgList = []
    const imgNodeList = Array.from(document.getElementsByTagName('img')) || []
    imgNodeList.forEach(item => {
      if (!item.getAttribute('data-material') && !isSvgUrlOrBase64(item.getAttribute('src'))) {
        item.style.cursor = 'zoom-in' // 移入图片增加鼠标提示
      }
    })
    // 获取符合条件的图片列表
    imgList = imgNodeList.map(item => !item.getAttribute('data-material') && item.getAttribute('src')).filter(item => !isSvgUrlOrBase64(item))
    return imgList
  }

  onMounted(() => {
    document.removeEventListener('click', handleImageClick, false)
    document.addEventListener('click', handleImageClick, false)
    // imagePreviewList = getImgSrcList()
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleImageClick, false)
  })

}
