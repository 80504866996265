import { chatConfig } from "@/const/chatConfig";
import useNIMStore from "@/stores/modules/nim";

export const useLoginChat = (userInfo) => {
  const NIMStore = useNIMStore();
  const {
    id,
    accId = chatConfig?.defaultAccount,
    yxPassword = chatConfig?.defaultToken,
  } = userInfo;
  if (accId && yxPassword) {
    NIMStore.loginChat(accId, yxPassword, id);
  } else {
    console.log("请配置默认账号和token");
  }
};
