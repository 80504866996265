import "core-js/modules/es.array.slice.js";
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "disabled-content"
};
import { ref, toRefs, watch } from "vue";
import { getAllTimeRange } from '@/api/home.js';
export default {
  __name: 'disabledTimeDialog',
  props: /*@__PURE__*/_mergeModels({
    appId: {
      type: String,
      default: ''
    }
  }, {
    "disabledVisible": false,
    "disabledVisibleModifiers": {}
  }),
  emits: ["update:disabledVisible"],
  setup: function setup(__props) {
    /** 组合式api */
    var props = __props;
    var _toRefs = toRefs(props),
      appId = _toRefs.appId;
    var visible = _useModel(__props, 'disabledVisible', false);

    /** 初始化相关 */
    var timeData = ref([]);
    var getData = function getData() {
      getAllTimeRange(appId.value).then(function (res) {
        if (res.data.code === 0) {
          timeData.value = res.data.data || [];
        }
      });
    };
    watch(visible, function (nval) {
      if (nval) {
        getData();
      }
    });

    /** 事件相关 */
    /**
     * 关闭弹窗
     */
    var closeDialog = function closeDialog() {
      visible.value = false;
    };
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        visible: visible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return visible.value = $event;
        }),
        header: "管理员设定本班级今日不可用时段如下",
        width: "410px",
        destroyOnClose: true,
        class: "disabled-dialog"
      }, {
        body: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [timeData.value.length ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(timeData.value, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              class: "item-div"
            }, _toDisplayString(item.slice(0, 5)) + "~" + _toDisplayString(item.slice(6)), 1);
          }), 128)) : _createCommentVNode("", true)])];
        }),
        footer: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "know-div",
            onClick: closeDialog
          }, "知道了")];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};