import CryptoJS from "crypto-js";
import { queryString } from "@/util/util";
import { flowIreader } from "@/api/home";
import { MessagePlugin } from 'tdesign-vue-next';

export function JumpToThird(appinfo, userinfo, token, callback) {
  if (appinfo.alias === 'digitalLibrary') {
    toThirdIReader(userinfo.id)
  } else if (appinfo.alias.includes('boxiao')) {
    callback(appinfo.alias)
  } else if (appinfo.alias.startsWith('LSExam')) {
    toThirdLSExam(appinfo.url, token)
  }
}

function toThirdLSExam(target, token) {
  const LSUrl = 'http://exam.zhixuehuijiao.com/sso/sd271'
  const qs = {
    token: token,
    target: target
  }
  const url = LSUrl + queryString(qs)
  window.open(url, 'newwindow')
}

function toThirdIReader(userId) {
  flowIreader().then(res => {
    if (res.data.data) {
      if (userId) {
        const ireaderUrl = 'https://se.zhangyue.com'
        const qs = {
          appId: location.host.includes('web.') ? '5fa060fa' : '391542e8',
          timestamp: new Date().getTime().toString().substring(0, 10),
          userId: String(userId)
        }
        qs.sign = generateIreaderSign(qs)
        const url = ireaderUrl + '/channel/index' + queryString(qs)
        window.open(url, 'newwindow')
      } else {
        MessagePlugin.error('未获取到当前用户userId，请联系管理员')
      }
    } else {
      MessagePlugin.error('当前用户未导入，请联系管理员')
    }
  }).catch(e => {
    e.message && MessagePlugin.error(e.message)
  })
}

/**
 * 计算掌阅APP签名
 *fileName string
  */
function generateIreaderSign(qsObj) {
  function sign(obj) { // 进行数组排序
    var params = ksort(obj)//生成签名
    return algorithm(params)
  }
  // 根据ASCII 码对obj进行排序
  function ksort(obj) {
    var keys = Object.keys(obj).sort(), sortedObj = {}
    for (var i in keys) {
      sortedObj[keys[i]] = obj[keys[i]]
    }
    return sortedObj
  }
  //  签名算法
  function algorithm(params) {
    var esc = encodeURIComponent
    var query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&')
    var md5 = str => CryptoJS.MD5(str).toString()
    var encrypt = md5(query).substr(6, 18)
    return md5(encrypt).substr(10, 16)
  }
  return sign(qsObj)
}