import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import { ref, watch } from "vue";
import chooseAvatar from '@/components/chooseAvatar/index.vue';
import { MessagePlugin } from "tdesign-vue-next";
export default {
  __name: 'avatarDialog',
  props: {
    avatarVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:avatarVisible', 'update'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var visible = ref(props.avatarVisible);
    var emit = __emit;
    var updateAvatar = function updateAvatar(url) {
      if (!url) return MessagePlugin.warning('请选择头像');
      emit('update', url);
      closeDialog();
    };
    var closeDialog = function closeDialog() {
      visible.value = false;
      emit('update:avatarVisible', false);
      emit('close');
    };
    watch(function () {
      return props.avatarVisible;
    }, function (newValue) {
      console.log(newValue, 'newValue');
      visible.value = newValue;
    });
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        header: "修改头像",
        visible: visible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return visible.value = $event;
        }),
        onClose: closeDialog,
        footer: null,
        width: "auto"
      }, {
        default: _withCtx(function () {
          return [visible.value ? (_openBlock(), _createBlock(chooseAvatar, {
            key: 0,
            onUpdate: updateAvatar,
            onCancel: closeDialog
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};