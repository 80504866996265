import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
import _imports_0 from '../icons/submitCard.png';
var _hoisted_1 = {
  class: "submit-card card media-card"
};
var _hoisted_2 = {
  class: "media-card-serial"
};
var _hoisted_3 = {
  key: 0,
  class: "media-card-title"
};
var _hoisted_4 = {
  class: "card-title"
};
var _hoisted_5 = {
  class: "card-content"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = {
  class: "submit-method"
};
import { computed } from "vue";
/* 变量 */

export default {
  __name: 'submitCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var submitMap = {
      'text': '文本',
      'picture': '图片',
      'video': '视频',
      'file': '文档'
    };

    /* 计算属性 */
    var html = computed(function () {
      return __props.cardData.data.item ? JSON.parse(__props.cardData.data.item).content : '';
    });
    var submitTypes = computed(function () {
      var submitType = __props.cardData.data.attribute.submitType || {};
      var checkedWays = [];
      for (var key in submitType) {
        if (submitType[key]) {
          checkedWays.push(submitMap[key]);
        }
      }
      return checkedWays;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.cardData.sort), 1), __props.cardData.data.title && __props.cardData.data.title !== '提交卡' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(__props.cardData.data.title), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "preview",
        innerHTML: html.value
      }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "提交方式：", -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(submitTypes.value, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item), 1);
      }), 128))])]);
    };
  }
};