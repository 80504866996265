export const getFileType = (name) => {
  const index = name.lastIndexOf('.')
  index !== -1 && (name = name.slice(index + 1))
	const imgArr = ['gif','jpg','psd','png']
	const audioArr = ['wav','mp3','wma','ra','midi','ogg','m4a','ape','aiff','au']
	const videoArr = ['RM','rmvb','mov','rm','mtv','dat','wmv','avi','mp4','3gp','amv','dmv','flv','asf','asx','mpeg','webm']
	const textArr = ['doc','docx','xls','xlsx','ppt','pptx','txt','pdf']
	const rarArr = ['rar','7z','zip']
	if(imgArr.includes(name)) {
		return 2 // 图片
	} else if (audioArr.includes(name)) {
		return 4 // 音频
	} else if (videoArr.includes(name)){
		return 3 // 视频
	} else if (textArr.includes(name)) {
		return 1 // 文本
	} else if (rarArr.includes(name)) {
		return 5 // 压缩包
	} else {
		return 6 // 其他
	}
}

export const getChineseFileType = (name) => {
	const index = name.lastIndexOf('.')
	index !== -1 && (name = name.slice(index + 1))
	const imgArr = ['gif','jpg','psd','png']
	const audioArr = ['wav','mp3','wma','ra','midi','ogg','m4a','ape','aiff','au']
	const videoArr = ['RM','rmvb','mov','rm','mtv','dat','wmv','avi','mp4','3gp','amv','dmv','flv','asf','asx','mpeg','webm']
	const textArr = ['doc','docx','xls','xlsx','ppt','pptx','txt','pdf']
	const rarArr = ['rar','7z','zip']
	if(imgArr.includes(name)) {
		return '图片' // 图片
	} else if (audioArr.includes(name)) {
		return '音频' // 音频
	} else if (videoArr.includes(name)){
		return '视频' // 视频
	} else if (textArr.includes(name)) {
		return '文本' // 文本
	} else if (rarArr.includes(name)) {
		return '压缩包' // 压缩包
	} else {
		return '其他' // 其他
	}
}

export const  formatBytes = (a, b) => {
	if (Number(a) === 0) return '0 B'
  if (Number(a) < 0) return '未知'
  const c = 1024,
    d = b || 2,
    e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}
