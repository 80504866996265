/**
 * @description 数据看板图表配置
 * @param type 入参身份类型
 * @param data 入参图表数据
 */
export const mainDataViewChartsOption = (type, data) => {
  if (!data) { // 无数据
    return noDataOptions(type === 'teacher');
  }
  switch (type) {
    case 'rector': // 校长
      return rectorOrGradeOption(data);
    case 'grade': // 年级主任
      return rectorOrGradeOption(data);
    case 'teacher': // 老师
      return teacherOption(data);
  }
};

/**
 * 校长类型和年级主任类型的图表配置
 * @param data
 * @returns {{yAxis: [{min: number, name: string, position: string, type: string},{axisLabel: {formatter: (function(*): string)}, max: number, name: string, show: boolean, splitLine: {show: boolean}, position: string, type: string}], xAxis: {data: *, type: string}, grid: {top: string, left: string, bottom: string, right: string}, legend: {itemGap: number, data, bottom: number}, series: [{barWidth: string, data: *, name: *, itemStyle: {color: string}, type: string, yAxisIndex: number},{showSymbol: boolean, data: *, name: *, itemStyle: {color: string}, type: string, yAxisIndex: number, smooth: boolean},{showSymbol: boolean, data: *, name: *, itemStyle: {color: string}, type: string, yAxisIndex: number, smooth: boolean},{showSymbol: boolean, data: *, name: *, itemStyle: {color: string}, type: string, yAxisIndex: number, smooth: boolean}], tooltip: {trigger: string}, title: {left: string, text}}}
 */
export const rectorOrGradeOption = (data) => {
  return {
    title: {
      left: 'center',
      text: data.title || '本周课堂组织情况'
    },
    tooltip: {
      trigger: 'axis'
    },
    animation: false,
    grid: {
      top: '20%', // 上边距
      bottom: '16%', // 下边距
      left: '6%', // 左边距
      right: '6%', // 右边距
    },
    yAxis: [
      {
        type: 'value',
        name: '',
        position: 'left',
        min: 0,
      },
      {
        type: 'value',
        name: '',
        position: 'right',
        max: 100,
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: (value) => {
            return `${value}%`;
          }
        }
      }
    ],
    legend: {
      bottom: 0,
      itemGap: 30,
      data: data.legend
    },
    xAxis: {
      type: 'category',
      data: data.xaxis
    },
    series: [
      {
        name: data.series[0]['name'],
        type: 'bar',
        barWidth: '20px',
        yAxisIndex: 0,
        itemStyle: {
          color: '#366EF4'
        },
        data: data.series[0]['data'],
      },
      {
        name: data.series[1]['name'],
        type: 'line',
        yAxisIndex: 1,
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: '#0AC9D0'
        },
        data: data.series[1]['data'],
      },
      {
        name: data.series[2]['name'],
        type: 'line',
        yAxisIndex: 1,
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: '#3FDEB1'
        },
        data: data.series[2]['data'],
      },
      {
        name: data.series[3]['name'],
        type: 'line',
        yAxisIndex: 1,
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: '#FFA63A'
        },
        tooltip: {
          valueFormatter: (value) => {
            return value + '%';
          }
        },
        data: data.series[3]['data'],
      }
    ]
  };
};

/**
 * 教师类型的图表配置
 * @param data
 */
export const teacherOption = (data)=> {
  return {
    title: {
      text: data.title || '本周课堂组织情况',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis'
    },
    animation: false,
    grid: {
      top: '12%', // 上边距
      bottom: '16%', // 下边距
      left: '8%', // 左边距
      right: '4%', // 右边距
    },
    yAxis: [
      {
        type: 'value',
        name: '',
        position: 'left',
      }
    ],
    legend: {
      bottom: 0,
      itemGap: 30,
      data: data.legend
    },
    xAxis: {
      type: 'category',
      data: data.xaxis
    },
    series: [
      {
        name: data.series[0]['name'],
        type: 'bar',
        stack: 'Total',
        barWidth: '20px',
        yAxisIndex: 0,
        itemStyle: {
          color: '#366EF4'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: data.series[0]['data'],
      },
      {
        name: data.series[1]['name'],
        type: 'bar',
        stack: 'Total',
        yAxisIndex: 0,
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#FFA63A'
        },
        data: data.series[1]['data'],
      },
    ]
  };
};

/**
 * 无数据的图表配置
 * @param teacher 是否为教师
 */
export const noDataOptions = (teacher)=> {
  return {
    title: {
      text: teacher ? '本月课堂组织情况' : '本周课堂组织情况',
      left: 'center'
    },
    grid: {
      top: '12%', // 上边距
      bottom: '16%', // 下边距
      left: '8%', // 左边距
      right: '8%' // 右边距
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false
    },
    yAxis: [
      {
        type: 'value',
        nameTextStyle: {
          fontSize: 0
        }
      },
      {
        type: 'value',
        max: 1,
        show: !teacher,
        nameTextStyle: {
          fontSize: 0
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: (value) => {
            return `${value * 100}%`;
          }
        }
      }
    ],
    series: [
      {
        data: [1000],
        type: 'line',
        symbolSize: 0,
        yAxisIndex: 0,
        labelLine: {
          show: false
        }
      },
      {
        data: [1000],
        type: 'line',
        symbolSize: 0,
        yAxisIndex: 1,
        labelLine: {
          show: false
        }
      }
    ]
  }
};