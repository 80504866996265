import "core-js/modules/es.string.fixed.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "cropper"
};
var _hoisted_2 = {
  style: {
    "flex": "1"
  }
};
var _hoisted_3 = {
  class: "show-preview"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "cropper-btn"
};
import { ref } from "vue";
import VueCropper from "vue-cropper/lib/vue-cropper.vue";
export default {
  __name: 'imgCropper',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    var dialogVisiblex = ref(false);
    var option = ref({
      img: '',
      // 裁剪图片的地址 url 地址, base64, blob
      outputSize: 1,
      // 裁剪生成图片的质量
      outputType: 'png',
      // 裁剪生成图片的格式 jpeg, png, webp
      info: true,
      // 裁剪框的大小信息
      canScale: false,
      // 图片是否允许滚轮缩放
      autoCrop: true,
      // 是否默认生成截图框
      autoCropWidth: 200,
      // 默认生成截图框宽度
      autoCropHeight: 200,
      // 默认生成截图框高度
      fixedBox: false,
      // 固定截图框大小 不允许改变
      fixed: true,
      // 是否开启截图框宽高固定比例
      fixedNumber: [1, 1],
      // 截图框的宽高比例 [ 宽度 , 高度 ]
      canMove: true,
      // 上传图片是否可以移动
      canMoveBox: true,
      // 截图框能否拖动
      original: false,
      // 上传图片按照原始比例渲染
      centerBox: false,
      // 截图框是否被限制在图片里面
      infoTrue: true,
      // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      full: true,
      // 是否输出原图比例的截图
      enlarge: '1',
      // 图片根据截图框输出比例倍数
      mode: 'contain',
      // 图片默认渲染方式 contain , cover, 100px, 100% auto
      success: function success() {} // 回调方法
    });
    var showModal = function showModal(obj) {
      if (obj.img) {
        option.value.img = obj.img;
      }
      //裁剪生成图片的质量
      if (obj.outputSize) {
        option.value.outputSize = obj.outputSize;
      }
      //裁剪生成图片的格式
      if (obj.outputType) {
        option.value.outputType = obj.outputType;
      }
      //裁剪框的大小信息
      if (obj.info) {
        option.value.info = obj.info;
      }
      //图片是否允许滚轮缩放
      if (obj.canScale) {
        option.value.canScale = obj.canScale;
      }
      //是否默认生成截图框
      if (obj.autoCrop) {
        option.value.autoCrop = obj.autoCrop;
      }
      //默认生成截图框宽度
      if (obj.autoCropWidth) {
        option.value.autoCropWidth = obj.autoCropWidth;
      }
      //默认生成截图框高度
      if (obj.autoCropHeight) {
        option.value.autoCropHeight = obj.autoCropHeight;
      }
      //固定截图框大小 不允许改变
      if (obj.fixedBox) {
        option.value.fixedBox = obj.fixedBox;
      }
      //是否开启截图框宽高固定比例
      if (obj.fixed) {
        option.value.fixed = obj.fixed;
      }
      //截图框的宽高比例
      if (obj.fixedNumber) {
        option.value.fixedNumber = obj.fixedNumber;
      }
      //上传图片是否可以移动
      if (obj.canMove) {
        option.value.canMove = obj.canMove;
      }
      //截图框能否拖动
      if (obj.canMoveBox) {
        option.value.canMoveBox = obj.canMoveBox;
      }
      //上传图片按照原始比例渲染
      if (obj.original) {
        option.value.original = obj.original;
      }
      //截图框是否被限制在图片里面
      if (obj.centerBox) {
        option.value.centerBox = obj.centerBox;
      }
      //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      if (obj.infoTrue) {
        option.value.infoTrue = obj.infoTrue;
      }
      //是否输出原图比例的截图
      if (obj.full) {
        option.value.full = obj.full;
      }
      //图片根据截图框输出比例倍数
      if (obj.enlarge) {
        option.value.enlarge = obj.enlarge;
      }
      //图片默认渲染方式
      if (obj.mode) {
        option.value.mode = obj.mode;
      }
      //图片回调函数
      if (obj.success) {
        option.value.success = obj.success;
      }
      dialogVisiblex.value = true;
    };
    //图片缩放
    var cropper = ref("cropper");
    var changeScale = function changeScale(num) {
      num = num || 1;
      cropper.value.changeScale(num);
    };
    //向左旋转
    var rotateLeft = function rotateLeft() {
      cropper.value.rotateLeft();
    };
    //向右旋转
    var rotateRight = function rotateRight() {
      cropper.value.rotateRight();
    };
    //实时预览函数
    var previews = ref({});
    var realTime = function realTime(data) {
      previews.value = data;
    };
    var cancel = function cancel() {
      dialogVisiblex.value = false;
    };
    var finish = function finish() {
      // 确定按钮
      cropper.value.getCropBlob(function (data) {
        option.value.success(data);
        cancel();
      });
    };
    __expose({
      showModal: showModal
    });
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_t_dialog, {
        header: "图片剪裁",
        visible: dialogVisiblex.value,
        "onUpdate:visible": _cache[2] || (_cache[2] = function ($event) {
          return dialogVisiblex.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        width: "1000px",
        onCancel: cancel,
        onConfirm: finish
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(VueCropper, {
            ref_key: "cropper",
            ref: cropper,
            img: option.value.img,
            outputSize: option.value.outputSize,
            outputType: option.value.outputType,
            info: option.value.info,
            canScale: option.value.canScale,
            autoCrop: option.value.autoCrop,
            autoCropWidth: option.value.autoCropWidth,
            autoCropHeight: option.value.autoCropHeight,
            fixedBox: option.value.fixedBox,
            fixed: option.value.fixed,
            fixedNumber: option.value.fixedNumber,
            canMove: option.value.canMove,
            canMoveBox: option.value.canMoveBox,
            original: option.value.original,
            centerBox: option.value.centerBox,
            infoTrue: option.value.infoTrue,
            full: option.value.full,
            enlarge: option.value.enlarge,
            mode: option.value.mode,
            onRealTime: realTime
          }, null, 8, ["img", "outputSize", "outputType", "info", "canScale", "autoCrop", "autoCropWidth", "autoCropHeight", "fixedBox", "fixed", "fixedNumber", "canMove", "canMoveBox", "original", "centerBox", "infoTrue", "full", "enlarge", "mode"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
            style: _normalizeStyle(previews.value.div),
            class: "preview"
          }, [_createElementVNode("img", {
            src: previews.value.url,
            style: _normalizeStyle(previews.value.img)
          }, null, 12, _hoisted_4)], 4)])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_t_button, {
            variant: "outline",
            theme: "default",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return changeScale(1);
            })
          }, {
            default: _withCtx(function () {
              return _cache[3] || (_cache[3] = [_createTextVNode("放大")]);
            }),
            _: 1
          }), _createVNode(_component_t_button, {
            variant: "outline",
            theme: "default",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return changeScale(-1);
            })
          }, {
            default: _withCtx(function () {
              return _cache[4] || (_cache[4] = [_createTextVNode("缩小")]);
            }),
            _: 1
          }), _createVNode(_component_t_button, {
            variant: "outline",
            theme: "default",
            onClick: rotateLeft
          }, {
            default: _withCtx(function () {
              return _cache[5] || (_cache[5] = [_createTextVNode("左旋转")]);
            }),
            _: 1
          }), _createVNode(_component_t_button, {
            variant: "outline",
            theme: "default",
            onClick: rotateRight
          }, {
            default: _withCtx(function () {
              return _cache[6] || (_cache[6] = [_createTextVNode("右旋转")]);
            }),
            _: 1
          })])];
        }),
        _: 1
      }, 8, ["visible"])]);
    };
  }
};