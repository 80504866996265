import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "head"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "practice-quick-setting"
};
var _hoisted_4 = {
  class: "label"
};
var _hoisted_5 = {
  class: "unit"
};
var _hoisted_6 = {
  class: "pratice-table"
};
var _hoisted_7 = {
  class: "group-list"
};
var _hoisted_8 = {
  class: "group-title"
};
var _hoisted_9 = {
  class: "topic-list"
};
var _hoisted_10 = {
  class: "flex-row"
};
var _hoisted_11 = {
  class: "flex-1 vertical-center"
};
var _hoisted_12 = {
  class: "flex-2 topic-content"
};
var _hoisted_13 = {
  class: "flex-3"
};
var _hoisted_14 = ["onClick"];
var _hoisted_15 = {
  class: "topic-setting"
};
var _hoisted_16 = {
  class: "setting-item-unit"
};
import { reactive, computed } from "vue";
import { numberToChinese } from "@/util/index";
import { EXERCISE_NAME_MAP, EXERCISE_CODE_MAP } from "@/views/sourcePreview/paperDetail/exerciseCard/const";
import { DialogPlugin } from "tdesign-vue-next";

/* 变量 */

export default {
  __name: 'setScoreDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 习练数据
    practiceData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:visible", "sure"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var emit = __emit;
    var props = __props;
    var state = reactive({
      copyPracticeData: {},
      // 拷贝一份数据，给当前组件使用
      quickSettings: []
    });

    /* 计算属性 */
    var groupList = computed(function () {
      var _state$copyPracticeDa;
      return ((_state$copyPracticeDa = state.copyPracticeData) === null || _state$copyPracticeDa === void 0 ? void 0 : _state$copyPracticeDa.paperNavs) || [];
    });
    var practiceScore = computed(function () {
      var res = 0;
      groupList.value.forEach(function (group) {
        group.paperNavItems.forEach(function (topic) {
          var scoreData = topic.paperExerciseScore;
          if (scoreData) {
            if (scoreData.type === "COMPREHENSIVE") {
              var isEmpty = scoreData === null || scoreData === void 0 ? void 0 : scoreData.childs.some(function (e) {
                return !e.score && e.score !== 0;
              });
              if (!isEmpty) {
                scoreData === null || scoreData === void 0 || scoreData.childs.forEach(function (e) {
                  if (e.type === "COMPLETION") {
                    res += Number(e.score || "0") * Number(e.total || "0");
                  } else {
                    res += Number(e.score || "0");
                  }
                });
              }
            } else if (scoreData.type === "COMPLETION") {
              res += Number(scoreData.score || "0") * Number(scoreData.total || "0");
            } else {
              res += Number(scoreData.score || "0");
            }
          }
        });
      });
      return res;
    });

    /* 事件 */
    // 超出省略号
    var txtEllipsis = function txtEllipsis(html) {
      var txt = html.replace(/<.*?>/gi, "");
      return txt.length > 50 ? txt.slice(0, 50) + "..." : txt;
    };

    // 获取习题组的分数
    var getGroupScore = function getGroupScore(group) {
      var score = 0;
      var list = (group === null || group === void 0 ? void 0 : group.paperNavItems) || [];
      list.forEach(function (topic) {
        var scoreData = topic.paperExerciseScore;
        if (scoreData) {
          if (scoreData.type === "COMPREHENSIVE") {
            scoreData === null || scoreData === void 0 || scoreData.childs.forEach(function (e) {
              if (e.type === "COMPLETION") {
                score += Number(e.score) * Number(e.total || "0");
              } else {
                score += Number(e.score);
              }
            });
          } else if (scoreData.type === "COMPLETION") {
            score += Number(scoreData.score) * Number(scoreData.total || "0");
          } else {
            score += Number(scoreData.score);
          }
        }
      });
      return score;
    };

    // 获取习题列表
    var getTopicList = function getTopicList(group) {
      return (group === null || group === void 0 ? void 0 : group.paperNavItems) || [];
    };

    // 判断是否为综合题
    var isComprehensive = function isComprehensive(topic) {
      var _topic$exercisesData;
      return (topic === null || topic === void 0 || (_topic$exercisesData = topic.exercisesData) === null || _topic$exercisesData === void 0 ? void 0 : _topic$exercisesData.type) === "COMPREHENSIVE";
    };

    // 获取小题列表
    var getQuestionList = function getQuestionList(topic) {
      var _topic$paperExerciseS;
      return (topic === null || topic === void 0 || (_topic$paperExerciseS = topic.paperExerciseScore) === null || _topic$paperExerciseS === void 0 ? void 0 : _topic$paperExerciseS.childs) || [];
    };

    // 更改综合题小题分数
    var comprehensiveScoreChange = function comprehensiveScoreChange(topic) {
      var topicScore = 0;
      var list = getQuestionList(topic);
      list.forEach(function (question) {
        var score = question.score || 0;
        topicScore += question.score;
      });
      topic.score = topicScore;
    };

    // 清空快速设置
    var clearQuickSettings = function clearQuickSettings() {
      state.quickSettings.forEach(function (e) {
        e.value = null;
      });
    };

    // 快速设置分数
    var quickSettingsChange = function quickSettingsChange(val, item) {
      item.value = val;
      var type = EXERCISE_NAME_MAP[item.label];
      groupList.value.forEach(function (group) {
        group.paperNavItems.forEach(function (topic) {
          var _topic$exercisesData2;
          var topicType = topic === null || topic === void 0 || (_topic$exercisesData2 = topic.exercisesData) === null || _topic$exercisesData2 === void 0 ? void 0 : _topic$exercisesData2.type;
          if (topicType === "COMPREHENSIVE") {
            var _topic$paperExerciseS2;
            var topicList = (topic === null || topic === void 0 || (_topic$paperExerciseS2 = topic.paperExerciseScore) === null || _topic$paperExerciseS2 === void 0 ? void 0 : _topic$paperExerciseS2.childs) || [];
            topicList.forEach(function (e) {
              if (e.type === type) {
                e.score = item.value;
              }
            });
          } else if (topicType === type) {
            topic.paperExerciseScore.score = item.value;
          }
        });
      });
    };

    // 重置
    var handleSure = function handleSure() {
      var emptyTopic = []; // 分数为空的题
      var res = JSON.parse(JSON.stringify(state.copyPracticeData));
      res === null || res === void 0 || res.paperNavs.forEach(function (group) {
        group.paperNavItems.forEach(function (topic) {
          delete topic.showSetting;
          var _topic$paperExerciseS3 = topic === null || topic === void 0 ? void 0 : topic.paperExerciseScore,
            type = _topic$paperExerciseS3.type,
            score = _topic$paperExerciseS3.score,
            childs = _topic$paperExerciseS3.childs;
          var emptyTip = "\u7B2C".concat(numberToChinese(group.sort), "\u9898\u7EC4\u7684\u4E60\u9898").concat(topic.sort, "\u672A\u8BBE\u7F6E\u5206\u6570");
          if (type === "COMPREHENSIVE") {
            childs.forEach(function (c) {
              if (!c.score) {
                emptyTopic.push(emptyTip);
              }
            });
          } else {
            if (!score) emptyTopic.push(emptyTip);
          }
        });
      });
      // 判断空分题
      if (emptyTopic.length) {
        var dialog = DialogPlugin({
          header: "提示",
          body: emptyTopic.join("，"),
          theme: "warning",
          confirmBtn: null,
          cancelBtn: {
            content: "确认",
            theme: "primary"
          }
        });
      } else {
        emit("sure", res);
        handleClose();
      }
    };
    var handleInit = function handleInit() {
      var _state$copyPracticeDa2;
      // 拷贝习练数据
      state.copyPracticeData = JSON.parse(JSON.stringify(props.practiceData));

      // 快速设置中，可设置的习题类型
      var types = []; // 习题类型
      (_state$copyPracticeDa2 = state.copyPracticeData) === null || _state$copyPracticeDa2 === void 0 || _state$copyPracticeDa2.paperNavs.forEach(function (group) {
        group.paperNavItems.forEach(function (topic) {
          var _topic$exercisesData3 = topic === null || topic === void 0 ? void 0 : topic.exercisesData,
            type = _topic$exercisesData3.type,
            childs = _topic$exercisesData3.childs;
          if (type) {
            if (type === "COMPREHENSIVE") {
              childs === null || childs === void 0 || childs.forEach(function (t) {
                var idx = types.findIndex(function (e) {
                  return e.type === t.type;
                });
                idx === -1 && types.push({
                  type: type,
                  label: EXERCISE_CODE_MAP[t.type],
                  value: null
                });
              });
            } else {
              var index = types.findIndex(function (e) {
                return e.type === type;
              });
              index === -1 && types.push({
                type: type,
                label: EXERCISE_CODE_MAP[type],
                value: null
              });
            }
          }
        });
      });
      state.quickSettings = types;
    };
    var handleClose = function handleClose() {
      emit("update:visible", false);
    };
    return function (_ctx, _cache) {
      var _component_t_input_number = _resolveComponent("t-input-number");
      var _component_t_popup = _resolveComponent("t-popup");
      var _component_t_icon = _resolveComponent("t-icon");
      var _component_t_button = _resolveComponent("t-button");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        class: "set-score-dialog",
        visible: __props.visible,
        header: "设置分数",
        width: "950px",
        onClose: handleClose,
        onOpened: handleInit
      }, {
        footer: _withCtx(function () {
          return [_createVNode(_component_t_button, {
            theme: "default",
            onClick: handleClose
          }, {
            default: _withCtx(function () {
              return _cache[3] || (_cache[3] = [_createTextVNode("取消")]);
            }),
            _: 1
          }), _createVNode(_component_t_button, {
            theme: "default",
            onClick: handleInit
          }, {
            default: _withCtx(function () {
              return _cache[4] || (_cache[4] = [_createTextVNode("重置")]);
            }),
            _: 1
          }), _createVNode(_component_t_button, {
            onClick: handleSure
          }, {
            default: _withCtx(function () {
              return _cache[5] || (_cache[5] = [_createTextVNode("确认")]);
            }),
            _: 1
          })];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(state.copyPracticeData.title) + "（" + _toDisplayString(practiceScore.value) + "分）", 1), _createVNode(_component_t_popup, {
            class: "popup",
            trigger: "click",
            placement: "bottom-right",
            onVisibleChange: clearQuickSettings
          }, {
            content: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.quickSettings, function (item, index) {
                return _openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "quick-setting-item"
                }, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1), _createVNode(_component_t_input_number, {
                  value: item.value,
                  "onUpdate:value": function onUpdateValue($event) {
                    return item.value = $event;
                  },
                  min: "1",
                  onChange: function onChange($event) {
                    return quickSettingsChange($event, item);
                  }
                }, null, 8, ["value", "onUpdate:value", "onChange"]), _createElementVNode("span", _hoisted_5, "分/" + _toDisplayString(item.type === 'COMPLETION' ? '空' : '题'), 1)]);
              }), 128)), _cache[0] || (_cache[0] = _createElementVNode("div", {
                class: "quick-setting-item"
              }, [_createElementVNode("span", {
                class: "label"
              }, "综合题"), _createElementVNode("span", {
                class: "unit"
              }, "自动计算")], -1))])];
            }),
            default: _withCtx(function () {
              return [_cache[1] || (_cache[1] = _createElementVNode("span", {
                class: "text-btn"
              }, "快捷设置", -1))];
            }),
            _: 1
          })]), _createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("div", {
            class: "table-head flex-row"
          }, [_createElementVNode("span", {
            class: "flex-1 vertical-center"
          }, "序号"), _createElementVNode("span", {
            class: "flex-2 vertical-center"
          }, "题目简要"), _createElementVNode("span", {
            class: "flex-3 vertical-center"
          }, "设置分数")], -1)), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupList.value, function (group) {
            return _openBlock(), _createElementBlock("div", {
              key: group.id,
              class: "group-item"
            }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_unref(numberToChinese)(group.sort)) + "、", 1), _createElementVNode("span", null, _toDisplayString(group.name), 1), _createElementVNode("span", null, "（" + _toDisplayString(isNaN(getGroupScore(group)) ? '--' : getGroupScore(group)) + "分）", 1)]), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getTopicList(group), function (topic) {
              var _topic$exercisesData4;
              return _openBlock(), _createElementBlock("div", {
                key: topic.id,
                class: "topic-item"
              }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(topic.sort), 1), _createElementVNode("span", _hoisted_12, _toDisplayString(txtEllipsis(topic === null || topic === void 0 || (_topic$exercisesData4 = topic.exercisesData) === null || _topic$exercisesData4 === void 0 ? void 0 : _topic$exercisesData4.content)), 1), _createElementVNode("div", _hoisted_13, [isComprehensive(topic) ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["set-btn", {
                  'set-btn-active': topic.showSetting
                }]),
                onClick: function onClick($event) {
                  return topic.showSetting = !topic.showSetting;
                }
              }, [_createVNode(_component_t_icon, {
                name: "add",
                class: "icon"
              }), _createElementVNode("span", null, _toDisplayString(topic.showSetting ? '完成赋分' : '小题赋分'), 1)], 10, _hoisted_14)) : (_openBlock(), _createBlock(_component_t_input_number, {
                key: 1,
                value: topic.paperExerciseScore.score,
                "onUpdate:value": function onUpdateValue($event) {
                  return topic.paperExerciseScore.score = $event;
                },
                min: "1"
              }, null, 8, ["value", "onUpdate:value"]))])]), _withDirectives(_createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getQuestionList(topic), function (question, index) {
                return _openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "setting-item"
                }, [_createElementVNode("span", null, "（" + _toDisplayString(index + 1) + "）", 1), _createVNode(_component_t_input_number, {
                  value: question.score,
                  "onUpdate:value": function onUpdateValue($event) {
                    return question.score = $event;
                  },
                  min: "1",
                  class: "setting-item-score",
                  onChange: function onChange($event) {
                    return comprehensiveScoreChange(topic);
                  }
                }, null, 8, ["value", "onUpdate:value", "onChange"]), _createElementVNode("span", _hoisted_16, "分/" + _toDisplayString((question === null || question === void 0 ? void 0 : question.type) === 'COMPLETION' ? '空' : '题'), 1)]);
              }), 128))], 512), [[_vShow, topic.showSetting]])]);
            }), 128))])]);
          }), 128))])])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};