import _objectSpread from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "theme"
};
var _hoisted_2 = {
  class: "theme-body"
};
var _hoisted_3 = {
  class: "theme-body-center"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = ["src"];
var _hoisted_7 = ["src"];
import { ref } from "vue";
import { getStore, setStore } from "@/util/store";
import { setUserTheme } from '@/api/user';
import navLeft from "@/components/navLeft";
export default {
  __name: 'Theme',
  setup: function setup(__props) {
    var hoverTheme = require("@/assets/hover-theme.svg");
    var checkTheme = require("@/assets/check-theme.svg");
    var userInfo = getStore({
      name: "userInfo"
    }); // 获取userInfo

    var bgColor = ref(['#EEEEF0', '#DCDDE1', '#0052d9', '#8eabff', '#d54941', '#ff9285', '#e37318', '#fa9550', '#2ba471', '#92dab2', '#029cd4', '#85d3ff', '#8e56dd', '#dcbfff', '#f5ba18', '#ffd36d', '#e851b3', '#ffaedc']);
    var active = ref(userInfo.background || bgColor.value[0]);
    var themeClick = function themeClick(background) {
      setUserTheme({
        background: background
      }).then(function (res) {
        if (res.data.code === 0) {
          active.value = background;
          setStore({
            name: "userInfo",
            content: _objectSpread(_objectSpread({}, userInfo), {}, {
              background: background
            })
          });
        }
      });
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(navLeft)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "theme-body-top"
      }, "主题设置", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bgColor.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          style: _normalizeStyle({
            background: item
          }),
          onClick: function onClick($event) {
            return themeClick(item);
          }
        }, [active.value !== item ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
          src: _unref(hoverTheme)
        }, null, 8, _hoisted_6)])) : _createCommentVNode("", true), active.value === item ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(checkTheme)
        }, null, 8, _hoisted_7)) : _createCommentVNode("", true)], 12, _hoisted_4);
      }), 128))])])])]);
    };
  }
};