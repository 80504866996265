import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "topbar"
};
import { reactive, computed } from "vue";
/* 变量 */

export default {
  __name: 'index',
  props: {
    courseData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var props = __props;
    var courseData = reactive(props.courseData);
    /* 计算属性 */
    var breadcrumb = computed(function () {
      var periodDictItemName = courseData.periodDictItemName,
        subjectDictItemName = courseData.subjectDictItemName,
        tutorialVersionDictItemName = courseData.tutorialVersionDictItemName,
        tutorialName = courseData.tutorialName,
        firstTutorialDirName = courseData.firstTutorialDirName,
        secondTutorialDirName = courseData.secondTutorialDirName,
        threeTutorialDirName = courseData.threeTutorialDirName,
        fourTutorialDirName = courseData.fourTutorialDirName;
      return [periodDictItemName, subjectDictItemName, tutorialVersionDictItemName, tutorialName, firstTutorialDirName, secondTutorialDirName, threeTutorialDirName, fourTutorialDirName].filter(function (e) {
        return Boolean(e);
      });
    });
    return function (_ctx, _cache) {
      var _component_t_icon = _resolveComponent("t-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumb.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "item"
        }, [_createElementVNode("span", null, _toDisplayString(item), 1), index !== breadcrumb.value.length - 1 ? (_openBlock(), _createBlock(_component_t_icon, {
          key: 0,
          name: "chevron-right",
          class: "icon"
        })) : _createCommentVNode("", true)]);
      }), 128))]);
    };
  }
};