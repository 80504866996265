import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { ref } from 'vue'
import { closeNotification, pollingForBacklog } from '@/api/todo.js'
import { validateNull } from '@/util/validate'

dayjs.extend(isSameOrAfter)

export const useNotification = () => {
  /**
   * 存在的待办提醒
   */
  const activeNotification = ref(null)
  const timer = ref(null)
  const requestTime = ref(null)


  /**
   * 轮询获取一条待办提醒
   */
  const pollingForBacklogRequest = async () => {
    const res = await pollingForBacklog()
    if (res) {
      const { data } = res.data
      addSetTimeoutEvent()
      if (!validateNull(data)) {
        activeNotification.value = { ...data }
      }
    }
  }
  /**
   * 消息提醒关闭
   */
  const closeNotificationEvent = async () => {
    if (activeNotification.value === null) return
    closeAllTimeEvent()
    const { id, backlogType } = activeNotification.value
    await closeNotification({ id, backlogType })
    activeNotification.value = null
    pollingForBacklogRequest()
  }

  /**
   * 删除定时
   */
  const closeSetTimeoutEvent = () => {
    if (timer.value) {
      clearInterval(timer.value)
      timer.value = null
    }
  }
    /**
   * 添加定时器
   */
  const addSetTimeoutEvent = () => {
    const nowSS = Number(dayjs().format('ss') % 30)
    const offset = 30 - Number(nowSS) || 1
    closeSetTimeoutEvent()
    if (validateNull(timer.value)) {
      timer.value = setInterval(pollingForBacklogRequest, offset * 1000)
    }
  }
  /**
   * 关闭请求定时
   */
  const closeRequestTimeEvent = () => {
    if (requestTime.value) {
      clearTimeout(requestTime.value)
      requestTime.value = null
    }
  }
  /**
   * 关闭所有定时
   */
  const closeAllTimeEvent = () => {
    closeRequestTimeEvent()
    closeSetTimeoutEvent()
  }
  /**
   * 初始化
   */
  const initNotification = () => {
    closeAllTimeEvent()
    pollingForBacklogRequest()
  }
  return {
    activeNotification,
    initNotification,
    closeAllTimeEvent,
    closeNotificationEvent,
  }
}

    