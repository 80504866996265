import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "course-card card"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "course-info"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  key: 0,
  class: "author-info"
};
import copyright from '../widgets/copyright';
/* 工具 */
import { ref } from "vue";
import { escape2Html } from "@/util/util";
/* 变量 */

export default {
  __name: 'courseCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 组件 */
    var defaultImg = require("./course-card.png");
    var visable = ref(false);
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: __props.cardData.data.banner || _unref(defaultImg),
        class: "bg"
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(escape2Html)(__props.cardData.data.title)), 1), __props.cardData.data.creatorSchool || __props.cardData.data.creator ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(__props.cardData.data.creatorSchool), 1), _createElementVNode("span", null, _toDisplayString(__props.cardData.data.creator), 1)])) : _createCommentVNode("", true), _createElementVNode("span", {
        class: "copyright",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return visable.value = true;
        })
      }, "版权声明")]), _createVNode(_unref(copyright), {
        visable: visable.value,
        "onUpdate:visable": _cache[1] || (_cache[1] = function ($event) {
          return visable.value = $event;
        })
      }, null, 8, ["visable"])]);
    };
  }
};