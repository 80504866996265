import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = {
  class: "base-list-body"
};
var _hoisted_2 = {
  class: "top-body"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "list-body"
};
var _hoisted_6 = {
  key: 0,
  class: "empty-body"
};
export default {
  __name: 'BaseList',
  props: {
    title: {
      type: String,
      default: ""
    },
    showMore: {
      type: Boolean,
      default: true
    },
    empty: {
      type: Boolean,
      default: true
    }
  },
  emits: ["change"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var rightEnter = require('@/assets/home/double.svg');
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h5", _hoisted_3, _toDisplayString(__props.title), 1), !__props.empty ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "right-box",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return emit('change');
        })
      }, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "查看更多", -1)), _createElementVNode("img", {
        src: _unref(rightEnter),
        alt: ""
      }, null, 8, _hoisted_4)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [__props.empty ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("p", null, "暂无内容", -1)), _renderSlot(_ctx.$slots, "empty-footer")])) : _renderSlot(_ctx.$slots, "body", {
        key: 1
      })])]);
    };
  }
};