import _objectSpread from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "user"
};
var _hoisted_2 = {
  class: "user-body"
};
var _hoisted_3 = {
  class: "user-body-center"
};
var _hoisted_4 = {
  style: {
    "position": "relative",
    "margin-bottom": "48px"
  }
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  key: 0,
  class: "member silver-member"
};
var _hoisted_7 = {
  key: 1,
  class: "member gold-member"
};
var _hoisted_8 = {
  key: 2,
  class: "member diamond-member"
};
var _hoisted_9 = {
  class: "user-body-center-file"
};
var _hoisted_10 = {
  class: "user-body-center-li"
};
var _hoisted_11 = {
  class: "user-body-center-li-left"
};
var _hoisted_12 = {
  key: 0,
  class: "user-body-center-li"
};
var _hoisted_13 = {
  class: "user-body-center-li-left"
};
var _hoisted_14 = {
  key: 1,
  class: "user-body-center-li"
};
var _hoisted_15 = {
  class: "user-body-center-li-left"
};
var _hoisted_16 = {
  class: "user-body-center-li"
};
var _hoisted_17 = {
  class: "user-body-center-li-left"
};
var _hoisted_18 = {
  class: "user-body-center-li"
};
var _hoisted_19 = {
  class: "user-body-center-li-left"
};
var _hoisted_20 = {
  class: "user-body-center-li"
};
var _hoisted_21 = {
  class: "user-body-center-li-left group"
};
var _hoisted_22 = {
  class: "user-body-center-li"
};
var _hoisted_23 = {
  class: "user-body-center-li-left"
};
var _hoisted_24 = {
  class: "user-body-center-li"
};
var _hoisted_25 = {
  class: "user-body-center-li-left"
};
var _hoisted_26 = {
  class: "user-body-center-li"
};
var _hoisted_27 = {
  class: "user-body-center-li-left"
};
var _hoisted_28 = {
  class: "user-body-center-li"
};
var _hoisted_29 = {
  class: "user-body-center-li-left cer-li"
};
var _hoisted_30 = {
  class: "cer-box"
};
var _hoisted_31 = ["src"];
var _hoisted_32 = ["src", "onClick"];
var _hoisted_33 = {
  style: {
    "display": "flex"
  }
};
var _hoisted_34 = {
  class: "text1"
};
var _hoisted_35 = {
  class: "text2"
};
var _hoisted_36 = {
  class: "text1"
};
var _hoisted_37 = {
  class: "text2"
};
var _hoisted_38 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "padding-bottom": "10px"
  }
};
var _hoisted_39 = ["src"];
import { ref } from "vue";
import { getStore, setStore } from "@/util/store";
import { getUpdatePersonal, getDictItem, getCommonModeration, getVipHeader } from "@/api/user.js";
import { getMyGroupList, getGroupQrCode, getGroupAdd, getGroupAddList, getGroupDel } from "@/api/home.js";
import { uploadObs } from "hxhy-obs";
import { MessagePlugin } from 'tdesign-vue-next';
import { validateName } from "@/util/validate";
import { env_name } from "@/util/util";
import { SearchIcon } from 'tdesign-icons-vue-next';
import navLeft from "@/components/navLeft";
import imgCropper from "@/components/imgCropper";
import AvatarDialog from "@/components/chooseAvatar/avatarDialog.vue";
export default {
  __name: 'UserInfo',
  setup: function setup(__props) {
    var userAvatar = require("@/assets/defaultAvatar1.png");
    var accessInfo = getStore({
      name: "accessInfo"
    }); // 获取accessInfo
    var userInfo = getStore({
      name: "userInfo"
    }); // 获取userInfo

    var nickname = ref(userInfo.name);
    var nicknameVisible = ref(false);
    var nicknameOld = ref(userInfo.name);
    var nicknameHandleCancel = function nicknameHandleCancel() {
      nicknameOld.value = nickname.value;
    };
    var gradeList = ref([]);
    getDictItem("grade").then(function (res) {
      if (res.data.code === 0) {
        gradeList.value = res.data.data;
      }
    });
    var gradeDictItemCode = ref(userInfo.gradeDictItemCode);
    var gradeDictItemName = ref(userInfo.gradeDictItemName);
    var gradeVisible = ref(false);
    var gradeDictItemCodeOld = ref(userInfo.gradeDictItemCode);
    var gradeDictItemNameOld = ref(userInfo.gradeDictItemName);
    var gradeHandleCancel = function gradeHandleCancel() {
      gradeDictItemCodeOld.value = gradeDictItemCode.value;
      gradeDictItemNameOld.value = gradeDictItemName.value;
    };
    var gradeChange = function gradeChange(code, data) {
      gradeDictItemNameOld.value = data.selectedOptions[0].label;
    };
    var periodList = ref([]);
    getDictItem("period").then(function (res) {
      if (res.data.code === 0) {
        periodList.value = res.data.data;
      }
    });
    var subjectList = ref([]);
    getDictItem("subject").then(function (res) {
      if (res.data.code === 0) {
        subjectList.value = res.data.data;
      }
    });
    var periodDictItemCode = ref(userInfo.periodDictItemCode);
    var periodDictItemName = ref(userInfo.periodDictItemName);
    var subjectDictItemCode = ref(userInfo.subjectDictItemCode);
    var subjectDictItemName = ref(userInfo.subjectDictItemName);
    var periodVisible = ref(false);
    var periodDictItemCodeOld = ref(userInfo.periodDictItemCode);
    var periodDictItemNameOld = ref(userInfo.periodDictItemName);
    var subjectDictItemCodeOld = ref(userInfo.subjectDictItemCode);
    var subjectDictItemNameOld = ref(userInfo.subjectDictItemName);
    var periodHandleCancel = function periodHandleCancel() {
      periodDictItemCodeOld.value = periodDictItemCode.value;
      periodDictItemNameOld.value = periodDictItemName.value;
      subjectDictItemCodeOld.value = subjectDictItemCode.value;
      subjectDictItemNameOld.value = subjectDictItemName.value;
    };
    var periodChange = function periodChange(code, data) {
      periodDictItemNameOld.value = data.selectedOptions[0].label;
    };
    var subjectChange = function subjectChange(code, data) {
      subjectDictItemNameOld.value = data.selectedOptions[0].label;
    };
    var schools = ref(userInfo.schools || []);
    var schoolsVisible = ref(false);
    var schoolsOld = ref(schools.value.join());
    var schoolsHandleCancel = function schoolsHandleCancel() {
      schoolsOld.value = schools.value.join();
    };
    var depts = ref(userInfo.depts || []);
    var deptsVisible = ref(false);
    var deptsOld = ref(depts.value.join());
    var deptsHandleCancel = function deptsHandleCancel() {
      deptsOld.value = depts.value.join();
    };
    var jobs = ref(userInfo.jobs || []);
    var jobsVisible = ref(false);
    var jobsOld = ref(jobs.value.join());
    var jobsHandleCancel = function jobsHandleCancel() {
      jobsOld.value = jobs.value.join();
    };
    var isEditSummary = ref(userInfo.editSummary); // 7天可修改1个人简介 ，暂无修改次数
    var summary = ref(userInfo.summary);
    var summaryVisible = ref(false);
    var summaryOld = ref(userInfo.summary);
    var summaryHandleCancel = function summaryHandleCancel() {
      summaryOld.value = summary.value;
      summaryVisible.value = false;
    };
    var resume = ref(userInfo.resume);
    var resumeVisible = ref(false);
    var resumeOld = ref(userInfo.resume);
    var resumeHandleCancel = function resumeHandleCancel() {
      resumeOld.value = resume.value;
    };
    var handleOk = function handleOk() {
      if (nicknameVisible.value) {
        if (validateName(nicknameOld.value)) {
          getCommonModeration({
            eventType: "nickname",
            text: nicknameOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              getUpdatePersonal({
                id: userInfo.id,
                nickname: nicknameOld.value
              }).then(function (res) {
                if (res.data.code === 0) {
                  MessagePlugin.closeAll();
                  MessagePlugin.success("昵称修改成功");
                  nickname.value = nicknameOld.value;
                  accessInfo = _objectSpread(_objectSpread({}, accessInfo), {}, {
                    nickname: nickname.value
                  });
                  userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                    name: nickname.value
                  });
                  setStore({
                    name: "accessInfo",
                    content: accessInfo
                  });
                  setStore({
                    name: "userInfo",
                    content: userInfo
                  });
                  nicknameVisible.value = false;
                }
              });
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("昵称包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          MessagePlugin.closeAll();
          MessagePlugin.warning("请输入汉字、数字、英文字母和_，不支持其他字符");
        }
      } else if (gradeVisible.value) {
        if (gradeDictItemCodeOld.value && gradeDictItemNameOld.value) {
          getUpdatePersonal({
            id: userInfo.id,
            gradeDictItemCode: gradeDictItemCodeOld.value,
            gradeDictItemName: gradeDictItemNameOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("任教年级修改成功");
              gradeDictItemCode.value = gradeDictItemCodeOld.value;
              gradeDictItemName.value = gradeDictItemNameOld.value;
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                gradeDictItemCode: gradeDictItemCode.value,
                gradeDictItemName: gradeDictItemName.value
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              gradeVisible.value = false;
            }
          });
        } else {
          MessagePlugin.closeAll();
          MessagePlugin.warning("任教年级必须选择");
        }
      } else if (periodVisible.value) {
        if (periodDictItemCodeOld.value && periodDictItemNameOld.value && subjectDictItemCodeOld.value && subjectDictItemNameOld.value) {
          getUpdatePersonal({
            id: userInfo.id,
            periodDictItemCode: periodDictItemCodeOld.value,
            periodDictItemName: periodDictItemNameOld.value,
            subjectDictItemCode: subjectDictItemCodeOld.value,
            subjectDictItemName: subjectDictItemNameOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("任教学科修改成功");
              periodDictItemCode.value = periodDictItemCodeOld.value;
              periodDictItemName.value = periodDictItemNameOld.value;
              subjectDictItemCode.value = subjectDictItemCodeOld.value;
              subjectDictItemName.value = subjectDictItemNameOld.value;
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                periodDictItemCode: periodDictItemCode.value,
                periodDictItemName: periodDictItemName.value,
                subjectDictItemCode: subjectDictItemCode.value,
                subjectDictItemName: subjectDictItemName.value
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              periodVisible.value = false;
            }
          });
        } else {
          MessagePlugin.closeAll();
          MessagePlugin.warning("任教学科必须选择");
        }
      } else if (schoolsVisible.value) {
        var fun = function fun() {
          getUpdatePersonal({
            id: userInfo.id,
            school: schoolsOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("所属学校修改成功");
              schools.value = schoolsOld.value.split();
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                schools: schoolsOld.value.split()
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              schoolsVisible.value = false;
            }
          });
        };
        if (schoolsOld.value) {
          getCommonModeration({
            eventType: "title",
            text: schoolsOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              fun();
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("所属学校包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          fun();
        }
      } else if (deptsVisible.value) {
        var _fun = function _fun() {
          getUpdatePersonal({
            id: userInfo.id,
            dept: deptsOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("所属部门修改成功");
              depts.value = deptsOld.value.split();
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                depts: deptsOld.value.split()
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              deptsVisible.value = false;
            }
          });
        };
        if (deptsOld.value) {
          getCommonModeration({
            eventType: "title",
            text: deptsOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              _fun();
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("所属部门包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          _fun();
        }
      } else if (jobsVisible.value) {
        var _fun2 = function _fun2() {
          getUpdatePersonal({
            id: userInfo.id,
            job: jobsOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("职务修改成功");
              jobs.value = jobsOld.value.split();
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                jobs: jobsOld.value.split()
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              jobsVisible.value = false;
            }
          });
        };
        if (jobsOld.value) {
          getCommonModeration({
            eventType: "title",
            text: jobsOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              _fun2();
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("职务包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          _fun2();
        }
      } else if (summaryVisible.value) {
        var _fun3 = function _fun3() {
          getUpdatePersonal({
            id: userInfo.id,
            summary: summaryOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("个人简介修改成功");
              summary.value = summaryOld.value;
              isEditSummary.value = false;
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                summary: summaryOld.value,
                editSummary: false
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              summaryVisible.value = false;
            }
          });
        };
        if (summaryOld.value) {
          getCommonModeration({
            eventType: "profile",
            text: summaryOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              _fun3();
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("个人简介包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          _fun3();
        }
      } else if (resumeVisible.value) {
        var _fun4 = function _fun4() {
          getUpdatePersonal({
            id: userInfo.id,
            resume: resumeOld.value
          }).then(function (res) {
            if (res.data.code === 0) {
              MessagePlugin.closeAll();
              MessagePlugin.success("履历介绍修改成功");
              resume.value = resumeOld.value;
              userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                resume: resumeOld.value
              });
              setStore({
                name: "userInfo",
                content: userInfo
              });
              resumeVisible.value = false;
            }
          });
        };
        if (resumeOld.value) {
          getCommonModeration({
            eventType: "profile",
            text: resumeOld.value
          }).then(function (res) {
            if (res.data.code === 0 && res.data.data.suggestion === 'pass') {
              _fun4();
            } else {
              MessagePlugin.closeAll();
              MessagePlugin.error("履历介绍包含法律法规或监管政策所限制的敏感内容，请修改后再发布。");
            }
          });
        } else {
          _fun4();
        }
      }
    };
    /*选择头像*/
    var avatarVisible = ref(false);
    var updateAvatar = function updateAvatar(avatar) {
      getUpdatePersonal({
        id: userInfo.id,
        avatar: avatar
      }).then(function (res) {
        if (res.data.code === 0) {
          MessagePlugin.closeAll();
          MessagePlugin.success("头像修改成功");
          fileLoading.value = false;
          avatarUrl.value = avatar;
          userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
            avatar: avatar
          });
          setStore({
            name: "userInfo",
            content: userInfo
          });
        }
      });
    };
    var handleAvatar = function handleAvatar() {
      avatarVisible.value = true;
    };
    /*选择头像end*/
    // 图片上传裁剪
    var avatarUrl = ref(userInfo.avatar);
    var fileValue = ref();
    var iscropper = ref();
    var fileLoading = ref(false);
    var getImg = function getImg(e) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        fileValue.value.value = '';
        iscropper.value.showModal({
          img: reader.result,
          // 裁剪图片的地址
          success: function success(blob) {
            fileLoading.value = true;
            uploadObs({
              bucketName: "hxhy-base-".concat(env_name || 'prod'),
              file: new File([blob], file.name),
              fileType: 2,
              userId: accessInfo.user_id,
              userName: accessInfo.username,
              institutionId: userInfo.institutionId,
              institutionName: userInfo.institutionName,
              appCode: 'main',
              appName: '主应用',
              pageName: 'userInfo',
              btnName: '修改头像'
            }).then(function (avatar) {
              getUpdatePersonal({
                id: userInfo.id,
                avatar: avatar
              }).then(function (res) {
                if (res.data.code === 0) {
                  MessagePlugin.closeAll();
                  MessagePlugin.success("头像修改成功");
                  fileLoading.value = false;
                  avatarUrl.value = avatar;
                  userInfo = _objectSpread(_objectSpread({}, userInfo), {}, {
                    avatar: avatar
                  });
                  setStore({
                    name: "userInfo",
                    content: userInfo
                  });
                }
              });
            });
          }
        });
      };
    };
    // 群组功能
    var groupHeader = ref(null);
    var groupValue = ref(null);
    var groupVisible = ref(false);
    var groupListVisible = ref(false);
    var groupIndex = ref(null); // 0加入群组输入框 1加入群组 2退出群组
    var groupItem = ref({});
    var groupName = ref([]);
    var groupAddList = ref([]);
    var total = ref(0);
    var current = ref(1);
    var getMyGroup = function getMyGroup() {
      getMyGroupList(accessInfo.user_id).then(function (res) {
        if (res.data.code === 0) {
          groupName.value = res.data.data;
        }
      });
    };
    getMyGroup();
    var getGroupAddListFun = function getGroupAddListFun() {
      getGroupAddList(accessInfo.user_id, {
        current: current.value,
        size: 10,
        queryKey: groupValue.value,
        type: 0
      }).then(function (res) {
        if (res.data.code === 0) {
          groupAddList.value = res.data.data.records;
          total.value = res.data.data.total;
        }
      });
    };
    var openGroup = function openGroup(type) {
      if (type) {
        groupHeader.value = "加入群组";
        groupIndex.value = 0;
        groupValue.value = "";
        groupVisible.value = true;
      } else {
        current.value = 1;
        groupHeader.value = "退出群组";
        groupValue.value = "";
        groupListVisible.value = true;
        getGroupAddListFun();
      }
    };
    var groupHandleOk = function groupHandleOk() {
      if (groupIndex.value === 0) {
        if (groupValue.value && Number(groupValue.value)) {
          getGroupQrCode(groupValue.value).then(function (res) {
            if (res.data.code === 0) {
              groupItem.value = res.data.data;
              groupIndex.value = 1;
            }
          });
        } else {
          MessagePlugin.closeAll();
          MessagePlugin.warning("请输入正确的群组号");
        }
      } else if (groupIndex.value === 1) {
        getGroupAdd({
          userId: accessInfo.user_id,
          name: userInfo.name,
          username: accessInfo.username,
          sex: accessInfo.sex === null ? 2 : accessInfo.sex,
          avatar: userInfo.avatar,
          memberType: userInfo.memberType ? userInfo.memberType.join('、') : null,
          institutionId: userInfo.institutionId,
          institutionName: userInfo.institutionName,
          groupId: groupItem.value.id,
          groupName: groupItem.value.name,
          announcementCode: groupItem.value.announcementCode,
          groupQrCode: groupItem.value.groupQrCode
        }).then(function (res) {
          if (res.data.code === 0) {
            MessagePlugin.closeAll();
            MessagePlugin.success("群组申请添加成功，请等待群管理员审核");
            groupVisible.value = false;
            getMyGroup();
          }
        });
      } else if (groupIndex.value === 2) {
        getGroupDel(groupItem.value.groupId, {
          userId: accessInfo.user_id
        }).then(function (res) {
          if (res.data.code === 0) {
            MessagePlugin.closeAll();
            MessagePlugin.success("群组退出成功");
            groupVisible.value = false;
            getMyGroup();
          }
        });
      }
    };
    var delgroup = function delgroup(item) {
      groupListVisible.value = false;
      groupVisible.value = true;
      groupItem.value = item;
      groupIndex.value = 2;
    };

    // 会员头像框
    var vipMember = ref(null);
    getVipHeader(accessInfo.user_id).then(function (res) {
      if (res.data.code === 0) {
        vipMember.value = res.data.data;
      }
    });
    return function (_ctx, _cache) {
      var _component_t_loading = _resolveComponent("t-loading");
      var _component_t_button = _resolveComponent("t-button");
      var _component_t_image_viewer = _resolveComponent("t-image-viewer");
      var _component_t_input = _resolveComponent("t-input");
      var _component_t_dialog = _resolveComponent("t-dialog");
      var _component_t_option = _resolveComponent("t-option");
      var _component_t_select = _resolveComponent("t-select");
      var _component_sapn = _resolveComponent("sapn");
      var _component_t_textarea = _resolveComponent("t-textarea");
      var _component_t_radio = _resolveComponent("t-radio");
      var _component_t_table = _resolveComponent("t-table");
      var _component_t_pagination = _resolveComponent("t-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(navLeft)), _createElementVNode("div", _hoisted_2, [_cache[51] || (_cache[51] = _createElementVNode("div", {
        class: "user-body-top"
      }, " 个人信息 ", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: avatarUrl.value || _unref(userAvatar)
      }, null, 8, _hoisted_5), vipMember.value === 'silver_member' ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("", true), vipMember.value === 'gold_member' ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true), vipMember.value === 'diamond_member' ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_9, [!fileLoading.value ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        onClick: handleAvatar
      }, "修改头像")) : _createCommentVNode("", true), fileLoading.value ? (_openBlock(), _createBlock(_component_t_loading, {
        key: 1
      })) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(_unref(userInfo).accountType === 2 ? '昵称' : '姓名'), 1), _createElementVNode("div", null, _toDisplayString(nickname.value), 1)]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        variant: "outline",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return nicknameVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[34] || (_cache[34] = [_createTextVNode("修改")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_cache[35] || (_cache[35] = _createElementVNode("span", null, "主要任教年级", -1)), _createElementVNode("div", null, _toDisplayString(gradeDictItemName.value), 1)]), _createVNode(_component_t_button, {
        variant: "outline",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return gradeVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[36] || (_cache[36] = [_createTextVNode("修改")]);
        }),
        _: 1
      })])) : _createCommentVNode("", true), !(_unref(userInfo).memberType && _unref(userInfo).memberType.includes('student')) ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_cache[37] || (_cache[37] = _createElementVNode("span", null, "主要任教学科", -1)), _createElementVNode("div", null, _toDisplayString(periodDictItemName.value) + " " + _toDisplayString(subjectDictItemName.value), 1)]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        variant: "outline",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return periodVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[38] || (_cache[38] = [_createTextVNode("修改")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_cache[39] || (_cache[39] = _createElementVNode("span", null, "所属学校", -1)), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(schools.value, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item), 1);
      }), 128))])]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        variant: "outline",
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return schoolsVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[40] || (_cache[40] = [_createTextVNode("修改")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_cache[41] || (_cache[41] = _createElementVNode("span", null, "所属部门", -1)), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(depts.value, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item), 1);
      }), 128))])]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        variant: "outline",
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return deptsVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[42] || (_cache[42] = [_createTextVNode("修改")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_cache[43] || (_cache[43] = _createElementVNode("span", null, "群组", -1)), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupName.value, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item), 1);
      }), 128))])])]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_cache[44] || (_cache[44] = _createElementVNode("span", null, "职务", -1)), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jobs.value, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item), 1);
      }), 128))])]), _unref(userInfo).accountType === 2 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        variant: "outline",
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return jobsVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[45] || (_cache[45] = [_createTextVNode("修改")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_cache[46] || (_cache[46] = _createElementVNode("div", null, [_createElementVNode("span", {
        class: "user-body-center-li-left-introduce"
      }, "个人简介"), _createElementVNode("span", null, "(将展示在个人名片和个人主页上)")], -1)), _createElementVNode("div", null, _toDisplayString(_unref(userInfo).summary), 1)]), _createVNode(_component_t_button, {
        variant: "outline",
        onClick: _cache[6] || (_cache[6] = function ($event) {
          return summaryVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[47] || (_cache[47] = [_createTextVNode("修改")]);
        }),
        _: 1
      })]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_cache[48] || (_cache[48] = _createElementVNode("span", null, "履历介绍", -1)), _createElementVNode("div", null, _toDisplayString(resume.value), 1)]), _createVNode(_component_t_button, {
        variant: "outline",
        onClick: _cache[7] || (_cache[7] = function ($event) {
          return resumeVisible.value = true;
        })
      }, {
        default: _withCtx(function () {
          return _cache[49] || (_cache[49] = [_createTextVNode("修改")]);
        }),
        _: 1
      })]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_cache[50] || (_cache[50] = _createElementVNode("span", null, "我的奖状", -1)), _createElementVNode("div", _hoisted_30, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userInfo).certificateUrlList, function (item, index) {
        var _item$pubTime, _item$pubTime2, _item$pubTime3;
        return _openBlock(), _createElementBlock("div", {
          class: "cer-item",
          key: index,
          src: item.url
        }, [_createVNode(_component_t_image_viewer, {
          images: [item.url]
        }, {
          trigger: _withCtx(function (_ref) {
            var open = _ref.open;
            return [_createElementVNode("img", {
              alt: "test",
              src: item.url,
              class: "header-img",
              onClick: open
            }, null, 8, _hoisted_32)];
          }),
          _: 2
        }, 1032, ["images"]), _createElementVNode("div", null, _toDisplayString(item === null || item === void 0 || (_item$pubTime = item.pubTime) === null || _item$pubTime === void 0 ? void 0 : _item$pubTime.split('-')[0]) + "年" + _toDisplayString(item === null || item === void 0 || (_item$pubTime2 = item.pubTime) === null || _item$pubTime2 === void 0 ? void 0 : _item$pubTime2.split('-')[1]) + "月" + _toDisplayString(item === null || item === void 0 || (_item$pubTime3 = item.pubTime) === null || _item$pubTime3 === void 0 ? void 0 : _item$pubTime3.split('-')[2]) + "日获得", 1)], 8, _hoisted_31);
      }), 128))])])])])]), _createVNode(_component_t_dialog, {
        header: "修改昵称",
        visible: nicknameVisible.value,
        "onUpdate:visible": _cache[9] || (_cache[9] = function ($event) {
          return nicknameVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: nicknameHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_input, {
            value: nicknameOld.value,
            "onUpdate:value": _cache[8] || (_cache[8] = function ($event) {
              return nicknameOld.value = $event;
            }),
            maxlength: 20,
            placeholder: "请输入昵称"
          }, null, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "修改任教年级",
        visible: gradeVisible.value,
        "onUpdate:visible": _cache[11] || (_cache[11] = function ($event) {
          return gradeVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: gradeHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_select, {
            style: {
              "width": "100%"
            },
            value: gradeDictItemCodeOld.value,
            "onUpdate:value": _cache[10] || (_cache[10] = function ($event) {
              return gradeDictItemCodeOld.value = $event;
            }),
            onChange: gradeChange
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gradeList.value, function (item) {
                return _openBlock(), _createBlock(_component_t_option, {
                  key: item.displayName,
                  value: item.code,
                  label: item.displayName
                }, null, 8, ["value", "label"]);
              }), 128))];
            }),
            _: 1
          }, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "修改任教学科",
        visible: periodVisible.value,
        "onUpdate:visible": _cache[14] || (_cache[14] = function ($event) {
          return periodVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: periodHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_33, [_createVNode(_component_t_select, {
            value: periodDictItemCodeOld.value,
            "onUpdate:value": _cache[12] || (_cache[12] = function ($event) {
              return periodDictItemCodeOld.value = $event;
            }),
            onChange: periodChange
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(periodList.value, function (item) {
                return _openBlock(), _createBlock(_component_t_option, {
                  key: item.displayName,
                  value: item.code,
                  label: item.displayName
                }, null, 8, ["value", "label"]);
              }), 128))];
            }),
            _: 1
          }, 8, ["value"]), _createVNode(_component_t_select, {
            value: subjectDictItemCodeOld.value,
            "onUpdate:value": _cache[13] || (_cache[13] = function ($event) {
              return subjectDictItemCodeOld.value = $event;
            }),
            onChange: subjectChange
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subjectList.value, function (item) {
                return _openBlock(), _createBlock(_component_t_option, {
                  key: item.displayName,
                  value: item.code,
                  label: item.displayName
                }, null, 8, ["value", "label"]);
              }), 128))];
            }),
            _: 1
          }, 8, ["value"])])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "修改所属学校",
        visible: schoolsVisible.value,
        "onUpdate:visible": _cache[16] || (_cache[16] = function ($event) {
          return schoolsVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: schoolsHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_input, {
            value: schoolsOld.value,
            "onUpdate:value": _cache[15] || (_cache[15] = function ($event) {
              return schoolsOld.value = $event;
            }),
            placeholder: "请输入所属学校"
          }, null, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "修改所属部门",
        visible: deptsVisible.value,
        "onUpdate:visible": _cache[18] || (_cache[18] = function ($event) {
          return deptsVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: deptsHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_input, {
            value: deptsOld.value,
            "onUpdate:value": _cache[17] || (_cache[17] = function ($event) {
              return deptsOld.value = $event;
            }),
            placeholder: "请输入所属部门"
          }, null, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "修改职务",
        visible: jobsVisible.value,
        "onUpdate:visible": _cache[20] || (_cache[20] = function ($event) {
          return jobsVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: jobsHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_input, {
            value: jobsOld.value,
            "onUpdate:value": _cache[19] || (_cache[19] = function ($event) {
              return jobsOld.value = $event;
            }),
            placeholder: "请输入职务"
          }, null, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_t_dialog, {
        header: "",
        visible: summaryVisible.value,
        "onUpdate:visible": _cache[22] || (_cache[22] = function ($event) {
          return summaryVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: summaryHandleCancel,
        onConfirm: handleOk,
        footer: !isEditSummary.value ? null : ''
      }, {
        header: _withCtx(function () {
          return [_cache[52] || (_cache[52] = _createTextVNode(" 修改个人简介")), _createVNode(_component_sapn, {
            style: {
              "font-size": "12px",
              "color": "#DE3F3F",
              "font-weight": "normal"
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("（" + _toDisplayString(isEditSummary.value ? '7天可修改1次简介' : '7天内只能修改1次简介') + "）", 1)];
            }),
            _: 1
          })];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_t_textarea, {
            disabled: !isEditSummary.value,
            value: summaryOld.value,
            "onUpdate:value": _cache[21] || (_cache[21] = function ($event) {
              return summaryOld.value = $event;
            }),
            autosize: {
              minRows: 5,
              maxRows: 5
            },
            maxlength: isEditSummary.value ? 100 : 0,
            placeholder: "请简要的介绍一下自己，限100字。"
          }, null, 8, ["disabled", "value", "maxlength"])];
        }),
        _: 1
      }, 8, ["visible", "footer"]), _createVNode(_component_t_dialog, {
        header: "修改履历介绍",
        visible: resumeVisible.value,
        "onUpdate:visible": _cache[24] || (_cache[24] = function ($event) {
          return resumeVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onClose: resumeHandleCancel,
        onConfirm: handleOk
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_textarea, {
            value: resumeOld.value,
            "onUpdate:value": _cache[23] || (_cache[23] = function ($event) {
              return resumeOld.value = $event;
            }),
            autosize: {
              minRows: 5,
              maxRows: 5
            },
            maxlength: 500,
            placeholder: "请简单的介绍一下自己的履历，限制500字。"
          }, null, 8, ["value"])];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_unref(imgCropper), {
        ref_key: "iscropper",
        ref: iscropper
      }, null, 512), _createVNode(_component_t_dialog, {
        header: groupHeader.value,
        visible: groupVisible.value,
        "onUpdate:visible": _cache[26] || (_cache[26] = function ($event) {
          return groupVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        onConfirm: groupHandleOk
      }, {
        default: _withCtx(function () {
          return [groupIndex.value === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createVNode(_component_t_radio, {
            checked: true
          }, {
            default: _withCtx(function () {
              return _cache[53] || (_cache[53] = [_createTextVNode("输入群号查找群组")]);
            }),
            _: 1
          }), _createVNode(_component_t_input, {
            class: "input-number",
            modelValue: groupValue.value,
            "onUpdate:modelValue": _cache[25] || (_cache[25] = function ($event) {
              return groupValue.value = $event;
            }),
            placeholder: "请输入群组号",
            maxlengt: 19
          }, null, 8, ["modelValue"])], 64)) : _createCommentVNode("", true), groupIndex.value === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createElementVNode("div", _hoisted_34, "确认申请加入群组：" + _toDisplayString(groupItem.value.name), 1), _createElementVNode("div", _hoisted_35, "群组号：" + _toDisplayString(groupItem.value.groupQrCode), 1)], 64)) : _createCommentVNode("", true), groupIndex.value === 2 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 2
          }, [_createElementVNode("div", _hoisted_36, "确认退出群组：" + _toDisplayString(groupItem.value.groupName), 1), _createElementVNode("div", _hoisted_37, "群组号：" + _toDisplayString(groupItem.value.groupQrCode), 1)], 64)) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["header", "visible"]), _createVNode(_component_t_dialog, {
        header: groupHeader.value,
        visible: groupListVisible.value,
        "onUpdate:visible": _cache[32] || (_cache[32] = function ($event) {
          return groupListVisible.value = $event;
        }),
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        footer: false,
        width: "800px"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_38, [_cache[54] || (_cache[54] = _createTextVNode(" 请点击需要退出的群组！ ")), _createVNode(_component_t_input, {
            modelValue: groupValue.value,
            "onUpdate:modelValue": _cache[28] || (_cache[28] = function ($event) {
              return groupValue.value = $event;
            }),
            style: {
              "width": "280px"
            },
            type: "search",
            placeholder: "输入群组名称进行搜索",
            onEnter: _cache[29] || (_cache[29] = function () {
              current.value = 1;
              getGroupAddListFun();
            })
          }, {
            suffixIcon: _withCtx(function () {
              return [_createVNode(_unref(SearchIcon), {
                onClick: _cache[27] || (_cache[27] = function () {
                  current.value = 1;
                  getGroupAddListFun();
                }),
                style: {
                  "cursor": "pointer"
                }
              })];
            }),
            _: 1
          }, 8, ["modelValue"])]), _createVNode(_component_t_table, {
            "row-key": "id",
            data: groupAddList.value,
            columns: [{
              colKey: 'groupName',
              title: '群组名称',
              ellipsis: true
            }, {
              colKey: 'groupOwnerName',
              title: '群管理员',
              ellipsis: true
            }, {
              colKey: 'auditTime',
              title: '加入时间',
              ellipsis: true
            }, {
              colKey: 'btn',
              title: '操作',
              width: 120,
              fixed: 'right'
            }],
            bordered: "",
            size: "small"
          }, {
            btn: _withCtx(function (_ref2) {
              var row = _ref2.row;
              return [_createVNode(_component_t_button, {
                variant: "text",
                theme: "primary",
                onClick: function onClick($event) {
                  return delgroup(row);
                }
              }, {
                default: _withCtx(function () {
                  return _cache[55] || (_cache[55] = [_createTextVNode("退出群组")]);
                }),
                _: 2
              }, 1032, ["onClick"])];
            }),
            empty: _withCtx(function () {
              return [_createElementVNode("img", {
                src: require('@/assets/empty.svg')
              }, null, 8, _hoisted_39)];
            }),
            _: 1
          }, 8, ["data"]), _createVNode(_component_t_pagination, {
            style: {
              "padding-top": "10px"
            },
            modelValue: current.value,
            "onUpdate:modelValue": _cache[30] || (_cache[30] = function ($event) {
              return current.value = $event;
            }),
            pageSize: 10,
            total: total.value,
            showPageSize: false,
            onChange: _cache[31] || (_cache[31] = function (pageInfo) {
              current.value = pageInfo.current;
              getGroupAddListFun();
            })
          }, null, 8, ["modelValue", "total"])];
        }),
        _: 1
      }, 8, ["header", "visible"]), _createVNode(AvatarDialog, {
        avatarVisible: avatarVisible.value,
        "onUpdate:avatarVisible": _cache[33] || (_cache[33] = function ($event) {
          return avatarVisible.value = $event;
        }),
        onUpdate: updateAvatar
      }, null, 8, ["avatarVisible"])]);
    };
  }
};