import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "section"
};
var _hoisted_2 = {
  class: "label"
};
import { ref } from 'vue';
import TeamTeacher from './TeamTeacher';
export default {
  __name: 'Team',
  props: {
    dataObj: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var editor = ref([]);
    var approval = ref([]);
    var original = ref([]);
    var teachers = ref([]);
    var initTeacherInfo = function initTeacherInfo(info) {
      if (info) {
        //责任编辑
        editor.value = [{
          teacher: info.editor,
          teacherTitle: info.editorTitle,
          teacherSchool: info.editorSchool,
          cover: info.editorCover
        }];
        //审核人
        approval.value = info.approvalUser ? [{
          teacher: info.approvalUser,
          teacherTitle: info.approvalUserTitle,
          teacherSchool: info.approvalUserSchool,
          cover: info.approvalUserCover
        }] : [];
        //原创
        original.value = [{
          teacher: info.originalUser,
          teacherTitle: info.originalUserTitle,
          teacherSchool: info.originalUserSchool,
          cover: info.originalUserCover
        }];
        //教师列表
        teachers.value = info.teachers;
      }
    };
    initTeacherInfo(props.dataObj.team);
    return function (_ctx, _cache) {
      var _component_t_col = _resolveComponent("t-col");
      var _component_t_row = _resolveComponent("t-row");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_t_row, null, {
        default: _withCtx(function () {
          return [editor.value.length > 0 ? (_openBlock(), _createBlock(_component_t_col, {
            key: 0,
            span: 12
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.dataObj.ascription === 2 ? '创编人' : '责任编辑'), 1), _createVNode(_unref(TeamTeacher), {
                "teacher-list": editor.value
              }, null, 8, ["teacher-list"])];
            }),
            _: 1
          })) : _createCommentVNode("", true), approval.value.length > 0 ? (_openBlock(), _createBlock(_component_t_col, {
            key: 1,
            span: 12
          }, {
            default: _withCtx(function () {
              return [_cache[0] || (_cache[0] = _createElementVNode("div", {
                class: "label"
              }, "审核人", -1)), _createVNode(_unref(TeamTeacher), {
                "teacher-list": approval.value
              }, null, 8, ["teacher-list"])];
            }),
            _: 1
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _createVNode(_component_t_row, null, {
        default: _withCtx(function () {
          return [original.value.length > 0 ? (_openBlock(), _createBlock(_component_t_col, {
            key: 0,
            span: 24
          }, {
            default: _withCtx(function () {
              return [_cache[1] || (_cache[1] = _createElementVNode("div", {
                class: "label"
              }, "原创人", -1)), _createVNode(_unref(TeamTeacher), {
                "teacher-list": original.value
              }, null, 8, ["teacher-list"])];
            }),
            _: 1
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _createVNode(_component_t_row, null, {
        default: _withCtx(function () {
          return [teachers.value.length > 0 ? (_openBlock(), _createBlock(_component_t_col, {
            key: 0,
            span: 12
          }, {
            default: _withCtx(function () {
              return [_cache[2] || (_cache[2] = _createElementVNode("div", {
                class: "label"
              }, "部分资源来自以下教师", -1)), _createVNode(_unref(TeamTeacher), {
                "teacher-list": teachers.value
              }, null, 8, ["teacher-list"])];
            }),
            _: 1
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      })]);
    };
  }
};