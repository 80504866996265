/* 工具 */
import { reactive, watch, onMounted, onUnmounted } from 'vue'

export default function () {
  /* 变量 */
  const isFirefox = () => !!window.navigator.userAgent.match(/firefox/i)
  const toFixed = (num, m = 2) => Math.round(Math.pow(10, m) * num) / Math.pow(10, m)
  const mousewheelEventName = isFirefox() ? 'DOMMouseScroll' : 'mousewheel'
  const transform = reactive({
    scale: 1,
    enableTransition: false
  })
  const style = reactive({})

  /* 监听 */
  watch(transform, (transform) => {
    const { scale, enableTransition } = transform
    style.transform = `scale(${scale})`
    style.transition = enableTransition ? 'transform .3s' : ''
    style.transformOrigin = `50% ${scale === 1 ? '50%' : '0%'}`
    style.height = `calc(${toFixed(scale === 1 ? 100 : (100 / parseInt((scale * 100), 10) * 100), 4)}% - 46px)`
    console.log(`页面当前缩放比例：${parseInt((scale * 100), 10)} %`)
  })

  /* 方法 */
  // 页面放大缩小
  const handleActions = (action, options = {}) => {
    const { zoomRate, enableTransition } = {
      zoomRate: 0.2,
      enableTransition: true,
      ...options
    }
    switch (action) {
      case 'zoomOut':
        if (transform.scale > 0.2) {
          transform.scale = toFixed(transform.scale - zoomRate, 4)
        }
        break
      case 'zoomIn':
        if (transform.scale < 5) {
          transform.scale = toFixed(transform.scale + zoomRate, 4)
        }
        break
    }
    transform.enableTransition = enableTransition
  }
  // 滚轮事件
  const mouseWheelHandler = (event) => {
    // CTRL + 鼠标滚轮
    if (event.ctrlKey) {
      event.preventDefault()
      const delta = event.wheelDelta ? event.wheelDelta : -event.detail
      const action = delta > 0 ? 'zoomIn' : 'zoomOut'
      handleActions(action, {
        zoomRate: 0.015,
        enableTransition: true
      })
    }
  }

  onMounted(() => {
    // 浏览器尺寸改变时候，浮动工具的定位
    window.addEventListener(mousewheelEventName, mouseWheelHandler, { passive: false })
  })

  onUnmounted(() => {
    window.removeEventListener(mousewheelEventName, mouseWheelHandler)
  })

  return style
}