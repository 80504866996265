import _objectSpread from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { reactive } from "vue";
import useOptions from "@/hooks/useOptions";

/* 变量 */

export default {
  __name: 'setPracticeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 习练数据
    practiceData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:visible", "sure"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var _useOptions = useOptions(),
      getOptions = _useOptions.getOptions;
    var emit = __emit;
    var props = __props;
    var state = reactive({
      lessionType: null,
      period: {
        code: null,
        displayName: ""
      },
      subject: {
        code: null,
        displayName: ""
      },
      oneClass: {
        code: null,
        displayName: ""
      },
      twoClass: {
        code: null,
        displayName: ""
      },
      periodList: [],
      subjectList: [],
      oneClassList: [{
        displayName: "身心健康",
        code: "physical_health"
      }, {
        displayName: "艺术素养",
        code: "art_acc"
      }, {
        displayName: "人文素养",
        code: "humanity_acc"
      }, {
        displayName: "科学素养",
        code: "science_acc"
      }],
      twoClassList: []
    });

    /* 事件 */
    // 获取下拉
    var getSelectList = function getSelectList(isOpen, dict) {
      var _state$oneClass;
      if (!isOpen) return;
      var listKey = "".concat(dict, "List");
      var apiDict = dict === "twoClass" ? (_state$oneClass = state.oneClass) === null || _state$oneClass === void 0 ? void 0 : _state$oneClass.code : dict;
      if (!apiDict) return;
      getOptions(apiDict).then(function (list) {
        state[listKey] = list;
      });
    };

    // 切换国家课程
    var lessionTypeChange = function lessionTypeChange(type) {
      if (type === 0) {
        state.oneClass = {
          code: null,
          displayName: ""
        };
        state.twoClass = {
          code: null,
          displayName: ""
        };
        state.twoClassList = [];
      } else {
        state.period = {
          code: null,
          displayName: ""
        };
        state.subject = {
          code: null,
          displayName: ""
        };
      }
    };

    // 选择一类
    var oneClassChange = function oneClassChange() {
      state.twoClass = {
        code: null,
        displayName: ""
      };
      state.twoClassList = [];
    };

    // 点击确定
    var handleSure = function handleSure() {
      var _state$period, _state$period2, _state$subject, _state$subject2, _state$oneClass2, _state$oneClass3, _state$twoClass, _state$twoClass2;
      var res = JSON.parse(JSON.stringify(props.practiceData));
      res.paperIndexCatalog = _objectSpread(_objectSpread({}, res.paperIndexCatalog), {}, {
        curriculumCategory: state.lessionType,
        periodDictItemName: ((_state$period = state.period) === null || _state$period === void 0 ? void 0 : _state$period.displayName) || "",
        periodDictItemCode: ((_state$period2 = state.period) === null || _state$period2 === void 0 ? void 0 : _state$period2.code) || null,
        subjectDictItemName: ((_state$subject = state.subject) === null || _state$subject === void 0 ? void 0 : _state$subject.displayName) || "",
        subjectDictItemCode: ((_state$subject2 = state.subject) === null || _state$subject2 === void 0 ? void 0 : _state$subject2.code) || null,
        firstDirDictItemName: ((_state$oneClass2 = state.oneClass) === null || _state$oneClass2 === void 0 ? void 0 : _state$oneClass2.displayName) || "",
        firstDirDictItemCode: ((_state$oneClass3 = state.oneClass) === null || _state$oneClass3 === void 0 ? void 0 : _state$oneClass3.code) || null,
        secondDirDictItemName: ((_state$twoClass = state.twoClass) === null || _state$twoClass === void 0 ? void 0 : _state$twoClass.displayName) || "",
        secondDirDictItemCode: ((_state$twoClass2 = state.twoClass) === null || _state$twoClass2 === void 0 ? void 0 : _state$twoClass2.code) || null
      });
      emit("sure", res);
      handleClose();
    };

    // 打开弹窗
    var handleOpen = function handleOpen() {
      var _props$practiceData;
      var catalog = (_props$practiceData = props.practiceData) === null || _props$practiceData === void 0 ? void 0 : _props$practiceData.paperIndexCatalog;
      if (catalog) {
        state.lessionType = catalog.curriculumCategory;
        state.period = {
          code: catalog.periodDictItemCode,
          displayName: catalog.periodDictItemName
        };
        state.periodList = catalog.periodDictItemCode ? [state.period] : [];
        state.subject = {
          code: catalog.subjectDictItemCode,
          displayName: catalog.subjectDictItemName
        };
        state.subjectList = catalog.subjectDictItemCode ? [state.subject] : [];
        state.oneClass = {
          code: catalog.firstDirDictItemCode,
          displayName: catalog.firstDirDictItemName
        };
        state.twoClass = {
          code: catalog.secondDirDictItemCode,
          displayName: catalog.secondDirDictItemName
        };
        state.twoClassList = catalog.secondDirDictItemCode ? [state.twoClass] : [];
      }
    };

    // 关闭弹窗
    var handleClose = function handleClose() {
      state.lessionType = null;
      state.period = {
        code: null,
        displayName: ""
      };
      state.subject = {
        code: null,
        displayName: ""
      };
      state.oneClass = {
        code: null,
        displayName: ""
      };
      state.twoClass = {
        code: null,
        displayName: ""
      };
      state.twoClassList = [];
      emit("update:visible", false);
    };
    return function (_ctx, _cache) {
      var _component_t_radio = _resolveComponent("t-radio");
      var _component_t_radio_group = _resolveComponent("t-radio-group");
      var _component_t_form_item = _resolveComponent("t-form-item");
      var _component_t_select = _resolveComponent("t-select");
      var _component_t_form = _resolveComponent("t-form");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        class: "set-practice-dialog",
        visible: props.visible,
        header: "习练设置",
        width: "410px",
        onOpened: handleOpen,
        onClose: handleClose,
        onConfirm: handleSure
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_form, {
            "label-align": "left",
            colon: ""
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_t_form_item, {
                label: "课程大类",
                "label-width": "80px",
                name: "lessionType"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_radio_group, {
                    modelValue: state.lessionType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                      return state.lessionType = $event;
                    }),
                    "allow-uncheck": "",
                    onChange: lessionTypeChange
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_t_radio, {
                        value: 0
                      }, {
                        default: _withCtx(function () {
                          return _cache[8] || (_cache[8] = [_createTextVNode("国家课程")]);
                        }),
                        _: 1
                      }), _createVNode(_component_t_radio, {
                        value: 1
                      }, {
                        default: _withCtx(function () {
                          return _cache[9] || (_cache[9] = [_createTextVNode("特色课程")]);
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }, 8, ["modelValue"])];
                }),
                _: 1
              }), state.lessionType === 0 ? (_openBlock(), _createBlock(_component_t_form_item, {
                key: 0,
                label: "学段",
                "label-width": "50px",
                name: "period"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_select, {
                    modelValue: state.period,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                      return state.period = $event;
                    }),
                    options: state.periodList,
                    keys: {
                      value: 'code',
                      label: 'displayName'
                    },
                    placeholder: "请选择内容",
                    "value-type": "object",
                    clearable: "",
                    onPopupVisibleChange: _cache[2] || (_cache[2] = function ($event) {
                      return getSelectList($event, 'period');
                    })
                  }, null, 8, ["modelValue", "options"])];
                }),
                _: 1
              })) : _createCommentVNode("", true), state.lessionType === 0 ? (_openBlock(), _createBlock(_component_t_form_item, {
                key: 1,
                label: "学科",
                "label-width": "50px",
                name: "subject"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_select, {
                    modelValue: state.subject,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
                      return state.subject = $event;
                    }),
                    options: state.subjectList,
                    keys: {
                      value: 'code',
                      label: 'displayName'
                    },
                    placeholder: "请选择内容",
                    "value-type": "object",
                    clearable: "",
                    onPopupVisibleChange: _cache[4] || (_cache[4] = function ($event) {
                      return getSelectList($event, 'subject');
                    })
                  }, null, 8, ["modelValue", "options"])];
                }),
                _: 1
              })) : _createCommentVNode("", true), state.lessionType === 1 ? (_openBlock(), _createBlock(_component_t_form_item, {
                key: 2,
                label: "一级分类",
                "label-width": "80px",
                name: "oneClass"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_select, {
                    modelValue: state.oneClass,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
                      return state.oneClass = $event;
                    }),
                    options: state.oneClassList,
                    keys: {
                      value: 'code',
                      label: 'displayName'
                    },
                    placeholder: "请选择内容",
                    "value-type": "object",
                    clearable: "",
                    onChange: oneClassChange
                  }, null, 8, ["modelValue", "options"])];
                }),
                _: 1
              })) : _createCommentVNode("", true), state.lessionType === 1 ? (_openBlock(), _createBlock(_component_t_form_item, {
                key: 3,
                label: "二级分类",
                "label-width": "80px",
                name: "twoClass"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_select, {
                    modelValue: state.twoClass,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
                      return state.twoClass = $event;
                    }),
                    options: state.twoClassList,
                    keys: {
                      value: 'code',
                      label: 'displayName'
                    },
                    placeholder: "请选择内容",
                    "value-type": "object",
                    clearable: "",
                    onPopupVisibleChange: _cache[7] || (_cache[7] = function ($event) {
                      return getSelectList($event, 'twoClass');
                    })
                  }, null, 8, ["modelValue", "options"])];
                }),
                _: 1
              })) : _createCommentVNode("", true)];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};