import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import practicePaper from "../practicePaper";

// 去掉左侧导航

export default {
  __name: 'index',
  setup: function setup(__props) {
    var _window$$wujie;
    /* 组件 */
    (_window$$wujie = window.$wujie) === null || _window$$wujie === void 0 || _window$$wujie.props.toggleLeftBar(false);
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(practicePaper), {
        isPreview: ""
      });
    };
  }
};