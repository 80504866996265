import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
import _imports_0 from '../icons/gaugeCard.png';
var _hoisted_1 = {
  class: "gauge-card card media-card"
};
var _hoisted_2 = {
  class: "media-card-title"
};
var _hoisted_3 = {
  class: "card-title"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  class: "gauge-table"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "row-col row-left row-item"
};
var _hoisted_8 = {
  class: "row-col row-right row-item"
};
var _hoisted_9 = {
  key: 1,
  class: "row-col row-empty row-item"
};
import { computed } from "vue";
import cardmap from "@/views/sourcePreview/courseDetail/const/cardmap";
/* 变量 */

export default {
  __name: 'gaugeCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var starValues = ["oneStart", "twoStart", "threeStart", "fourStart", "fiveStart"];

    /* 方法 */
    var letter2num = function letter2num(starLevel) {
      var num = 0;
      starValues.forEach(function (item, index) {
        if (starLevel === item) {
          num = index + 1;
        }
      });
      return num;
    };
    var formatTables = function formatTables() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return arr.map(function (item) {
        var stars = [];
        for (var key in item) {
          if (starValues.includes(key)) {
            stars.push({
              star: letter2num(key),
              description: item[key] || "暂无评价标准"
            });
            item.stars = stars.sort(function (a, b) {
              return a.star - b.star;
            });
          }
        }
        return item;
      });
    };
    /* 计算属性 */
    var gaugeSortName = computed(function () {
      return "".concat(__props.cardData.sort, " - \u91CF\u8868");
    });
    var tabData = computed(function () {
      var tData = JSON.parse(__props.cardData.data).content.tableData || [];
      return formatTables(tData);
    });
    var desc = computed(function () {
      var _props$cardData;
      return ((_props$cardData = __props.cardData) === null || _props$cardData === void 0 || (_props$cardData = _props$cardData.parent) === null || _props$cardData === void 0 ? void 0 : _props$cardData.gaugeExtendAttr) && JSON.parse(__props.cardData.parent.gaugeExtendAttr).desc;
    });
    return function (_ctx, _cache) {
      var _component_t_icon = _resolveComponent("t-icon");
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_3, _toDisplayString(gaugeSortName.value), 1)]), desc.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "gauge-desc card-content",
        innerHTML: desc.value
      }, null, 8, _hoisted_4)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "row head"
      }, [_createElementVNode("span", {
        class: "row-col row-left"
      }, "评价维度"), _createElementVNode("span", {
        class: "row-col row-right"
      }, "评价")], -1)), tabData.value && tabData.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabData.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "row"
        }, [_createElementVNode("span", _hoisted_7, _toDisplayString(item.evaluationConten), 1), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.stars, function (s) {
          return _openBlock(), _createElementBlock("div", {
            key: s.star,
            class: "stars"
          }, [_createVNode(_component_t_tooltip, {
            content: s.description,
            placement: "top",
            theme: "light"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_t_icon, {
                name: "star-filled",
                class: "icon-star",
                title: "".concat(s.star, "\u661F")
              }, null, 8, ["title"])];
            }),
            _: 2
          }, 1032, ["content"])]);
        }), 128))])]);
      }), 128))])) : (_openBlock(), _createElementBlock("span", _hoisted_9, "量表数据为空"))])]);
    };
  }
};