import "core-js/modules/es.number.constructor.js";
import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/paper/edit.svg';
var _hoisted_1 = {
  class: "edit-text"
};
var _hoisted_2 = ["contenteditable"];
import { ref, computed, onMounted } from "vue";

/* 变量 */

export default {
  __name: 'editText',
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    maxLength: {
      type: String,
      default: "50"
    },
    fontColor: {
      type: String,
      default: "#232A35"
    },
    fontSize: {
      type: String,
      default: "24px"
    },
    lineHeight: {
      type: String,
      default: "36px"
    },
    backGroundColor: {
      type: String,
      default: "#F2F3FF"
    }
  },
  emits: ["update:value", "blur"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    _useCssVars(function (_ctx) {
      return {
        "5ed35338": bgc.value
      };
    });

    /* 工具 */
    var emit = __emit;
    var props = __props;
    var input = ref();

    /* 计算属性 */
    var bgc = computed(function () {
      return props.backGroundColor;
    });

    /* 事件 */
    // 聚焦到最后
    var focusToEnd = function focusToEnd() {
      document.execCommand("selectAll", false, null);
      document.getSelection().collapseToEnd();
    };

    // 输入事件
    var handleInput = function handleInput(e) {
      var maxLength = Number(props.maxLength);
      var innerText = e.target.innerText;
      if (innerText.length >= maxLength) {
        e.target.innerText = innerText.substring(0, maxLength);
        focusToEnd();
      }
    };

    // 聚焦事件
    var handleFocus = function handleFocus(isFocusToEnd) {
      input.value.classList.add("focus");
      input.value.focus();
      isFocusToEnd && focusToEnd();
    };

    // 失焦事件
    var handleBlur = function handleBlur(e) {
      input.value.classList.remove("focus");
      var innerText = e.target.innerText;
      if (innerText === props.value) return;
      if (innerText.length === 0) {
        e.target.innerText = props.value;
      } else {
        emit("update:value", innerText);
        emit("blur", innerText);
      }
    };

    /* 钩子 */
    onMounted(function () {});
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        ref_key: "input",
        ref: input,
        class: "ipt",
        contenteditable: !props.isPreview,
        style: _normalizeStyle({
          fontSize: props.fontSize,
          lineHeight: props.lineHeight,
          color: props.fontColor
        }),
        onFocus: _cache[0] || (_cache[0] = function ($event) {
          return handleFocus(false);
        }),
        onBlur: handleBlur,
        onInput: handleInput
      }, _toDisplayString(props.value), 45, _hoisted_2), !props.isPreview ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_0,
        alt: "",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return handleFocus(true);
        })
      })) : _createCommentVNode("", true)]);
    };
  }
};