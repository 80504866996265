import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
export default {
  __name: 'Avatar',
  props: {
    avatarUrl: {
      type: String,
      default: ""
    },
    size: {
      type: [Number, String],
      default: 36
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var sizeStyle = computed(function () {
      var size = props.size;
      var s = "".concat(size);
      s = "".concat(s.replace("px", ""), "px");
      return {
        width: s,
        height: s
      };
    });
    return function (_ctx, _cache) {
      var _component_svg_icon = _resolveComponent("svg-icon");
      var _component_t_image = _resolveComponent("t-image");
      return _openBlock(), _createBlock(_component_t_image, {
        class: "zxhj-avatar",
        round: "",
        style: _normalizeStyle(sizeStyle.value),
        shape: "circle",
        fit: "cover",
        src: props.avatarUrl
      }, {
        loading: _withCtx(function () {
          return [_createVNode(_component_svg_icon, {
            name: "avatar",
            size: __props.size
          }, null, 8, ["size"])];
        }),
        error: _withCtx(function () {
          return [_createVNode(_component_svg_icon, {
            name: "avatar",
            size: __props.size
          }, null, 8, ["size"])];
        }),
        _: 1
      }, 8, ["style", "src"]);
    };
  }
};