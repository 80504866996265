import "core-js/modules/es.number.constructor.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "image-preview"
};
var _hoisted_2 = {
  class: "img-box"
};
var _hoisted_3 = ["src"];

/* 变量 */

export default {
  __name: 'index',
  props: {
    visable: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:visable'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */

    var emits = __emit;
    var hide = function hide() {
      emits('update:visable', false);
    };
    return function (_ctx, _cache) {
      return __props.visable ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: __props.list[__props.index]
      }, null, 8, _hoisted_3)]), _createElementVNode("div", {
        class: "mask",
        onClick: hide
      })])) : _createCommentVNode("", true);
    };
  }
};