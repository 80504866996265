import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "paper"
};
var _hoisted_2 = {
  class: "paper-header"
};
var _hoisted_3 = {
  class: "nav-bg"
};
var _hoisted_4 = {
  class: "left"
};
var _hoisted_5 = {
  class: "right"
};
var _hoisted_6 = {
  class: "nav-item"
};
var _hoisted_7 = ["src"];
var _hoisted_8 = {
  class: "paper-box"
};
var _hoisted_9 = {
  class: "left-directory"
};
var _hoisted_10 = {
  class: "directory-title"
};
var _hoisted_11 = {
  class: "nav-box"
};
var _hoisted_12 = ["data-guuid"];
var _hoisted_13 = ["onClick"];
var _hoisted_14 = {
  class: "list-group"
};
var _hoisted_15 = ["data-iuuid", "data-itemid", "onClick"];
var _hoisted_16 = {
  class: "right-paper"
};
var _hoisted_17 = {
  class: "paper-secret-line"
};
var _hoisted_18 = ["src"];
var _hoisted_19 = {
  class: "paper-edit-stage"
};
var _hoisted_20 = {
  class: "paper-title"
};
var _hoisted_21 = ["id", "onMousemove"];
var _hoisted_22 = {
  class: "exercisesGroup-title"
};
var _hoisted_23 = ["id", "onClick", "onMousemove"];
var _hoisted_24 = {
  class: "item-wrap"
};
var _hoisted_25 = {
  class: "center"
};
var _hoisted_26 = {
  class: "practice-type"
};
var _hoisted_27 = {
  class: "no-title"
};
var _hoisted_28 = ["innerHTML"];
var _hoisted_29 = {
  class: "choice-order"
};
var _hoisted_30 = ["innerHTML"];
var _hoisted_31 = ["innerHTML"];
var _hoisted_32 = {
  key: 0
};
var _hoisted_33 = {
  class: "choice-option-item"
};
var _hoisted_34 = {
  class: "choice-order"
};
var _hoisted_35 = ["innerHTML"];
import { ref } from "vue";
import { Icon } from 'tdesign-icons-vue-next';
import { numberToChinese, num2Letter, formatChoiceOption } from '@/util/util';
import { formatPaperInfo } from '@/util/paper';
import { setScrollIntoView } from '@/util/util';
import { useRoute } from "vue-router";
import { getExerciseDetail } from "@/api/resources.js";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var route = useRoute();
    var currentPaper = ref({});
    var practiceType = ref({
      'SINGLE_CHOICE': '单选',
      'MULTIPLE_CHOICE': '多选',
      'COMPLETION': '填空',
      'ANSWER_QUESTIONS': '简答',
      'COMPREHENSIVE': '综合'
    });
    var exportImg = require("@/assets/course/export.svg");
    var paperSecretLine = require("@/assets/course/paper-secret-line.png");
    var groupUuidLeft = ref(-1); /*当前题组Uuid-左侧*/
    var exerciseUuidLeft = ref(-1); /*当前习题Uuid-左侧*/
    var groupUuid = ref(-1); /*当前题组Uuid*/
    var exerciseUuid = ref(-1); /*当前习题Uuid*/

    getExerciseDetail(route.query.id).then(function (res) {
      if (res.data.code === 0) {
        var obj = res.data.data;
        currentPaper.value = {
          paperId: obj.id,
          title: obj.title,
          navs: formatPaperInfo(obj.navDetails)
        };
        console.log(currentPaper.value, 99999);
      }
    });
    var currentExerciseGroup = function currentExerciseGroup(groupUuidNow, exerciseUuidNow) {
      var scrollUuid = exerciseUuidNow ? exerciseUuidNow + ('-group-' + groupUuidNow) : 'group-' + groupUuidNow;
      setScrollIntoView(scrollUuid);
    };
    var hoverEvent = function hoverEvent() {
      var groupUuidNow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -1;
      var exerciseUuidNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
      groupUuid.value = groupUuidNow;
      exerciseUuid.value = exerciseUuidNow;
    };
    var currentExercise = function currentExercise() {
      var groupUuidNow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -1;
      var exerciseUuidNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
      groupUuidLeft.value = groupUuidNow;
      exerciseUuidLeft.value = exerciseUuidNow;
    };
    return function (_ctx, _cache) {
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(currentPaper.value.title), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
        src: _unref(exportImg)
      }, null, 8, _hoisted_7), _cache[2] || (_cache[2] = _createTextVNode(" 导出 "))])])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_cache[4] || (_cache[4] = _createTextVNode(" 习练导航 ")), _createVNode(_component_t_tooltip, {
        placement: "top-left"
      }, {
        content: _withCtx(function () {
          return _cache[3] || (_cache[3] = [_createTextVNode("点击题目或题组可迅速定位到当前题目或题组位置")]);
        }),
        default: _withCtx(function () {
          return [_createVNode(_unref(Icon), {
            name: "help-circle",
            size: "20px"
          })];
        }),
        _: 1
      })]), _createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentPaper.value.navs, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          "data-guuid": item.uuid,
          class: "list"
        }, [_createElementVNode("div", {
          class: "title",
          onClick: function onClick($event) {
            return currentExerciseGroup(item.uuid);
          }
        }, [_createElementVNode("p", null, _toDisplayString(_unref(numberToChinese)(index + 1)) + "、" + _toDisplayString(item.navName), 1)], 8, _hoisted_13), _createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.item, function (item2, index2) {
          return _openBlock(), _createElementBlock("div", {
            key: index + '-' + index2,
            class: _normalizeClass(["list-group-item", {
              active: item.uuid === groupUuidLeft.value && item2.uuid === exerciseUuidLeft.value
            }]),
            "data-iuuid": item2.uuid,
            "data-itemid": item2.itemId,
            onClick: function onClick($event) {
              currentExercise(item.uuid, item2.uuid);
              currentExerciseGroup(item.uuid, item2.uuid);
            }
          }, _toDisplayString(index2 + 1), 11, _hoisted_15);
        }), 128))])], 8, _hoisted_12);
      }), 128))])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("img", {
        src: _unref(paperSecretLine),
        alt: "密封线"
      }, null, 8, _hoisted_18)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, _toDisplayString(currentPaper.value.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentPaper.value.navs, function (itemExercises, indexExercises) {
        return _openBlock(), _createElementBlock("div", {
          id: 'group-' + itemExercises.uuid,
          key: itemExercises.uuid,
          class: _normalizeClass(["exercisesGroup", {
            active: itemExercises.uuid === groupUuid.value && exerciseUuid.value === -1
          }]),
          onMousemove: function onMousemove($event) {
            return hoverEvent(itemExercises.uuid);
          },
          onMouseleave: _cache[1] || (_cache[1] = function ($event) {
            return hoverEvent();
          })
        }, [_createElementVNode("div", _hoisted_22, [_createElementVNode("span", null, _toDisplayString(_unref(numberToChinese)(indexExercises + 1)) + "、" + _toDisplayString(itemExercises.navName), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemExercises.item, function (item, index) {
          return _openBlock(), _createElementBlock("div", {
            id: item.uuid + '-group-' + itemExercises.uuid,
            key: item.uuid + '-' + index,
            class: _normalizeClass(["subjectBox", [itemExercises.uuid === groupUuid.value && exerciseUuid.value === item.uuid && 'active', item.type.toLowerCase()]]),
            onClick: _withModifiers(function ($event) {
              return currentExercise(itemExercises.uuid, item.uuid);
            }, ["stop"]),
            onMousemove: _withModifiers(function ($event) {
              return hoverEvent(itemExercises.uuid, item.uuid);
            }, ["stop"]),
            onMouseleave: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
              return hoverEvent();
            }, ["stop"]))
          }, [_createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createElementVNode("div", {
            ref_for: true,
            ref: 'title' + index,
            class: "title"
          }, [_createElementVNode("div", _hoisted_26, _toDisplayString(practiceType.value[item.type]), 1), _createElementVNode("span", _hoisted_27, _toDisplayString(index + 1) + ".", 1), _createElementVNode("div", {
            class: "sub-title",
            innerHTML: item.title
          }, null, 8, _hoisted_28)], 512), item.type === 'SINGLE_CHOICE' || item.type === 'MULTIPLE_CHOICE' ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(item.answer, function (optionItem, index2) {
            return _openBlock(), _createElementBlock("div", {
              class: "choice-option-item",
              key: optionItem.uuid + index2
            }, [_createElementVNode("label", _hoisted_29, _toDisplayString(_unref(num2Letter)(index2 + 1)), 1), _createElementVNode("p", {
              class: "choice-text",
              innerHTML: _unref(formatChoiceOption)(optionItem.title)
            }, null, 8, _hoisted_30)]);
          }), 128)) : _createCommentVNode("", true), item.type === 'COMPREHENSIVE' ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(item.subExerciseList, function (item2, index2) {
            return _openBlock(), _createElementBlock("div", {
              key: index2
            }, [_createElementVNode("div", null, [_createElementVNode("b", null, "小题" + _toDisplayString(index2 + 1) + ": ", 1), _createElementVNode("span", {
              ref_for: true,
              ref: 'subTitle' + index + index2,
              innerHTML: item2.title
            }, null, 8, _hoisted_31)]), item2.type === 'SINGLE_CHOICE' || item2.type === 'MULTIPLE_CHOICE' ? (_openBlock(), _createElementBlock("div", _hoisted_32, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.answer, function (item3, index3) {
              return _openBlock(), _createElementBlock("div", {
                key: item3.uuid + index3,
                ref_for: true,
                ref: 'subChoiceTitle' + index + index2
              }, [_createElementVNode("div", _hoisted_33, [_createElementVNode("label", _hoisted_34, _toDisplayString(_unref(num2Letter)(index3 + 1)), 1), _createElementVNode("p", {
                class: "choice-text",
                innerHTML: _unref(formatChoiceOption)(item3.title)
              }, null, 8, _hoisted_35)])]);
            }), 128))])) : _createCommentVNode("", true)]);
          }), 128)) : _createCommentVNode("", true)])])], 42, _hoisted_23);
        }), 128))], 42, _hoisted_21);
      }), 128))])])])]);
    };
  }
};