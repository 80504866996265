import _objectSpread from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "tree"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "tree-node-text"
};
var _hoisted_4 = {
  class: "tip"
};
var _hoisted_5 = {
  key: 1,
  style: {
    "opacity": "0.25",
    "text-align": "center"
  }
};
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Icon } from 'tdesign-icons-vue-next';
import lessonMenuItem from './lessonMenuItem';
import resourcePreview from '@/views/sourcePreview/resourcePreview';
import { MessagePlugin } from 'tdesign-vue-next';
/** 接口 */
import { getCourseVersion } from '@/api/resources.js';
/** 工具 */
import { getStore } from "@/util/store";
import { env_name } from '@/util/util.js';
export default {
  __name: 'LessonMenu',
  props: {
    dataObj: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var accountType = route.query.accountType;
    var props = __props;
    var accessInfo = getStore({
      name: "accessInfo"
    }); // 获取accessInfo
    var commonName = env_name ? "//".concat(env_name, "course.").concat(env_name.includes('edu') ? 'zhixuehuijiao' : 'huixuecloud', ".cn") : '//edu-course.zhixuehuijiao.cn';
    var commonName2 = env_name ? "//".concat(env_name, "main.").concat(env_name.includes('edu') ? 'zhixuehuijiao' : 'huixuecloud', ".cn") : '//web.zhixuehuijiao.cn';
    var typeCount = computed(function () {
      return function (arr, type) {
        var count = 0;
        arr.forEach(function (item) {
          if (item.itemType === type) {
            count++;
          }
        });
        return count;
      };
    });
    var canShow = Boolean(props.dataObj.isVipResource && props.dataObj.hasVipRole || !props.dataObj.isVipResource || accountType !== '2');
    var showList = ref([]);
    var handlerShow = function handlerShow(item, index) {
      if (canShow) {
        //如果是会员资源且是会员
        if (item.childrenEntry.length > 0) {
          if (showList.value.includes(index)) {
            showList.value.splice(showList.value.indexOf(index), 1);
          } else {
            showList.value.push(index);
          }
        } else {
          MessagePlugin.closeAll();
          MessagePlugin.warning('暂无内容');
        }
      } else {
        return;
      }
    };
    var visible = ref(false);
    var sourceData = ref(null);
    var preview = function preview(_ref) {
      var item = _ref.item,
        fullPermission = _ref.fullPermission;
      // itemType (1：单元；2：学程；3：资源；4：习练)
      var canOpen = (item === null || item === void 0 ? void 0 : item.isVipResource) && (item === null || item === void 0 ? void 0 : item.hasVipRole) || !(item !== null && item !== void 0 && item.isVipResource) || accountType !== '2';
      if (item.itemType == 2) {
        // window.open(`/courseDetail?id=${item.resourceRelationId}&source=${item.source || 2}&accountType=${accountType}`)
        getCourseVersion(item.resourceRelationId).then(function (res) {
          if (res.data.code === 0) {
            if (res.data.data === 2) {
              // 新学程预览
              window.open("".concat(commonName, "/preview?courseId=").concat(item.resourceRelationId, "&version=2"));
            } else {
              // 旧学程预览
              window.open("/courseDetail?id=".concat(item.resourceRelationId, "&source=").concat(item.source || 2, "&accountType=").concat(accessInfo.accountType));
            }
          }
        });
      } else if (item.itemType == 3) {
        if (canOpen) {
          var obj = _objectSpread(_objectSpread({}, item), {}, {
            id: item.resourceRelationId
          });
          sourceData.value = obj;
          visible.value = true;
        } else {
          return;
        }
      } else if (item.itemType == 4) {
        if (canOpen) {
          window.open("".concat(commonName2, "/inspirationCentre?inspirationCentre=%2FpreviewParper%3FresId%3D").concat(item.resourceRelationId)); // 新
        } else {
          return;
        }
      }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [__props.dataObj.entry && __props.dataObj.entry.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataObj.entry, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "tree-node"
        }, [_createElementVNode("div", {
          class: "tree-node-content",
          onClick: function onClick($event) {
            return handlerShow(item, index);
          }
        }, [_createElementVNode("div", _hoisted_3, _toDisplayString(index + 1) + "." + _toDisplayString(item.name), 1), _createElementVNode("span", _hoisted_4, "包含" + _toDisplayString(typeCount.value(item.childrenEntry, 2)) + " 个学程、" + _toDisplayString(typeCount.value(item.childrenEntry, 3)) + " 个素材、" + _toDisplayString(typeCount.value(item.childrenEntry, 4)) + " 个习练", 1), _unref(canShow) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["deal-box", {
            more: showList.value.includes(index) && item.childrenEntry.length > 0
          }])
        }, [_createVNode(_unref(Icon), {
          name: "caret-down-small",
          size: "32px"
        })], 2)) : _createCommentVNode("", true)], 8, _hoisted_2), item.childrenEntry.length > 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "children-tree",
          style: _normalizeStyle({
            height: showList.value.includes(index) ? item.childrenEntry.length * 50 - 10 + 'px' : 0
          })
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrenEntry, function (item2, index2) {
          return _openBlock(), _createBlock(_unref(lessonMenuItem), {
            key: index2,
            "is-restricted": __props.dataObj.isRestricted,
            ascription: __props.dataObj.ascription,
            info: item2,
            accountType: _unref(accountType),
            dataObj: __props.dataObj,
            class: "children-node",
            onPreview: preview
          }, null, 8, ["is-restricted", "ascription", "info", "accountType", "dataObj"]);
        }), 128))], 4)) : _createCommentVNode("", true)]);
      }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_5, "暂无目录")), visible.value ? (_openBlock(), _createBlock(_unref(resourcePreview), {
        key: 2,
        visible: visible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return visible.value = $event;
        }),
        sourceData: sourceData.value
      }, null, 8, ["visible", "sourceData"])) : _createCommentVNode("", true)]);
    };
  }
};