import "core-js/modules/es.function.name.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "cover-img"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  key: 0,
  class: "kind"
};
var _hoisted_4 = {
  key: 1,
  class: "subject"
};
var _hoisted_5 = {
  key: 2,
  class: "tutorialName"
};
var _hoisted_6 = {
  key: 3,
  class: "tutorialVersion"
};
var _hoisted_7 = {
  class: "recommend-info"
};
var _hoisted_8 = ["title"];
var _hoisted_9 = ["src"];
var _hoisted_10 = {
  key: 0,
  class: "school"
};
var _hoisted_11 = {
  class: "data"
};
var _hoisted_12 = {
  key: 0
};
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  __name: 'lessonRecommendItem',
  props: {
    info: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var route = useRoute();
    var accountType = route.query.accountType;
    var serverLogoDgefault = require('@/assets/course/big.svg');
    var type = ref({
      1: '校级课程',
      2: '区域课程',
      3: '平台课程'
    });
    var typeName = ref({
      nation: '国家课程',
      specialty: '特色课程'
    });
    var intoLessonView = function intoLessonView() {
      // const { id: lessonId, module } = props.info
      // this.$router.push(`/curriculaPreview?lessonId=${lessonId}&module=${module}`)
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "recommend-item",
        onClick: intoLessonView
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        src: __props.info.cover || _unref(serverLogoDgefault)
      }, null, 8, _hoisted_2), typeName.value[__props.info.resourceTypeDictItemCode] ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(typeName.value[__props.info.resourceTypeDictItemCode]), 1)) : _createCommentVNode("", true), __props.info.subjectDictItemName && __props.info.resourceTypeDictItemCode === 'nation' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.info.subjectDictItemName), 1)) : _createCommentVNode("", true), __props.info.tutorialName && __props.info.resourceTypeDictItemCode === 'nation' ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.info.tutorialName), 1)) : _createCommentVNode("", true), __props.info.tutorialVersionDictItemName && __props.info.resourceTypeDictItemCode === 'nation' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(__props.info.tutorialVersionDictItemName), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        class: "title",
        title: __props.info.name
      }, [__props.info.isVipResource && _unref(accountType) === '2' ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: require('@/assets/vipIcon.svg'),
        style: {
          "margin-right": "8px"
        }
      }, null, 8, _hoisted_9)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(__props.info.name), 1)], 8, _hoisted_8), __props.info.schoolName ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(__props.info.schoolName), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, [_createTextVNode(_toDisplayString(__props.info.citeCount) + "次引用  |  " + _toDisplayString(__props.info.collCount) + "次收藏  |  ", 1), __props.info.uniqueCode ? (_openBlock(), _createElementBlock("span", _hoisted_12, "ID " + _toDisplayString(__props.info.uniqueCode), 1)) : _createCommentVNode("", true)])])]);
    };
  }
};