import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "box"
};
var _hoisted_2 = {
  class: "load-icon"
};
var _hoisted_3 = {
  key: 0,
  class: "time-content"
};
var _hoisted_4 = {
  class: "red"
};
var _hoisted_5 = {
  key: 1,
  class: "time-content"
};
var _hoisted_6 = {
  key: 2,
  class: "time-content"
};
import { ref } from "vue";
import { sendXkwCallback, syn_status } from '@/api/xkw';
import { useRoute } from "vue-router";
import { MessagePlugin } from 'tdesign-vue-next';
import { LoadingIcon } from "tdesign-icons-vue-next";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var route = useRoute();
    var uuid = ref('');
    var loadingState = ref(true);
    var time = ref(0);
    var msg = ref('');
    var syntimmer = null;
    var closePage = function closePage() {
      syntimmer && clearInterval(syntimmer);
      window.close();
    };
    var get_syn_status = function get_syn_status() {
      var openid = route.query.openid;
      var paperid = route.query.paperid;
      syn_status(openid, paperid, uuid.value).then(function (res) {
        //异步组卷成功，结束轮询，发送成功请求
        var data = res.data.data;
        if (data.state === 0) {
          //清空uuid
          sessionStorage.removeItem('paperid_' + paperid);
          //移除轮询
          syntimmer && clearInterval(syntimmer);
          msg.value = data.msg;
          MessagePlugin.success(data.msg);
          finishSyn();
        }
      });
    };
    var startSyn = function startSyn() {
      //启动轮询
      syntimmer = setInterval(function () {
        get_syn_status();
      }, 3000);
    };
    var finishSyn = function finishSyn() {
      var paperid = route.query.paperid;
      time.value = 5;
      loadingState.value = false;

      //记住最近一次的组卷id
      localStorage.setItem('paperid', paperid);
      setInterval(function () {
        time.value--;
        if (time.value <= 0) {
          closePage();
        }
      }, 1000);
    };
    var sendNotify = function sendNotify() {
      var openid = route.query.openid;
      var paperid = route.query.paperid;
      sendXkwCallback(openid, paperid, uuid.value).then(function (res) {
        startSyn();
      }).catch(function (err) {
        time.value = -999;
        loadingState.value = false;
        MessagePlugin.error('组卷似乎遇到了问题，请重试或联系管理员');
      });
    };
    var paperid = route.query.paperid;
    if (!paperid) {
      loadingState.value = false;
      time.value = -999;
      msg.value = '未查询到paperid参数';
    } else {
      uuid.value = sessionStorage.getItem('paperid_' + paperid);
      if (!uuid.value) {
        uuid.value = Math.floor(Math.random() * 10000000);
        sessionStorage.setItem('paperid_' + paperid, uuid.value);
      }
      sendNotify();
    }
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(loadingState) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "title"
      }, "组卷中", -1)), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "subtitle"
      }, "您可以先关闭本页面，后台会自动继续组卷...", -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(LoadingIcon))])], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_unref(time) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[2] || (_cache[2] = _createTextVNode("本页面")), _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(time)), 1), _cache[3] || (_cache[3] = _createTextVNode("秒钟后关闭"))])) : _unref(time) === -999 ? (_openBlock(), _createElementBlock("div", _hoisted_5, "组卷似乎遇到了问题，请重试或联系管理员")) : _createCommentVNode("", true), _unref(msg) ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(msg)), 1)) : _createCommentVNode("", true), _unref(msg) ? (_openBlock(), _createBlock(_component_t_button, {
        key: 3,
        theme: "default",
        onClick: closePage
      }, {
        default: _withCtx(function () {
          return _cache[4] || (_cache[4] = [_createTextVNode(" 立即关闭 ")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)], 64))]);
    };
  }
};