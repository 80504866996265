import CryptoJS from "crypto-js";

const key = "zxhjzxhjzxhjzxhj"; //后端给出的密钥
const iv = "zxhjzxhjzxhjzxhj"; //后端给出的密钥偏移量

//加密方法
export function Encrypt(text) {
  var srcs = CryptoJS.enc.Utf8.parse(text);
  var encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

export function Decrypt(text) {
  let baseResult = CryptoJS.enc.Base64.parse(text); // Base64解密
  let ciphertext = CryptoJS.enc.Base64.stringify(baseResult); // Base64解密
  let decryptResult = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
    //  AES解密
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  let resData = decryptResult.toString(CryptoJS.enc.Utf8).toString();
  return resData;
}
