import _objectSpread from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "paper-detail"
};
var _hoisted_2 = {
  class: "practice-paper"
};
var _hoisted_3 = {
  class: "left"
};
var _hoisted_4 = {
  class: "right"
};
var _hoisted_5 = {
  class: "head"
};
var _hoisted_6 = {
  key: 0,
  class: "head-btns"
};
var _hoisted_7 = {
  class: "group-list"
};
var _hoisted_8 = {
  class: "group-title"
};
var _hoisted_9 = {
  class: "topic-list"
};
var _hoisted_10 = {
  class: "exercise-item"
};
var _hoisted_11 = {
  key: 1,
  class: "exercise-btn"
};
var _hoisted_12 = {
  key: 0,
  class: "editing"
};
var _hoisted_13 = ["onClick"];
import layout from "./widgets/layout";
import navBar from "./widgets/navBar";
import topicGroup from "./widgets/topicGroup";
import editText from "./widgets/editText";
import actionBox from "./widgets/actionBox";
import setScoreDialog from "./widgets/setScoreDialog";
import setPracticeDialog from "./widgets/setPracticeDialog";
import exportPracticeDialog from "./widgets/exportPracticeDialog";
import exerciseCard from "@/views/sourcePreview/paperDetail/exerciseCard";
import applyEditDialog from "./widgets/applyEditDialog.vue";

/* 工具 */
import { toRefs, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { numberToChinese } from "@/util/index";
import { MessagePlugin, DialogPlugin } from "tdesign-vue-next";
import { EXERCISE_NAME_MAP } from "../exerciseCard/const";
import { getStore } from "@/util/store";
import bus from "@/util/bus";

/* api */
import { getPracticeDetail, getResourceState, editPractice as editPracticeApi } from "@/api/practicePaper";

/* 变量 */

export default {
  __name: 'index',
  props: {
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    /* 组件 */
    var route = useRoute();
    var router = useRouter();
    var props = __props;
    var _toRefs = toRefs(props),
      isPreview = _toRefs.isPreview;
    var state = reactive({
      editingTopics: [],
      // 修改中的习题id列表
      exerciseId: null,
      // 点击申请修改的习题信息
      practiceData: {},
      // 习练数据
      setScoreShow: false,
      // 设置分值弹窗
      setPracticeShow: false,
      // 设置习练弹窗
      exportPracticeShow: false,
      // 习练导出弹窗
      applyEditShow: false // 申请修改弹窗
    });

    /* 事件 */
    // 页面返回
    var backHander = function backHander() {
      router.go(-1);
    };

    // 习练设置
    var setPractice = function setPractice() {
      state.setPracticeShow = true;
    };

    // 习练保存
    var savePractice = function savePractice() {
      editPractice("保存成功！");
    };

    // 习练导出
    var exportPractice = function exportPractice() {
      state.exportPracticeShow = true;
    };

    // 是否显示习题的提示
    var isShowTooltip = function isShowTooltip(topic) {
      var _route$query;
      return ((_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.reportEditor) && topic.isPublic === 0;
    };

    // 是否显示习题申请修改按钮 %26isStored%3D1
    var isShowEditBtn = function isShowEditBtn(topic) {
      var _route$query2;
      var userInfo = getStore({
        name: "userInfo"
      }); // 用户信息
      var paperIsStored = ((_route$query2 = route.query) === null || _route$query2 === void 0 ? void 0 : _route$query2.isStored) === "1"; // 习练是否入库
      var isOwner = (topic === null || topic === void 0 ? void 0 : topic.ownerId) === (userInfo === null || userInfo === void 0 ? void 0 : userInfo.id); // 是否自行创建
      var topicIsStored = (topic === null || topic === void 0 ? void 0 : topic.isPublic) === 1 && (topic === null || topic === void 0 ? void 0 : topic.reportTarget) === 0; // 习题是否入库
      return paperIsStored && isOwner && topicIsStored;
    };
    var tooltipTxt = function tooltipTxt(topic) {
      var store = "";
      if (topic.reportTarget === 0) {
        store = "平台库";
      } else if (topic.reportTarget === 1) {
        store = "机构库";
      }
      return "\u8BE5\u4E60\u9898\u672A\u5165".concat(store);
    };

    // 获取所有习题
    var getAllTopics = function getAllTopics() {
      var _state$practiceData;
      var allTopics = []; // 所有的习题列表（为了计算习题sort）
      // 先给题组sort赋值，并过滤添加习题按钮
      (_state$practiceData = state.practiceData) === null || _state$practiceData === void 0 || _state$practiceData.paperNavs.forEach(function (group, index) {
        group.sort = index + 1;
        var topics = group.paperNavItems.filter(function (e) {
          return e.id !== -1;
        });
        group.paperNavItems = topics;
        allTopics.push.apply(allTopics, _toConsumableArray(topics));
      });
      return allTopics;
    };

    // 重新给习题组/习题列表，sort赋值
    var sortPractice = function sortPractice() {
      var res = JSON.parse(JSON.stringify(state.practiceData));
      var allTopics = getAllTopics();
      // 给习题sort赋值
      res === null || res === void 0 || res.paperNavs.forEach(function (g) {
        g.paperNavItems.forEach(function (t) {
          var index = allTopics.findIndex(function (e) {
            return e.id === t.id;
          });
          index !== -1 && (t.sort = index + 1);
        });
      });
      state.practiceData = res;
    };

    // 点击自动排序
    var automaticSort = function automaticSort() {
      var _state$practiceData2;
      // 先取出所有习题，并分组
      var topics = {
        SINGLE_CHOICE: [],
        MULTIPLE_CHOICE: [],
        JUDGE: [],
        COMPLETION: [],
        ANSWER_QUESTIONS: [],
        COMPREHENSIVE: []
      };
      (_state$practiceData2 = state.practiceData) === null || _state$practiceData2 === void 0 || _state$practiceData2.paperNavs.forEach(function (group) {
        group.paperNavItems.forEach(function (topic) {
          var _topic$exercisesData = topic === null || topic === void 0 ? void 0 : topic.exercisesData,
            type = _topic$exercisesData.type;
          type && topics[type].push(topic);
        });
      });
      // 将分好的习题，放到习题组内
      var newPaperNavs = [];
      var _loop = function _loop() {
        var _state$practiceData3;
        var value = EXERCISE_NAME_MAP[key];
        var index = newPaperNavs.length;
        var navId = ((_state$practiceData3 = state.practiceData) === null || _state$practiceData3 === void 0 || (_state$practiceData3 = _state$practiceData3.paperNavs[index]) === null || _state$practiceData3 === void 0 ? void 0 : _state$practiceData3.id) || null;
        if (topics[value].length > 0) {
          newPaperNavs.push({
            id: navId,
            name: key,
            sort: index + 1,
            paperNavItems: topics[value].map(function (e) {
              return _objectSpread(_objectSpread({}, e), {}, {
                navId: navId
              });
            })
          });
        }
      };
      for (var key in EXERCISE_NAME_MAP) {
        _loop();
      }
      state.practiceData = _objectSpread(_objectSpread({}, state.practiceData), {}, {
        paperNavs: newPaperNavs
      });
      editPractice("自动排序成功！");
    };

    // 编辑标题（习练标题、习题组标题）
    var editTitle = function editTitle(tip) {
      editPractice(tip);
    };

    // 获取习题组悬浮按钮列表
    var getGroupHoverBtns = function getGroupHoverBtns(group, index) {
      var _state$practiceData4;
      return [{
        label: "上移",
        disable: index === 0,
        clickEvent: function clickEvent() {
          return groupMoveUp(group, index);
        }
      }, {
        label: "下移",
        disable: index === ((_state$practiceData4 = state.practiceData) === null || _state$practiceData4 === void 0 ? void 0 : _state$practiceData4.paperNavs.length) - 1,
        clickEvent: function clickEvent() {
          return groupMoveDown(group, index);
        }
      }, {
        label: "删除",
        clickEvent: function clickEvent() {
          return groupDelete(group, index);
        }
      }];
    };

    // 获取习题悬浮按钮列表
    var getTopicHoverBtns = function getTopicHoverBtns(group, topic, idx) {
      return [{
        label: "编辑",
        clickEvent: function clickEvent() {
          return topicEdit(group, topic, idx);
        }
      }, {
        label: "上移",
        disable: idx === 0,
        clickEvent: function clickEvent() {
          return topicMoveUp(group, topic, idx);
        }
      }, {
        label: "下移",
        disable: idx === (group === null || group === void 0 ? void 0 : group.paperNavItems.length) - 1,
        clickEvent: function clickEvent() {
          return topicMoveDown(group, topic, idx);
        }
      }, {
        label: "删除",
        clickEvent: function clickEvent() {
          return topicDelete(group, topic, idx);
        }
      }];
    };

    // 习题组上移
    var groupMoveUp = function groupMoveUp(group, index) {
      var lastGroup = state.practiceData.paperNavs[index - 1];
      state.practiceData.paperNavs[index - 1] = group;
      state.practiceData.paperNavs[index] = lastGroup;
      editPractice();
    };

    // 习题组下移
    var groupMoveDown = function groupMoveDown(group, index) {
      var nextGroup = state.practiceData.paperNavs[index + 1];
      state.practiceData.paperNavs[index + 1] = group;
      state.practiceData.paperNavs[index] = nextGroup;
      editPractice();
    };

    // 习题组删除
    var groupDelete = function groupDelete(group, index) {
      var dialog = DialogPlugin({
        header: "删除题组",
        body: "是否删除该题组",
        theme: "danger",
        cancelBtn: "取消",
        confirmBtn: {
          content: "确认",
          theme: "danger"
        },
        onConfirm: function onConfirm() {
          state.practiceData.paperNavs.splice(index, 1);
          editPractice("删除题组成功！");
          dialog.destroy();
        }
      });
    };

    // 习题编辑
    var topicEdit = function topicEdit(group, topic) {
      router.push({
        path: "/editExcersise",
        query: {
          id: topic.exercisesId,
          uniqueCode: topic.uniqueCode,
          itemId: topic.id
        }
      });
    };

    // 习题上移
    var topicMoveUp = function topicMoveUp(group, topic, index) {
      var lastTopic = group.paperNavItems[index - 1];
      group.paperNavItems[index - 1] = topic;
      group.paperNavItems[index] = lastTopic;
      editPractice();
    };

    // 习题下移
    var topicMoveDown = function topicMoveDown(group, topic, index) {
      var nextTopic = group.paperNavItems[index + 1];
      group.paperNavItems[index + 1] = topic;
      group.paperNavItems[index] = nextTopic;
      editPractice();
    };

    // 习题删除
    var topicDelete = function topicDelete(group, topic, index) {
      var dialog = DialogPlugin({
        header: "删除题目",
        body: "是否删除该题目",
        theme: "danger",
        cancelBtn: "取消",
        confirmBtn: {
          content: "确认",
          theme: "danger"
        },
        onConfirm: function onConfirm() {
          group.paperNavItems.splice(index, 1);
          editPractice("删除题目成功！");
          dialog.destroy();
        }
      });
    };

    // 赋值习练数据并编辑
    var setPracticeData = function setPracticeData(data, tip) {
      state.practiceData = data;
      editPractice(tip);
    };

    // 调用编辑习练
    var editPractice = function editPractice() {
      var tip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "修改成功！";
      sortPractice();
      var pData = JSON.parse(JSON.stringify(state.practiceData));
      pData.paperNavs.forEach(function (g) {
        g.paperNavItems.forEach(function (t) {
          delete t.exercisesData;
        });
      });
      editPracticeApi(pData).then(function (res) {
        if (res.data.code === 0) {
          MessagePlugin.success(tip);
          getPractice();
        }
      });
    };

    // 获取详情
    var getPractice = function getPractice() {
      var _route$query3;
      var resId = (_route$query3 = route.query) === null || _route$query3 === void 0 ? void 0 : _route$query3.resId;
      if (!resId) return;
      getPracticeDetail(resId).then(function (res) {
        if (res.data.code === 0) {
          var _route$query4;
          state.practiceData = res.data.data || {};
          if (((_route$query4 = route.query) === null || _route$query4 === void 0 ? void 0 : _route$query4.isStored) === "1") {
            getEditBtnStatus();
          }
        }
      });
    };

    // 按钮是否申请中状态
    var isEditing = function isEditing(topicId) {
      return state.editingTopics.includes(topicId);
    };

    // 点击申请修改
    var applyEdit = function applyEdit(topic) {
      var _topic$exercisesData2;
      state.exerciseId = topic === null || topic === void 0 || (_topic$exercisesData2 = topic.exercisesData) === null || _topic$exercisesData2 === void 0 ? void 0 : _topic$exercisesData2.id;
      state.applyEditShow = true;
    };

    // 获取数据库申请修改按钮状态
    var getEditBtnStatus = function getEditBtnStatus() {
      var allTopics = getAllTopics();
      var topicIds = allTopics.map(function (e) {
        return e.exercisesId;
      });
      var params = {
        resId: topicIds,
        resType: 2,
        ruleType: 1
      };
      getResourceState(params).then(function (res) {
        if (res.data.code === 0) {
          var list = res.data.data || [];
          state.editingTopics = list.filter(function (e) {
            return e.resState === 0;
          }).map(function (e) {
            return e.resId;
          });
        }
      });
    };

    /* 钩子 */
    onMounted(function () {
      getPractice();
    });
    return function (_ctx, _cache) {
      var _component_t_icon = _resolveComponent("t-icon");
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(layout), null, {
        header: _withCtx(function () {
          return [_createVNode(_unref(navBar), {
            "left-text": _unref(isPreview) ? '习练预览' : '习练创编',
            "left-icon-show": !_unref(isPreview),
            "is-back": false,
            onBack: backHander
          }, _createSlots({
            _: 2
          }, [!_unref(isPreview) ? {
            name: "right",
            fn: _withCtx(function () {
              return [_createElementVNode("span", {
                class: "top-btn",
                onClick: setPractice
              }, "习练设置"), _createElementVNode("span", {
                class: "top-btn",
                onClick: savePractice
              }, "习练保存")];
            }),
            key: "0"
          } : undefined]), 1032, ["left-text", "left-icon-show"])];
        }),
        body: _withCtx(function () {
          var _state$practiceData5;
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_unref(topicGroup), {
            "practice-data": state.practiceData,
            isPreview: _unref(isPreview),
            onChange: setPracticeData
          }, null, 8, ["practice-data", "isPreview"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_unref(editText), {
            value: state.practiceData.title,
            "onUpdate:value": _cache[0] || (_cache[0] = function ($event) {
              return state.practiceData.title = $event;
            }),
            isPreview: _unref(isPreview),
            onBlur: _cache[1] || (_cache[1] = function ($event) {
              return editTitle('修改习练标题成功！');
            })
          }, null, 8, ["value", "isPreview"]), !_unref(isPreview) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", {
            onClick: automaticSort
          }, "自动排序"), _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return state.setScoreShow = true;
            })
          }, "分值设置")])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(((_state$practiceData5 = state.practiceData) === null || _state$practiceData5 === void 0 ? void 0 : _state$practiceData5.paperNavs) || [], function (group, index) {
            return _openBlock(), _createBlock(_unref(actionBox), {
              id: 'practice-group-' + group.id,
              class: _normalizeClass(["group-item", {
                mt16: index !== 0
              }]),
              key: group.sort,
              "hover-btns": getGroupHoverBtns(group, index),
              isPreview: _unref(isPreview)
            }, {
              hover: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_unref(numberToChinese)(group.sort)) + "、", 1), _createVNode(_unref(editText), {
                  value: group.name,
                  "onUpdate:value": function onUpdateValue($event) {
                    return group.name = $event;
                  },
                  maxlength: "10",
                  fontSize: "16px",
                  fontColor: "rgba(35, 42, 53, 0.85)",
                  lineHeight: "24px",
                  backGroundColor: "#D9E1FF",
                  isPreview: _unref(isPreview),
                  onBlur: _cache[3] || (_cache[3] = function ($event) {
                    return editTitle('修改题组名称成功！');
                  })
                }, null, 8, ["value", "onUpdate:value", "isPreview"])])];
              }),
              default: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((group === null || group === void 0 ? void 0 : group.paperNavItems) || [], function (topic, idx) {
                  return _openBlock(), _createBlock(_unref(actionBox), {
                    id: 'practice-topic-' + topic.id,
                    class: "mt16",
                    key: topic.id,
                    hoverAll: "",
                    "hover-btns": getTopicHoverBtns(group, topic, idx),
                    isPreview: _unref(isPreview)
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("div", _hoisted_10, [isShowTooltip(topic) ? (_openBlock(), _createBlock(_component_t_tooltip, {
                        key: 0,
                        class: "tooltip",
                        content: tooltipTxt(topic)
                      }, {
                        default: _withCtx(function () {
                          return [_createVNode(_component_t_icon, {
                            name: "error-circle-filled",
                            color: "#E53737"
                          })];
                        }),
                        _: 2
                      }, 1032, ["content"])) : _createCommentVNode("", true), _createVNode(_unref(exerciseCard), {
                        class: "pad10",
                        sort: topic.sort,
                        data: {
                          exercise: topic.exercisesData
                        },
                        hideAnalysis: ""
                      }, null, 8, ["sort", "data"]), isShowEditBtn(topic) ? (_openBlock(), _createElementBlock("div", _hoisted_11, [isEditing(topic === null || topic === void 0 ? void 0 : topic.exercisesId) ? (_openBlock(), _createElementBlock("span", _hoisted_12, "修改中")) : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "edit",
                        onClick: function onClick($event) {
                          return applyEdit(topic);
                        }
                      }, "申请修改", 8, _hoisted_13))])) : _createCommentVNode("", true)])];
                    }),
                    _: 2
                  }, 1032, ["id", "hover-btns", "isPreview"]);
                }), 128))])];
              }),
              _: 2
            }, 1032, ["id", "class", "hover-btns", "isPreview"]);
          }), 128))])])])];
        }),
        _: 1
      }), _createVNode(_unref(setScoreDialog), {
        visible: state.setScoreShow,
        "onUpdate:visible": _cache[4] || (_cache[4] = function ($event) {
          return state.setScoreShow = $event;
        }),
        "practice-data": state.practiceData,
        onSure: setPracticeData
      }, null, 8, ["visible", "practice-data"]), _createVNode(_unref(setPracticeDialog), {
        visible: state.setPracticeShow,
        "onUpdate:visible": _cache[5] || (_cache[5] = function ($event) {
          return state.setPracticeShow = $event;
        }),
        "practice-data": state.practiceData,
        onSure: setPracticeData
      }, null, 8, ["visible", "practice-data"]), _createVNode(_unref(exportPracticeDialog), {
        visible: state.exportPracticeShow,
        "onUpdate:visible": _cache[6] || (_cache[6] = function ($event) {
          return state.exportPracticeShow = $event;
        })
      }, null, 8, ["visible"]), _createVNode(applyEditDialog, {
        visible: state.applyEditShow,
        "onUpdate:visible": _cache[7] || (_cache[7] = function ($event) {
          return state.applyEditShow = $event;
        }),
        exerciseId: state.exerciseId,
        onReload: getEditBtnStatus
      }, null, 8, ["visible", "exerciseId"])]);
    };
  }
};