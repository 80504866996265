export const chatConfig = {
  sdk: "NIM_Web_SDK_v6.1.0",
  // 本地消息显示数量，会影响性能
  localMsglimit: 20,
  // 用户的appkey
  // 用于在web demo中注册账号异步请求demo 服务器中使用
  test: {
    appKey: "41aad933e712cd1f0e8047a049e0fde3",
    postUrl: "https://apptest.netease.im",
  },
  online: {
    appKey: "41aad933e712cd1f0e8047a049e0fde3",
    postUrl: "https://app.netease.im",
  },
  useDb: true,
  ack: 1653377153669,
  defaultAccount: "devlongtianyouwosdvg",
  defaultToken: "devl313wvzr",
  defaultToAccount: "devchaiwei2152s3", // 默认联系人的云信账号
  defaultToToken: "devaa457691", //
  schoolId: "2266",
  defaultUserId: "1747529345304449026",
  nosSurvivalTime: 20 * 24 * 60 * 60,
  defaultUserIcon: "",
  env: "pre", // dev,test,pre,pro
  defaultCurstormerAccid: [
    "yijianfankui",
    "jishuzhichi",
    "houqingbaoxiu",
    "xiaozhangxinxiang",
    "canyinfuwu",
  ],
  defaultCurstormerName: [
    "校长信箱",
    "功能反馈",
    "技术支持",
    "后勤报修",
    "餐饮服务",
  ],
};
