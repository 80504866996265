import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.values.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import _imports_0 from '@/assets/paper/delete.svg';
import _imports_1 from '@/assets/paper/drag-move.svg';
import _imports_2 from '@/assets/paper/add.svg';
var _hoisted_1 = {
  class: "topic-group"
};
var _hoisted_2 = {
  class: "head"
};
var _hoisted_3 = {
  class: "group-item"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 0,
  class: "icons"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  key: 0,
  class: "undraggable"
};
import draggable from "vuedraggable";

/* 工具 */
import { ref, reactive, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { numberToChinese, debounce } from "@/util/index";
import { MessagePlugin, DialogPlugin } from "tdesign-vue-next";

/* 变量 */

export default {
  __name: 'topicGroup',
  props: {
    // 习练数据
    practiceData: {
      type: Object,
      default: function _default() {}
    },
    // 是否预览
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  emits: ["change"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 组件 */
    var route = useRoute();
    var router = useRouter();
    var emit = __emit;
    var props = __props;
    var form = ref();
    var state = reactive({
      copyPracticeData: {},
      // 拷贝一份数据，给当前组件使用
      addDialog: false,
      rules: {
        topicGroupName: [{
          required: true
        }]
      },
      formData: {
        topicGroupName: ""
      }
    });

    /* 计算属性 */
    var groupList = computed(function () {
      var _state$copyPracticeDa;
      return ((_state$copyPracticeDa = state.copyPracticeData) === null || _state$copyPracticeDa === void 0 ? void 0 : _state$copyPracticeDa.paperNavs) || [];
    });
    var paperId = computed(function () {
      var _state$copyPracticeDa2;
      return (_state$copyPracticeDa2 = state.copyPracticeData) === null || _state$copyPracticeDa2 === void 0 ? void 0 : _state$copyPracticeDa2.id;
    });

    /* 监听 */
    watch(function () {
      return props.practiceData;
    }, function (data) {
      state.copyPracticeData = JSON.parse(JSON.stringify(data));
    }, {
      immediate: true,
      deep: true
    });

    /* 事件 */
    // 给习题列表，加一个 添加按钮 item
    var formartTopics = function formartTopics(group) {
      var res = (group === null || group === void 0 ? void 0 : group.paperNavItems) || [];
      var index = res.findIndex(function (e) {
        return e.id === -1;
      });
      index === -1 && !props.isPreview && res.push({
        id: -1,
        undraggable: true
      });
      return res;
    };

    // 添加题组-关闭
    var onClose = function onClose() {
      form.value.reset();
    };

    // 添加题组-确认
    var onConfirm = debounce(function () {
      form.value.submit();
    });

    // 添加题组-提交
    var onSubmit = function onSubmit() {
      form.value.validate().then(function (result) {
        if (result && Object.keys(result).length) {
          var _Object$values$;
          var firstError = (_Object$values$ = Object.values(result)[0]) === null || _Object$values$ === void 0 || (_Object$values$ = _Object$values$[0]) === null || _Object$values$ === void 0 ? void 0 : _Object$values$.message;
          MessagePlugin.warning(firstError);
        }
        if (result === true) {
          var _state$copyPracticeDa3, _state$copyPracticeDa4;
          var pushItem = {
            paperId: paperId.value,
            name: state.formData.topicGroupName,
            sort: ((_state$copyPracticeDa3 = state.copyPracticeData) === null || _state$copyPracticeDa3 === void 0 ? void 0 : _state$copyPracticeDa3.paperNavs.length) - 1,
            paperNavItems: []
          };
          (_state$copyPracticeDa4 = state.copyPracticeData) === null || _state$copyPracticeDa4 === void 0 || _state$copyPracticeDa4.paperNavs.push(pushItem);
          state.addDialog = false;
          emitChange('添加题组成功！');
        }
      });
    };

    // 删除题组
    var delTopicGroup = function delTopicGroup(id) {
      var dialog = DialogPlugin({
        header: "删除题组",
        body: "是否删除该题组",
        theme: "danger",
        cancelBtn: "取消",
        confirmBtn: {
          content: "确认",
          theme: "danger"
        },
        onConfirm: function onConfirm() {
          var _state$copyPracticeDa5, _state$copyPracticeDa6;
          var index = (_state$copyPracticeDa5 = state.copyPracticeData) === null || _state$copyPracticeDa5 === void 0 ? void 0 : _state$copyPracticeDa5.paperNavs.findIndex(function (e) {
            return e.id === id;
          });
          index !== -1 && ((_state$copyPracticeDa6 = state.copyPracticeData) === null || _state$copyPracticeDa6 === void 0 ? void 0 : _state$copyPracticeDa6.paperNavs.splice(index, 1));
          dialog.destroy();
          emitChange('删除题组成功！');
        }
      });
    };

    // 拖拽事件
    var dragHandle = function dragHandle() {
      emitChange();
    };

    // 点击题目
    var topicClick = function topicClick(groupId, topicId) {
      if (topicId === -1) {
        var _route$query;
        router.push({
          path: '/choiseExercise',
          query: {
            resId: (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.resId,
            topicGroupId: groupId
          }
        });
      } else {
        scrollTo("#practice-topic-".concat(topicId));
      }
    };
    var scrollTo = function scrollTo(id) {
      var dom = document.querySelector(id);
      dom.scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    };

    // 发射事件
    var emitChange = function emitChange(tip) {
      emit("change", state.copyPracticeData, tip);
    };
    return function (_ctx, _cache) {
      var _component_t_input = _resolveComponent("t-input");
      var _component_t_form_item = _resolveComponent("t-form-item");
      var _component_t_form = _resolveComponent("t-form");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "习练导航", -1)), !props.isPreview ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "btn",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return state.addDialog = true;
        })
      }, "添加题组")) : _createCommentVNode("", true)]), _createVNode(_unref(draggable), {
        class: "group-list",
        list: groupList.value,
        "item-key": "id",
        animation: "300",
        handle: ".drag-icon",
        onEnd: dragHandle
      }, {
        item: _withCtx(function (_ref2) {
          var group = _ref2.element;
          return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
            class: "group-item-head",
            onClick: function onClick($event) {
              return scrollTo("#practice-group-".concat(group.id));
            }
          }, [_createElementVNode("span", null, _toDisplayString(_unref(numberToChinese)(group.sort)) + "、" + _toDisplayString(group.name), 1), !props.isPreview ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
            class: "delete-icon",
            src: _imports_0,
            alt: "",
            onClick: function onClick($event) {
              return delTopicGroup(group.id);
            }
          }, null, 8, _hoisted_6), _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "drag-icon",
            src: _imports_1,
            alt: ""
          }, null, -1))])) : _createCommentVNode("", true)], 8, _hoisted_4), _createVNode(_unref(draggable), {
            class: "topic-list",
            list: formartTopics(group),
            "item-key": "id",
            animation: "300",
            group: "topic",
            filter: ".undraggable",
            disabled: props.isPreview,
            onEnd: dragHandle
          }, {
            item: _withCtx(function (_ref3) {
              var topic = _ref3.element;
              return [_createElementVNode("div", {
                class: "topic-item",
                onClick: function onClick($event) {
                  return topicClick(group.id, topic.id);
                }
              }, [topic.undraggable ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [_createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(topic.sort), 1)], 8, _hoisted_7)];
            }),
            _: 2
          }, 1032, ["list", "disabled"])])];
        }),
        _: 1
      }, 8, ["list"]), _createVNode(_component_t_dialog, {
        visible: state.addDialog,
        "onUpdate:visible": _cache[2] || (_cache[2] = function ($event) {
          return state.addDialog = $event;
        }),
        header: "添加题组",
        onClose: onClose,
        onConfirm: _unref(onConfirm)
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_form, {
            ref_key: "form",
            ref: form,
            data: state.formData,
            rules: state.rules,
            showErrorMessage: false,
            onSubmit: onSubmit
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_t_form_item, {
                label: "题组名称",
                name: "topicGroupName"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_t_input, {
                    modelValue: state.formData.topicGroupName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                      return state.formData.topicGroupName = $event;
                    }),
                    placeholder: "请输入题组名称",
                    maxlength: "10"
                  }, null, 8, ["modelValue"])];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["data", "rules"])];
        }),
        _: 1
      }, 8, ["visible", "onConfirm"])]);
    };
  }
};