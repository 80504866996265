import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent } from "vue";
var _hoisted_1 = {
  class: "notice-detail"
};
var _hoisted_2 = {
  class: "top"
};
var _hoisted_3 = {
  class: "left-func"
};
var _hoisted_4 = {
  class: "content"
};
var _hoisted_5 = {
  key: 0,
  class: "empty"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  key: 1,
  class: "content-list"
};
var _hoisted_8 = {
  class: "text"
};
var _hoisted_9 = {
  class: "time"
};
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  class: "foot"
};
import { ref } from 'vue';
import { Icon } from 'tdesign-icons-vue-next';
import { getMstListAll, getBatchUpdate } from '@/api/home.js';
import { useRouter } from 'vue-router';
import { getEnvName, getHostName } from '@/util/env';
export default {
  __name: 'Notice',
  setup: function setup(__props) {
    var emptyImg = require('@/assets/course/teach-empty.svg');
    var loading = ref(false);
    var msgList = ref([]);
    var total = ref(0);
    var current = ref(1);
    var getMstData = function getMstData(pageInfo) {
      getMstListAll({
        current: pageInfo.current,
        size: 10
      }).then(function (res) {
        if (res.data.code === 0) {
          total.value = res.data.data.total;
          msgList.value = res.data.data.records;
        }
      });
    };
    getMstData({
      current: current.value
    });
    getBatchUpdate();
    var router = useRouter();
    var noticeClick = function noticeClick(item) {
      if (item.msgContentType === 1) {
        // 跳转对应应该带路由
        var env_name = getEnvName();
        var hostName = getHostName();
        var url = '';
        if (item.msgMainCode === 'app_auth_apply') {
          // 应用权限申请与审批
          if (item.msgSubCode.includes('applicant')) {
            // 申请人
            url = "https://".concat(env_name ? env_name + 'main' : 'web', ".").concat(hostName, "/applyApp?applyApp=%2Fapps");
          } else {
            // 审批人
            url = "https://".concat(env_name ? env_name + 'main' : 'web', ".").concat(hostName, "/applyApp?applyApp=%2FpermissionApproval");
          }
        } else {
          url = "https://".concat(env_name ? env_name + 'main' : 'web', ".").concat(hostName, "/evaluate?evaluate=%2Fresult%2Foverview%2F").concat(item.payload.projectId);
        }
        window.location.href = url;
      } else if (item.msgContentType === 2) {
        // 查看大图
      }
    };
    var goBack = function goBack() {
      //判断当前是否存在JSAndroid方法，如果出现错误， 属于pc端
      if (window.JSAndroid) {
        window.JSAndroid.activityFinish();
      } else {
        router.back();
      }
    };
    return function (_ctx, _cache) {
      var _component_t_pagination = _resolveComponent("t-pagination");
      var _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_unref(Icon), {
        name: "chevron-left",
        size: "32px",
        class: "top-back",
        onClick: goBack
      }), _cache[1] || (_cache[1] = _createElementVNode("span", null, "消息通知", -1))])]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [msgList.value.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
        src: _unref(emptyImg)
      }, null, 8, _hoisted_6), _cache[2] || (_cache[2] = _createElementVNode("span", null, "暂无消息通知", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(msgList.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "content-list-li"
        }, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.payload.content), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, _toDisplayString(item.createTime), 1), item.msgContentType !== 0 ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: {
            "cursor": "pointer"
          },
          onClick: function onClick($event) {
            return noticeClick(item);
          }
        }, "查看详情", 8, _hoisted_10)) : _createCommentVNode("", true)])]);
      }), 128))]))])), [[_directive_loading, loading.value]]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_t_pagination, {
        current: current.value,
        "onUpdate:current": _cache[0] || (_cache[0] = function ($event) {
          return current.value = $event;
        }),
        total: total.value,
        showPageSize: false,
        onChange: getMstData
      }, null, 8, ["current", "total"])])]);
    };
  }
};