import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "title-text"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  key: 0,
  class: "option-list"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  key: 1,
  class: "judge-option"
};
import { computed } from "vue";

/* 变量 */

export default {
  __name: 'titleText',
  props: {
    type: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: function _default() {}
    },
    hideAnalysis: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var props = __props;

    /* 计算属性 */
    var isCorrect = computed(function () {
      var _props$data;
      var item = (_props$data = props.data) === null || _props$data === void 0 || (_props$data = _props$data.options) === null || _props$data === void 0 ? void 0 : _props$data.find(function (e) {
        return e.isCorrect;
      });
      return item.code;
    });
    return function (_ctx, _cache) {
      var _props$data2, _props$data3;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
        innerHTML: (_props$data2 = __props.data) === null || _props$data2 === void 0 ? void 0 : _props$data2.content
      }, null, 8, _hoisted_2), props.type === '单选题' || props.type === '多选题' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(((_props$data3 = __props.data) === null || _props$data3 === void 0 ? void 0 : _props$data3.options) || [], function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.sign,
          class: "option-item"
        }, [_createElementVNode("span", null, _toDisplayString(item.code) + ".", 1), _createElementVNode("span", {
          innerHTML: item.content
        }, null, 8, _hoisted_4)]);
      }), 128))])) : _createCommentVNode("", true), props.type === '判断题' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", null, [_createElementVNode("span", {
        class: _normalizeClass(["circle", {
          'correct': !props.hideAnalysis && isCorrect.value
        }])
      }, null, 2), _cache[0] || (_cache[0] = _createElementVNode("span", null, "正确", -1))]), _createElementVNode("div", null, [_createElementVNode("span", {
        class: _normalizeClass(["circle", {
          'correct': !props.hideAnalysis && !isCorrect.value
        }])
      }, null, 2), _cache[1] || (_cache[1] = _createElementVNode("span", null, "错误", -1))])])) : _createCommentVNode("", true)]);
    };
  }
};