// 有背景版本
export function fileClass(formatType) {
  const index = formatType.lastIndexOf('.')
  index !== -1 && (formatType = formatType.slice(index + 1))
  const mimetype = {
    doc: 'file-doc',
    docx: 'file-doc',
    jpg: 'file-pic',
    png: 'file-pic',
    picture: 'file-pic',
    jpeg: 'file-pic',
    pdf: 'file-pdf',
    xls: 'file-xls',
    xlsx: 'file-xls',
    ppt: 'file-ppt',
    txt: 'file-txt',
    pptx: 'file-ppt',
    rar: 'file-zip',
    mp3: 'file-audio',
    mp4: 'file-video',
    avi: 'file-video',
    mov: 'file-video',
    rmvb: 'file-video',
    flv: 'file-video',
    vs4: 'file-video',
    wma: 'file-video'
  }
  const type = formatType && formatType.toLowerCase()
  return mimetype[type] ? mimetype[type] : 'file-other'
}

export const escape2Html = str => {
  const arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp':
    '&',
    'quot': '"',
    'mdash': '—',
    "ldquo": '“',
    "rdquo": '”',
    "rsquo": '’',
    "lsquo": '‘',
    "permil": '‰',
    "euro": '€',
    "le": '≤',
    "ge": '≥',
    "ne": '≠',
    "middot": '·',
    "trade": '™',
    "\\n": ' '
  }
  return str.replace(/&(lt|gt|nbsp|amp|quot|mdash|ldquo|rdquo|rsquo|lsquo|permil|euro|le|ge|ne|middot|trade);|(\\n)/gi, (all, t, d) => {
    return arrEntities[t] || arrEntities[d]
  })
}

export const imgCoverMap = {
  'file-pic': require('@/assets/icon/imgIcon.png'),
  'file-pdf': require('@/assets/icon/pdfIcon.png'),
  'file-xls': require('@/assets/icon/excelIcon.png'),
  'file-doc': require('@/assets/icon/docIcon.png'),
  'file-video': require('@/assets/icon/videoIcon.png'),
  'file-audeo': require('@/assets/icon/audioIcon.png'),
  'file-other': require('@/assets/icon/otherIcon.png'),
  'file-ppt': require('@/assets/icon/pptIcon.png'),
  'file-txt': require('@/assets/icon/txt.png'),
  'file-audio': require('@/assets/icon/audioIcon.png'),
  'file-zip': require('@/assets/icon/zip.png'),
  'empty-img': require('@/assets/empty.svg')
}