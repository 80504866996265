import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "teacher-list"
};
var _hoisted_2 = {
  class: "left"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "right"
};
var _hoisted_5 = {
  class: "name"
};
var _hoisted_6 = {
  key: 0,
  class: "split"
};
var _hoisted_7 = {
  key: 0,
  class: "school"
};
import { ref } from 'vue';
export default {
  __name: 'TeamTeacher',
  props: {
    teacherList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var defaultUserImg = require('@/assets/course/user-default.png');
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.teacherList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "teacher-item"
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: item.cover ? item.cover : _unref(defaultUserImg),
          class: "teach-head"
        }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(item.teacher) + " ", 1), item.teacherTitle ? (_openBlock(), _createElementBlock("span", _hoisted_6, "|")) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.teacherTitle), 1)]), item.teacherSchool ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.teacherSchool), 1)) : _createCommentVNode("", true)])]);
      }), 128))]);
    };
  }
};