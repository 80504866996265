/**
 * 创建echarts hooks
 */

// 引入柱状图图表，图表后缀都为 Chart
import {BarChart, LineChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  DatasetComponent,
  GridComponent,
  LegendComponent, TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import * as Echarts from 'echarts/core';
// 标签自动布局、全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';

// 注册必须的组件
Echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LegendComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export const useCharts = () => {
  // 初始化
  const initCharts = (id) => {
    let chartInstance = null;
    const el = document.getElementById(id);
    if (el) {
      // 初始化 ECharts 实例
      chartInstance = Echarts.init(el);
    }
    return chartInstance;
  };

  // 设置值
  const setOption = (options, chartInstance) => {
    if (chartInstance) {
      chartInstance.setOption(options);
    }
  };

  // 设置加载显示
  const setLoading = (loading, chartInstance) => {
    if (chartInstance) {
      if (loading) {
        chartInstance.showLoading();
      } else chartInstance.hideLoading();
    }
  };

  return {
    initCharts,
    setOption,
    setLoading
  };
};
