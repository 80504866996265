import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "avatar-box"
};
var _hoisted_2 = {
  class: "avatar-list"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "btn-group"
};
import { ref } from "vue";
import { getAvatarList } from "@/api/user";
import { getStore } from "@/util/store";
export default {
  __name: 'index',
  emits: ['update', 'cancel'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var userInfo = getStore({
      name: "userInfo"
    }); // 获取userInfo

    var emit = __emit;
    var avatarList = ref([]);
    getAvatarList().then(function (res) {
      avatarList.value = res.data.data || [];
    });
    var selectedUrl = ref(userInfo.avatar);
    var handleChoose = function handleChoose(item) {
      selectedUrl.value = item;
    };
    var update = function update() {
      updateAvatar(selectedUrl.value);
    };
    var cancel = function cancel() {
      emit('cancel');
    };
    var updateAvatar = function updateAvatar(data) {
      emit('update', data);
    };
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(avatarList.value, function (url, index) {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["avatar-item", {
            'actived': selectedUrl.value === url
          }]),
          key: index,
          onClick: function onClick($event) {
            return handleChoose(url);
          }
        }, [_createElementVNode("img", {
          src: url,
          class: "avatar-img"
        }, null, 8, _hoisted_4)], 10, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_t_button, {
        class: "btn-item",
        theme: "default",
        onClick: cancel
      }, {
        default: _withCtx(function () {
          return _cache[0] || (_cache[0] = [_createTextVNode("取消")]);
        }),
        _: 1
      }), _createVNode(_component_t_button, {
        class: "btn-item",
        onClick: update,
        theme: "primary"
      }, {
        default: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createTextVNode("确认")]);
        }),
        _: 1
      })])]);
    };
  }
};