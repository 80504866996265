import { deepClone } from '@/util/util'
import { v4 } from 'uuid'
import { validateNull } from '@/util/validate'

/**
 * 计算题号
 * @param navs
 * @returns {*|{}}
 */
const calcNo = navs => {
  const nav = deepClone(navs)
  let i = 1
  let no = 1
  nav.forEach(v => {
    v.sort = no++
    v.item.forEach(v2 => {
      v2.index = v2.sort = i++
    })
  })
  return nav
}
// 多余字段信息
// const uselessObject = {
//   answerList: null,
//   media: null,
//   delFlg: null,
//   isPublic: 0,
//   ismark: 0,
//   isCollection: 0,
//   knowledgePoints: null,
//   chapter: null,
//   quality: null,
//   comment: null,
//   difficulty: null,
//   difficultyNum: null,
//   sourceImKey: null,
//   reportStatus: 'new',
//   reportStatusName: '新纪录',
//   reportMsg: null,
//   author: null,
//   authorName: null,
//   schoolId: null,
//   schoolName: null,
//   reviewComment: null,
//   schoolIsBoutique: false,
//   groupIsBoutique: false,
//   platformIsBoutique: false,
//   tenantId: null,
//   groupId: null,
//   groupName: null,
//   isOffShelf: false,
//   isFFolder: false,
//   reportList: null,
//   redStarFlag: 0
// }

const dealObject = (info = {}, v2 = {}, sort = '') => {
  console.log(info, 99999)
  return {
    itemId: v2.itemId,
    navItemId: v2.navItemId,
    id: info.id || '',
    uuid: info.uuid || '',
    title: info.content || '',
    sort: info.sort || sort,
    type: info.typeDictItemCode || '',
    answer:
      (info.answer &&
        info.answer.map(item => {
          item.id = item.id || ''
          item.exercisesId = info.id || ''
          item.correctValue = item.correctValue || ''
          return item
        })) ||
      [],
    correctAnswerId: info.correctAnswerId || '',
    correctAnswer: info.correctAnswer || '',
    subExerciseList: (info.subExerciseList || []).map(v => dealObject(v)),
    source: info.source || '',
    sourceId: info.sourceId || '',
    analysis: info.analysis || '',
    parent: info.parent || '',
    catalog: info.catalog || [],
    score: info.score || 1,
    exerciseSource: info.exerciseSource || 1,
    completionCount: info.completionCount || (info.answer && info.answer.length) || 1,
    completionScore: info.completionScore || '',
    version: info.version || 0
  }
}

/**
 * 组装格式化数据字段
 * @param navs
 * @returns {*}
 */
const formatPaperInfo = navs => {
  return navs.map(v => {
    return {
      sort: v.sort,
      navId: v.navId,
      navName: v.navName,
      uuid: v.uuid || v4(),
      item: v.navItemDetails.map(v2 => {
        const info = JSON.parse(v2.content)
        return dealObject(info, v2)
      })
    }
  })
}

/**
 * 创建默认试卷
 * @param list
 * @returns {{navs, title: string}}
 */
const createDefaultPaper = list => {
  const default_navs = [
    {
      id: 1,
      sort: 1,
      navName: '未命名题组',
      type: '',
      uuid: v4(),
      item: []
    }
  ]
  default_navs.forEach(nav => nav.item.push(...list))
  const default_paperMoudle = {
    title: '未命名习练',
    navs: [...default_navs]
  }
  return default_paperMoudle
}

/**
 * 试卷设置默认分数
 * @param navs
 */
const handleSetNavsScore = navs => {
  const copyNavs = deepClone(navs)
  copyNavs.forEach(v =>
    v.item.forEach(v2 => {
      if (v2.type === 'COMPREHENSIVE' && v2.subExerciseList.length > 0) {
        v2.score = 0
        v2.subExerciseList.forEach(v3 => {
          if (v3.type === 'COMPLETION') {
            v3.completionScore = 1
            v3.score = (v3.completionCount || v3.answer.length) * v3.completionScore
          } else {
            v3.score = 1
          }
          v2.score += parseInt(v3.score)
        })
      } else if (v2.type === 'COMPLETION') {
        v2.completionScore = 1
        v2.score = v2.completionScore * (v2.completionCount || v2.answer.length)
      } else {
        v2.score = 1
      }
    })
  )
  return copyNavs
}

/**
 * 设置新添加小题默认分数
 * @param exercises
 * @returns {*|{}}
 */
const handleSetItemScore = exercises => {
  const copyExercises = deepClone(exercises)
  copyExercises.forEach(v => {
    if (v.type === 'COMPREHENSIVE' && v.subExerciseList.length > 0) {
      v.score = 0
      v.subExerciseList.forEach(v2 => {
        if (v2.type === 'COMPLETION') {
          v2.completionScore = 1
          v2.score = (v2.completionCount || v2.answer.length) * v2.completionScore
        } else {
          v2.score = 1
        }
        v.score += parseInt(v2.score)
      })
    } else if (v.type === 'COMPLETION') {
      v.completionScore = 1
      v.score = v.completionScore * (v.completionCount || v.answer.length)
    } else {
      v.score = 1
    }
  })
  return copyExercises
}

/**
 * 校验试卷
 * @param navs
 * @returns {Promise<unknown>}
 */
const validateExercise = (navs = []) => {
  return new Promise((resolve, reject) => {
    // 校验试卷是否为空
    const error = { message: `当前习练为空，是否要退出？` }
    if (!navs.length) reject(error)
    // 校验题组以及题目
    for (let i = 0, len = navs.length; i < len; i++) {
      const items = navs[i].item
      // 校验空题组
      if (items && items.length <= 0) {
        const error = {
          message: `题组【${navs[i].navName}】内还没有添加题目，是否要退出？`,
          uuid: navs[i].uuid
        }
        reject(error)
        break
      } else {
        // 题目校验
        for (let j = 0, len = items.length; j < len; j++) {
          // 题干校验
          if (validateNull(items[j].title)) {
            const error = {
              message: `第${items[j].index}题题干内容为空，是否要退出？`,
              uuid: items[j].uuid
            }
            reject(error)
            break
          }
          // 选择题 选项选择校验
          if (items[j].type === 'SINGLE_CHOICE' || items[j].type === 'MULTIPLE_CHOICE') {
            if (items[j].answer.every(itm => validateNull(itm.correctValue))) {
              const error = {
                message: `第${items[j].index}题【选择题】还未选择正确答案，是否要退出？`,
                uuid: items[j].uuid
              }
              reject(error)
              break
            }
          }
          // 填空题 空校验
          if (items[j].type === 'COMPLETION') {
            if (items[j].answer && items[j].answer.length === 0) {
              const error = {
                message: `第${items[j].index}题【填空题】至少要有一个空，是否要退出？`,
                uuid: items[j].uuid
              }
              reject(error)
              break
            }
          }
          // 综合题 校验
          if (items[j].type === 'COMPREHENSIVE' && items[j].subExerciseList.length) {
            const subList = items[j].subExerciseList
            for (let k = 0, len = subList.length; k < len; k++) {
              // 选择题小题
              if (subList[k].type === 'SINGLE_CHOICE' || subList[k].type === 'MULTIPLE_CHOICE') {
                if (subList[k].answer.every(itm => validateNull(itm.correctValue))) {
                  const error = {
                    message: `第${items[j].index}题【综合题】的第（${k + 1}）小题还未选择正确答案，是否要退出？`,
                    uuid: items[j].uuid
                  }
                  reject(error)
                  break
                }
              }
              // 填空题小题
              if (subList[k].type === 'COMPLETION') {
                if (subList[k].answer && subList[k].answer.length === 0) {
                  const error = {
                    message: `第${items[j].index}题【综合题】的第（${k + 1}）小题至少要有一个空，是否要退出？`,
                    uuid: items[j].uuid
                  }
                  reject(error)
                  break
                }
              }
            }
          }
          resolve()
        }
      }
    }
  })
}
export { calcNo, dealObject, formatPaperInfo, createDefaultPaper, handleSetNavsScore, handleSetItemScore, validateExercise }
