import request from "@/api/axios";
import { apiList as api } from "./apiList";


export function getSelect(data) {
  return request({
    url: api.todo.getSelect, // 首页待办勾选
    method: "post",
    data
  });
}

/**
 * 轮询消息提醒关闭
 *
 */
export function closeNotification(data) {
  return request({
    url: api.todo.closeNotification,
    method: 'post',
    data,
  })
}
/**
 * 轮询获取一条待办提醒
 *
 */
export function pollingForBacklog() {
  return request({
    url: api.todo.pollingForBacklog,
    method: 'get',
  })
}