import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "layout"
};
var _hoisted_2 = {
  class: "layout-header"
};
var _hoisted_3 = {
  class: "layout-interlayer"
};
var _hoisted_4 = {
  class: "layout-body"
};
export default {
  __name: 'layout',
  setup: function setup(__props) {
    /**
     * 公共布局Layout组件
     */

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("header", _hoisted_2, [_renderSlot(_ctx.$slots, "header")]), _createElementVNode("section", _hoisted_3, [_renderSlot(_ctx.$slots, "interlayer")]), _createElementVNode("section", _hoisted_4, [_renderSlot(_ctx.$slots, "body")])]);
    };
  }
};