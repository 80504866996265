import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue";
import _imports_0 from '@/assets/paper/small-arrow.svg';
var _hoisted_1 = {
  class: "exercise-card"
};
var _hoisted_2 = {
  class: "tag-title"
};
var _hoisted_3 = {
  class: "type-tag"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "title"
};
var _hoisted_6 = {
  key: 0,
  class: "comprehensive-topic"
};
var _hoisted_7 = {
  class: "analysis-box"
};
var _hoisted_8 = {
  key: 0,
  class: "analysis-item"
};
var _hoisted_9 = {
  class: "comprehensive-answer"
};
var _hoisted_10 = {
  key: 1,
  class: "analysis-item"
};
var _hoisted_11 = {
  class: "analysis-item"
};
var _hoisted_12 = ["innerHTML"];
var _hoisted_13 = {
  key: 0,
  class: "analysis-switch"
};
import titleText from "./titleText";
import answerText from "./answerText";

/* 工具 */
import { ref, computed } from "vue";
import { EXERCISE_CODE_MAP } from "./const";

/* 变量 */

export default {
  __name: 'index',
  props: {
    sort: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Object,
      default: function _default() {}
    },
    hideAnalysis: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    /* 组件 */
    var props = __props;
    var isOpenAnalysis = ref(false); // 是否展开解析

    /* 计算属性 */
    var exerciseData = computed(function () {
      var _props$data;
      return ((_props$data = props.data) === null || _props$data === void 0 ? void 0 : _props$data.exercise) || {};
    });
    var exerciseType = computed(function () {
      return EXERCISE_CODE_MAP[exerciseData.value.type] || "其他";
    });
    var exerciseAnswer = computed(function () {
      var res = null;
      if (exerciseType.value === "综合题") {
        var _exerciseData$value;
        res = [];
        var exerciseList = ((_exerciseData$value = exerciseData.value) === null || _exerciseData$value === void 0 ? void 0 : _exerciseData$value.childs) || [];
        exerciseList.forEach(function (item) {
          res.push({
            type: EXERCISE_CODE_MAP[item === null || item === void 0 ? void 0 : item.type],
            answer: getAnswer(item)
          });
        });
      } else {
        res = getAnswer(exerciseData.value);
      }
      return res;
    });

    /* 事件 */
    // 根据题型，获取答案
    var getAnswer = function getAnswer(data) {
      var res = "";
      var type = data.type;
      type = EXERCISE_CODE_MAP[type];
      if (type === "单选题" || type === "多选题") {
        var list = (data === null || data === void 0 ? void 0 : data.options) || [];
        res = list.filter(function (e) {
          return e.isCorrect;
        }).map(function (e) {
          return e.code;
        }).join("，");
      } else if (type === "判断题") {
        var _options$;
        var options = (data === null || data === void 0 ? void 0 : data.options) || [];
        res = (_options$ = options[0]) !== null && _options$ !== void 0 && _options$.isCorrect ? "对" : "错";
      } else if (type === "填空题") {
        res = (data === null || data === void 0 ? void 0 : data.fillBlanks) || [];
      } else if (type === "简答题") {
        var _data$answer;
        res = data === null || data === void 0 || (_data$answer = data.answer) === null || _data$answer === void 0 ? void 0 : _data$answer.correct;
      }
      return res;
    };
    return function (_ctx, _cache) {
      var _exerciseData$value2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(exerciseType.value), 1), props.sort ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.sort) + ".", 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(titleText), {
        type: exerciseType.value,
        data: exerciseData.value,
        hideAnalysis: props.hideAnalysis
      }, null, 8, ["type", "data", "hideAnalysis"]), exerciseType.value === '综合题' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(((_exerciseData$value2 = exerciseData.value) === null || _exerciseData$value2 === void 0 ? void 0 : _exerciseData$value2.childs) || [], function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("span", null, "（" + _toDisplayString(index + 1) + "）", 1), _createVNode(_unref(titleText), {
          type: _unref(EXERCISE_CODE_MAP)[item.type],
          data: item,
          hideAnalysis: props.hideAnalysis
        }, null, 8, ["type", "data", "hideAnalysis"])]);
      }), 128))])) : _createCommentVNode("", true)])]), _withDirectives(_createElementVNode("div", _hoisted_7, [exerciseType.value === '综合题' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "analysis-tag"
      }, "答", -1)), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exerciseAnswer.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("span", null, "（" + _toDisplayString(index + 1) + "）", 1), _createVNode(_unref(answerText), {
          type: item === null || item === void 0 ? void 0 : item.type,
          answer: item === null || item === void 0 ? void 0 : item.answer
        }, null, 8, ["type", "answer"])]);
      }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "analysis-tag"
      }, "答", -1)), _createVNode(_unref(answerText), {
        type: exerciseType.value,
        answer: exerciseAnswer.value
      }, null, 8, ["type", "answer"])])), _createElementVNode("div", _hoisted_11, [_cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "analysis-tag analysis-tag-blue"
      }, "解", -1)), _createElementVNode("span", {
        class: "analysis-content",
        innerHTML: exerciseData.value.analysis
      }, null, 8, _hoisted_12)])], 512), [[_vShow, isOpenAnalysis.value]]), !__props.hideAnalysis ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", {
        class: "left",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return isOpenAnalysis.value = !isOpenAnalysis.value;
        })
      }, [_createElementVNode("span", null, _toDisplayString(isOpenAnalysis.value ? '收起解析' : '展开解析'), 1), _createElementVNode("img", {
        class: _normalizeClass({
          'rotate180': isOpenAnalysis.value
        }),
        src: _imports_0,
        alt: ""
      }, null, 2)]), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "bottom-btn")])])) : _createCommentVNode("", true)]);
    };
  }
};