import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue";
import _imports_0 from './down.svg';
var _hoisted_1 = {
  key: 0,
  class: "resource-detail"
};
var _hoisted_2 = {
  key: 0,
  class: "resource-info"
};
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { MessagePlugin } from "tdesign-vue-next";
/* 组件 */
import kanshanVideo from "./kanshanVideo";
import preview from "./preview";
import leftBar from "./leftBar";
/* api */
import { getResourceInfo, getPersonResourceInfo } from "@/api/resources";
/* 变量 */

export default {
  __name: 'index',
  setup: function setup(__props) {
    /* 工具 */
    var allResourceType = [{
      value: 1,
      label: "教案"
    }, {
      value: 2,
      label: "课件"
    }, {
      value: 3,
      label: "习题"
    }, {
      value: 4,
      label: "素材"
    }, {
      value: 5,
      label: "资源包"
    }, {
      value: 6,
      label: "微课"
    }, {
      value: 7,
      label: "试卷"
    }, {
      value: 9,
      label: "学程(电子版)"
    }, {
      value: 11,
      label: "纪录片"
    }, {
      value: 12,
      label: "优质资源"
    }, {
      value: 8,
      label: "其他"
    }];
    var route = useRoute();
    var state = reactive({
      sourceData: {},
      isLoad: false
    });
    /* 方法 */
    var getLibResource = function getLibResource() {
      getResourceInfo(route.query.id).then(function (res) {
        if (res.data.code === 0) {
          state.sourceData = res.data.data || {};
          state.isLoad = true;
        }
      });
    };
    var getPerResource = function getPerResource() {
      getPersonResourceInfo(route.query.id).then(function (res) {
        if (res.data.code === 0) {
          state.sourceData = res.data.data || {};
          state.isLoad = true;
        }
      });
    };
    var downSource = function downSource() {
      var _window = window,
        JSAndroid = _window.JSAndroid;
      if (typeof JSAndroid !== "undefined") {
        var data = {
          title: state.sourceData.name,
          url: state.sourceData.url || state.sourceData.path
        };
        JSAndroid.downLoadSource(JSON.stringify(data));
        MessagePlugin.success("下载任务创建成功");
      } else {
        var link = document.createElement("a");
        link.href = state.sourceData.url || state.sourceData.path;
        link.download = state.sourceData.name;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(function () {
          document.body.removeChild(link);
        }, 0);
      }
    };
    onMounted(function () {
      var _route$query = route.query,
        id = _route$query.id,
        url = _route$query.url;
      if (id) {
        route.query.source === "1" ? getPerResource() : getLibResource();
      } else {
        url && (state.sourceData.url = url);
        state.isLoad = true;
      }
    });
    var getFileType = function getFileType(type) {
      var data = allResourceType.find(function (e) {
        return e.value === Number(type);
      });
      return data === null || data === void 0 ? void 0 : data.label;
    };
    return function (_ctx, _cache) {
      return state.isLoad ? (_openBlock(), _createElementBlock("div", _hoisted_1, [state.sourceData.name ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(state.sourceData.name), 1)])) : _createCommentVNode("", true), state.sourceData && state.sourceData.sourceType === 2 && state.sourceData.formatType.toUpperCase() === 'VS4' ? (_openBlock(), _createBlock(_unref(kanshanVideo), {
        key: 1,
        "video-player-id": state.sourceData.path,
        "video-url": state.sourceData.path,
        "video-poster": state.sourceData.coverPath,
        "ks-resource-id": state.sourceData.sourceId,
        "sub-title": state.sourceData.subtitle
      }, null, 8, ["video-player-id", "video-url", "video-poster", "ks-resource-id", "sub-title"])) : (_openBlock(), _createBlock(_unref(preview), {
        key: 2,
        path: state.sourceData.url
      }, null, 8, ["path"])), state.sourceData.id ? (_openBlock(), _createBlock(_unref(leftBar), {
        key: 3,
        data: state.sourceData
      }, null, 8, ["data"])) : _createCommentVNode("", true), state.sourceData.id ? (_openBlock(), _createElementBlock("div", {
        key: 4,
        class: "down-bar",
        onClick: downSource
      }, _cache[0] || (_cache[0] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
    };
  }
};