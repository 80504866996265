export const calcDate = (date1, date2) => {
  var date3 = date2 - date1

  var days = Math.floor(date3 / (24 * 3600 * 1000))

  var leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))

  var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  var leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(date3 / 1000)
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

/**
 * 日期格式化
 */
export function dateFormat(date, newFormat) {
  let format = newFormat || 'yyyy-MM-dd hh:mm:ss'
  if (date !== 'Invalid Date') {
    var o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      'S': date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  }
  return ''
}

/**
 * 日期格式化为下一个整点
 */
export function dateFormatNext(date, newFormat) {
  let format = newFormat || 'yyyy-MM-dd hh'
  if (date !== 'Invalid Date') {
    var o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours() + 1, // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      'S': date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  }
  return ''
}
/**
 * 日期格式化为距离现在时间
 */
export function backwardsFormatDate(date) {
  const time = new Date(date); // 目标时间
  const nowTime = new Date(); // 当前时间
  const year = time.getFullYear(); // 目标年
  const nowYear = nowTime.getFullYear(); // 当前年
  const month = (time.getMonth() + 1).toString().padStart(2, '0'); // 目标月
  const day = time.getDate().toString().padStart(2, '0'); // 目标日
  const hours = time.getHours().toString().padStart(2, '0'); // 目标时
  const min = time.getMinutes().toString().padStart(2, '0'); // 目标分

  const diff = +time - +nowTime;
  const bool1 = diff < 1000 * 60; // 小于一分钟
  const bool2 = !bool1 && diff < 1000 * 60 * 60; // 小于一小时
  const bool3 = !bool2 && diff < 1000 * 60 * 60 * 24; // 当天
  const bool4 = !bool3 && diff > -1000 * 60 * 60 * 24 && diff < 1000 * 60 * 60 * 24 * 2; // 昨天或明天
  const bool5 = !bool4 && year === nowYear; // 今年

  let res = '';
  if (bool1) {
    res = '1分钟内';
  } else if (bool2) {
    res = `${Math.ceil(diff / 1000 / 60)}分钟后`;
  } else if (bool3) {
    res = `今天${hours}:${min}`;
  } else if (bool4) {
    const yesterday = new Date(nowTime);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(nowTime);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (diff > 0 && diff < 1000 * 60 * 60 * 24) {
      res = `昨天${hours}:${min}`;
    } else if (diff < 0 && diff > -1000 * 60 * 60 * 24) {
      res = `明天${hours}:${min}`;
    }
  } else if (bool5) {
    res = `${month}月${day}日 ${hours}:${min}`;
  } else {
    res = `${year}年${month}月${day}日 ${hours}:${min}`;
  }
  return res;
}

// export function formatRelativeDate(dateString) {
//   const targetDate = new Date(dateString);
//   const now = new Date();
//   const diffInMilliseconds = now - targetDate;
//   const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
//   const diffInMinutes = Math.floor(diffInSeconds / 60);
//   const diffInHours = Math.floor(diffInMinutes / 60);

//   const isSameDay = (date1, date2) => {
//     return date1.getFullYear() === date2.getFullYear() &&
//            date1.getMonth() === date2.getMonth() &&
//            date1.getDate() === date2.getDate();
//   };

//   const isYesterday = (date1, date2) => {
//     const yesterday = new Date(date2);
//     yesterday.setDate(yesterday.getDate() - 1);
//     return isSameDay(date1, yesterday);
//   };

//   if (isYesterday(targetDate, now)) {
//     return '昨天';
//   } else if (isSameDay(targetDate, now)) {
//     return `${targetDate.getHours()}:${targetDate.getMinutes()}`;
//   } else if (targetDate < now) {
//     return `${targetDate.getMonth() + 1}月${targetDate.getDate()}日`;
//   } else {
//     return `${targetDate.getFullYear()}年${targetDate.getMonth() + 1}月${targetDate.getDate()}日`;
//   }
// }
const yearMonthDay = (time) => {
  return `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`
}
export function formatRelativeDate (time) {
  let showTime = ''
  const now = new Date()
  const today = yearMonthDay(now)
  const yesterDay = yearMonthDay(new Date(now.getTime() - 24 * 60 * 60 * 1000))
  const beforeYesterDay = yearMonthDay(new Date(now.getTime() - 24 * 60 * 60 * 1000 * 2))
  const timeArr = time.split(' ')
  if (time.includes(today)) { // 今天显示'时：分'
    showTime = timeArr[1].slice(0, 5)
  } else if (time.includes(yesterDay)) { // 昨天显示'昨天'
    showTime = '昨天'
  } else if (time.includes(beforeYesterDay)) { // 前天显示'前天'
    showTime = '前天'
  } else {
    const newTimeArr = timeArr[0].split('-')
    showTime = `${Number(newTimeArr[1])}月${Number(newTimeArr[2])}日`
  }
  return showTime
}