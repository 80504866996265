/* 组件 */
import courseCard from "@/views/sourcePreview/courseDetail/content/card/courseCard.vue"; // 学程卡
import taskGroupCard from "@/views/sourcePreview/courseDetail/content/card/taskGroupCard.vue"; // 任务群卡
import taskCard from "@/views/sourcePreview/courseDetail/content/card/taskCard.vue"; // 任务卡
import subtaskCard from "@/views/sourcePreview/courseDetail/content/card/subtaskCard.vue"; // 子任务卡
import imageTextCard from "@/views/sourcePreview/courseDetail/content/card/imageTextCard.vue"; // 图文卡
import exerciseCard from "@/views/sourcePreview/courseDetail/content/card/exerciseCard.vue"; // 习题卡
import sourceCard from "@/views/sourcePreview/courseDetail/content/card/sourceCard.vue"; // 资源卡
import submitCard from "@/views/sourcePreview/courseDetail/content/card/submitCard.vue"; // 提交卡
import interactCard from "@/views/sourcePreview/courseDetail/content/card/interactCard.vue"; // 提交卡
import gaugeCard from "@/views/sourcePreview/courseDetail/content/card/gaugeCard.vue"; // 量表卡
import tipCard from "@/views/sourcePreview/courseDetail/content/widgets/tipCard.vue"; // 量表卡
/* 工具 */
import { reactive, markRaw } from 'vue'

export default function() {
  const components = reactive([
    { name: 'courseCard', component: markRaw(courseCard) },
    { name: 'taskGroupCard', component: markRaw(taskGroupCard) },
    { name: 'taskCard', component: markRaw(taskCard) },
    { name: 'subtaskCard', component: markRaw(subtaskCard) },
    { name: 'imageTextCard', component: markRaw(imageTextCard) },
    { name: 'exerciseCard', component: markRaw(exerciseCard) },
    { name: 'sourceCard', component: markRaw(sourceCard) },
    { name: 'submitCard', component: markRaw(submitCard) },
    { name: 'interactCard', component: markRaw(interactCard) },
    { name: 'gaugeCard', component: markRaw(gaugeCard) },
    { name: 'tipCard', component: markRaw(tipCard) },
  ])

  const getComponent = (name) => {
    return components.find(e => e.name === name)?.component
  }

  return getComponent
}
