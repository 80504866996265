import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "children-node-info"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "children-node-text"
};
var _hoisted_4 = ["title"];
var _hoisted_5 = {
  class: "summary"
};
import { ref, toRefs } from "vue";
import { escape2Html, formatBytes } from '@/util/util';
import openVipDialog from "@/components/openVip";
export default {
  __name: 'lessonMenuItem',
  props: {
    ascription: {
      //课程归属。（1：公共课程；2：个人课程）
      type: Number,
      default: 1
    },
    isRestricted: {
      //是否受限。  0：不受限 1：受限 //全局
      type: Number,
      default: 1
    },
    info: {
      // 是否受限isShow 。  0：不受限 1：受限 //单个
      type: Object,
      default: function _default() {}
    },
    accountType: {
      //
      type: String,
      default: ''
    }
  },
  emits: ['preview'],
  setup: function setup(__props, _ref) {
    var _info$value, _info$value2, _info$value3;
    var __emit = _ref.emit;
    var resourceType = ref('course');
    var itemData = ref({});
    var Vipvisible = ref(false);
    var props = __props;
    var _toRefs = toRefs(props),
      info = _toRefs.info;
    var emit = __emit; //数组里面可以传递多个
    var type = ref(['', '单元', '学程', '素材', '习练']);
    var canShowResource = ref(info.value.itemType === 3 && (((_info$value = info.value) === null || _info$value === void 0 ? void 0 : _info$value.isVipResource) && ((_info$value2 = info.value) === null || _info$value2 === void 0 ? void 0 : _info$value2.hasVipRole) || !((_info$value3 = info.value) !== null && _info$value3 !== void 0 && _info$value3.isVipResource) || props.accountType !== '2'));
    var handlerPreview = function handlerPreview() {
      var fullPermission = props.isRestricted === 0 ? true : false; //完整权限
      emit('preview', {
        item: info.value,
        fullPermission: fullPermission
      });
    };
    var mapType = {
      2: 'course',
      3: 'material',
      4: 'exercise'
    };
    var openVip = function openVip(item) {
      resourceType.value = mapType[item.itemType];
      if (resourceType.value === 'exercise') {
        itemData.value = Object.assign(item, item.catalog ? item.catalog[0] : {});
      } else {
        itemData.value = item;
      }
      Vipvisible.value = true;
    };
    var hClose = function hClose() {
      Vipvisible.value = false;
    };
    return function (_ctx, _cache) {
      var _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
        class: _normalizeClass(["label", ['type-' + _unref(info).itemType]])
      }, _toDisplayString(type.value[_unref(info).itemType]), 3), _unref(info).isVipResource && __props.accountType === '2' ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: require('@/assets/vipIcon.svg'),
        style: {
          "margin-right": "8px"
        }
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(info).name ? _unref(escape2Html)(_unref(info).name) : '暂无标题'), 1), _createElementVNode("div", {
        class: "tip-box permission",
        title: _unref(info).name
      }, [_createElementVNode("div", _hoisted_5, [_unref(info).resourceAttr ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_unref(info).itemType === 2 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode("本学程包含 " + _toDisplayString(_unref(info).resourceCount || 0) + " 素材 | " + _toDisplayString(_unref(info).exerciseCount || 0) + " 习题", 1)], 64)) : _unref(info).itemType === 3 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode("素材类型：" + _toDisplayString(_unref(info).resourceType) + " 素材大小：" + _toDisplayString(_unref(formatBytes)(_unref(info).resourceSize)), 1)], 64)) : _unref(info).itemType === 4 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createTextVNode("本习练包含 习题：" + _toDisplayString(_unref(info).exerciseCount || 0), 1)], 64)) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(" 暂无属性 ")], 64))]), canShowResource.value || _unref(info).itemType !== 3 ? (_openBlock(), _createBlock(_component_t_button, {
        key: 0,
        type: "primary",
        class: "preview",
        onClick: handlerPreview
      }, {
        default: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createTextVNode("预览")]);
        }),
        _: 1
      })) : _createCommentVNode("", true), _unref(info).isVipResource && !_unref(info).hasVipRole && __props.accountType === '2' ? (_openBlock(), _createBlock(_component_t_button, {
        key: 1,
        class: "open-vip",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return openVip(_unref(info));
        })
      }, {
        default: _withCtx(function () {
          return _cache[2] || (_cache[2] = [_createTextVNode("开通会员")]);
        }),
        _: 1
      })) : _createCommentVNode("", true)], 8, _hoisted_4), Vipvisible.value ? (_openBlock(), _createBlock(_unref(openVipDialog), {
        key: 1,
        dialogVisible: Vipvisible.value,
        itemData: itemData.value,
        type: _unref(resourceType),
        onHClose: hClose
      }, null, 8, ["dialogVisible", "itemData", "type"])) : _createCommentVNode("", true)]);
    };
  }
};