import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../icons/exerciseCard.png';
import _imports_1 from '../icons/down.png';
import _imports_2 from '../icons/up.png';
var _hoisted_1 = {
  class: "exercise-card card media-card"
};
var _hoisted_2 = {
  class: "padding card-content"
};
var _hoisted_3 = {
  class: "media-card-serial"
};
var _hoisted_4 = {
  key: 0,
  class: "media-card-title"
};
var _hoisted_5 = {
  class: "card-title"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = {
  class: "exercise-content"
};
var _hoisted_8 = {
  class: "title"
};
var _hoisted_9 = {
  class: "type"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  key: 0,
  class: "chioce-list"
};
var _hoisted_12 = ["innerHTML"];
var _hoisted_13 = {
  key: 1,
  class: "comprehensive-titles"
};
var _hoisted_14 = {
  class: "subtopic-title"
};
var _hoisted_15 = ["innerHTML"];
var _hoisted_16 = {
  key: 0,
  class: "chioce-list"
};
var _hoisted_17 = ["innerHTML"];
var _hoisted_18 = {
  src: _imports_1
};
var _hoisted_19 = {
  src: _imports_2
};
var _hoisted_20 = {
  class: "answer-analysis answer-content card-content"
};
var _hoisted_21 = {
  class: "answer-box"
};
var _hoisted_22 = {
  key: 0
};
var _hoisted_23 = ["innerHTML"];
var _hoisted_24 = ["innerHTML"];
var _hoisted_25 = {
  class: "analysis-box"
};
var _hoisted_26 = ["innerHTML"];
import { ref, reactive, computed } from "vue";
import { num2Letter, formatChoiceOption } from "@/views/sourcePreview/courseDetail/const/utils";
/* 变量 */

export default {
  __name: 'exerciseCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var isShowAnswer = ref(false);
    var exerciseType = reactive({
      SINGLE_CHOICE: "单选",
      MULTIPLE_CHOICE: "多选",
      COMPLETION: "填空",
      ANSWER_QUESTIONS: "简答",
      COMPREHENSIVE: "综合"
    });

    /* 计算属性 */
    var desc = computed(function () {
      return __props.cardData.data.attribute.desc || "";
    });
    var exerciseData = computed(function () {
      return JSON.parse(__props.cardData.data.item) ? JSON.parse(__props.cardData.data.item).content : {};
    });
    var isChoice = function isChoice(type) {
      return type === "SINGLE_CHOICE" || type === "MULTIPLE_CHOICE";
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(__props.cardData.sort), 1), __props.cardData.data.title && __props.cardData.data.title !== '习题卡' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(__props.cardData.data.title), 1)])) : _createCommentVNode("", true), desc.value ? (_openBlock(), _createElementBlock("span", {
        key: 1,
        innerHTML: desc.value,
        class: "media-card-desc"
      }, null, 8, _hoisted_6)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(exerciseType[exerciseData.value.type]), 1), _createElementVNode("span", {
        innerHTML: exerciseData.value.title
      }, null, 8, _hoisted_10)]), isChoice(exerciseData.value.type) ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exerciseData.value.answer, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "chioce-item"
        }, [_createElementVNode("span", null, _toDisplayString(_unref(num2Letter)(index + 1)), 1), _createElementVNode("span", {
          innerHTML: _unref(formatChoiceOption)(item.title || '')
        }, null, 8, _hoisted_12)]);
      }), 128))])) : _createCommentVNode("", true), exerciseData.value.type === 'COMPREHENSIVE' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exerciseData.value.subExerciseList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("div", _hoisted_14, [_createElementVNode("span", null, "【小题" + _toDisplayString(index + 1) + "】", 1), _createElementVNode("div", {
          innerHTML: item.title
        }, null, 8, _hoisted_15)]), isChoice(item.type) ? (_openBlock(), _createElementBlock("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.answer, function (e, i) {
          return _openBlock(), _createElementBlock("div", {
            key: i,
            class: "chioce-item"
          }, [_createElementVNode("span", null, _toDisplayString(_unref(num2Letter)(i + 1)), 1), _createElementVNode("span", {
            innerHTML: _unref(formatChoiceOption)(e.title || '')
          }, null, 8, _hoisted_17)]);
        }), 128))])) : _createCommentVNode("", true)]);
      }), 128))])) : _createCommentVNode("", true)])]), _createElementVNode("div", {
        class: "is-show-answer answer-content",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return isShowAnswer.value = !isShowAnswer.value;
        })
      }, [_createElementVNode("span", null, _toDisplayString(isShowAnswer.value ? '收起' : '查看') + "答案与解析", 1), _withDirectives(_createElementVNode("img", _hoisted_18, null, 512), [[_vShow, !isShowAnswer.value]]), _withDirectives(_createElementVNode("img", _hoisted_19, null, 512), [[_vShow, isShowAnswer.value]])]), _withDirectives(_createElementVNode("div", _hoisted_20, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_21, [_cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "tag"
      }, "答", -1)), exerciseData.value.type === 'COMPREHENSIVE' ? (_openBlock(), _createElementBlock("div", _hoisted_22, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exerciseData.value.subExerciseList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "comprehensive-answer"
        }, [_createElementVNode("span", null, "【小题" + _toDisplayString(index + 1) + "】", 1), _createElementVNode("span", {
          innerHTML: item.correctAnswer
        }, null, 8, _hoisted_23)]);
      }), 128))])) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        innerHTML: exerciseData.value.correctAnswer
      }, null, 8, _hoisted_24))]), _createElementVNode("div", _hoisted_25, [_cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "tag"
      }, "解", -1)), _createElementVNode("span", {
        innerHTML: exerciseData.value.analysis
      }, null, 8, _hoisted_26)])])], 512), [[_vShow, isShowAnswer.value]])]);
    };
  }
};