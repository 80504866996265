import { request } from "hxhy-axios"
import { apiList as api } from "./apiList"

// 教师空间基础信息
export function teacherSpace(id) {
  return request({
    url: api.homepage.teacherSpace + id,
    method: "get",
  })
}

// 相册分页数据
export function photos(params) {
  return request({
    url: api.homepage.photos,
    method: "get",
    params,
  })
}

// 上一页下一页
export function switchPage(params) {
  return request({
    url: api.homepage.switch,
    method: "get",
    params,
  })
}

// 教学数据分页
export function teach(params) {
  return request({
    url: api.homepage.teach,
    method: "get",
    params,
  })
}

// 创作数据分页
export function create(params) {
  return request({
    url: api.homepage.create,
    method: "get",
    params,
  })
}

// 活动数据分页
export function events(params) {
  return request({
    url: api.homepage.events,
    method: "get",
    params,
  })
}

// 笔记分页
export function notePaging(query,params) {
  const { userId, viewRadius } = query
  return request({
    url: api.homepage.notePaging+ userId + '/' + viewRadius,
    method: "get",
    params,
  })
}

// 空间搜索
export function Spatial(spaceAccount) {
  return request({
    url: api.homepage.Spatial + spaceAccount,
    method: "get",
  })
}

// 获取最近访客列表
export function guestList(id) {
  return request({
    url: api.homepage.guestList + id,
    method: "get",
  })
}

// 增加一次访问记录
export function interview(id,params) {
  return request({
    url: api.homepage.interview + id,
    method: "post",
    params
  })
}

// 设置背景图
export function setBackground(userId,backImageId) {
  return request({
    url: api.homepage.setBackground + userId + '/' + backImageId,
    method: "post",
  })
}

// 获取背景图列表
export function background(params) {
  return request({
    url: api.homepage.background,
    method: "get",
    params
  })
}

// 设置展示范围
export function scopeOfDisplay(userId,radius) {
  return request({
    url: api.homepage.scopeOfDisplay + userId + '/' + radius,
    method: "post",
  })
}

// 随便逛逛
export function casual(query) {
  return request({
    url: api.homepage.casual ,
    method: "get",
    params: query
  })
}

// 点赞
export function giveLike(dynamicId,dynamicContentType,opType) {
  return request({
    url: api.homepage.giveLike + dynamicId + '/' + dynamicContentType + '/' + opType,
    method: "post",
  })
}

// 个人名片
export function personalCard(id) {
  return request({
    url: api.homepage.cardInfo + id,
    method: "get",
  })
}