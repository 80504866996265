import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import openVipDialog from "@/components/openVip";
import { ref, watch } from "vue";
export default {
  __name: 'tipCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    var itemData = ref({});
    var visible = ref(false);
    var hClose = function hClose() {
      visible.value = false;
    };
    var openVip = function openVip() {
      itemData.value = __props.cardData.data;
      visible.value = true;
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
        class: "tip"
      }, [_cache[1] || (_cache[1] = _createTextVNode("已预览50%，")), _createElementVNode("span", {
        onClick: openVip
      }, _cache[0] || (_cache[0] = [_createElementVNode("a", {
        href: "JavaScript:;"
      }, "开通会员", -1)])), _cache[2] || (_cache[2] = _createTextVNode(" 查看全部内容"))]), visible.value ? (_openBlock(), _createBlock(_unref(openVipDialog), {
        key: 0,
        dialogVisible: visible.value,
        itemData: itemData.value,
        onHClose: hClose
      }, null, 8, ["dialogVisible", "itemData"])) : _createCommentVNode("", true)]);
    };
  }
};