import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "position"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "icon"
};
import { ref, onMounted, onUnmounted, computed } from "vue";
import WujieVue from "wujie-vue3";
export default {
  __name: 'index',
  props: {
    slideShow: {
      type: Boolean,
      default: false
    },
    courseData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var bus = WujieVue.bus;
    /* 变量 */
    var isShow = ref(false);
    var structureList = ref([]);

    /* 计算属性 */
    var structureFilterList = computed(function () {
      return structureList.value.filter(function (e) {
        return e.type <= 3;
      });
    });
    /* 方法 */
    onMounted(function () {
      bus.$on('course-content-scroll', function (top) {
        isShow.value = top > 4;
      });
      bus.$on("course-current-structure", function (structure) {
        structureList.value = structure;
      });
    });
    onUnmounted(function () {
      bus.$off("course-current-structure");
    });
    var textFormat = function textFormat(txt, index) {
      var res = txt.length > 10 ? "".concat(txt.substr(0, 10), "...") : txt;
      return index === structureFilterList.value.length - 1 ? txt : res;
    };
    var scrollToCard = function scrollToCard(id) {
      bus.$emit("course-content-scroll-to", id);
    };
    var backTopClick = function backTopClick() {
      var courseId = __props.courseData.id;
      document.querySelector("#course-card-".concat(courseId)).scrollIntoView();
    };
    return function (_ctx, _cache) {
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      var _component_t_icon = _resolveComponent("t-icon");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["position-bar", {
          'half-width': __props.slideShow
        }])
      }, [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "当前位置：", -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(structureFilterList.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "structure"
        }, [_createVNode(_component_t_tooltip, {
          content: item.title,
          placement: "top"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", {
              class: "title",
              onClick: function onClick($event) {
                return scrollToCard(item.id);
              }
            }, _toDisplayString(item.sort) + " - " + _toDisplayString(textFormat(item.title, index)), 9, _hoisted_2)];
          }),
          _: 2
        }, 1032, ["content"]), index !== structureFilterList.value.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_3, "/")) : _createCommentVNode("", true)]);
      }), 128))]), _createElementVNode("div", {
        class: "back-top",
        onClick: backTopClick
      }, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "返回顶部", -1)), _createVNode(_component_t_icon, {
        name: "backtop",
        class: "icon"
      })])], 2)), [[_vShow, structureFilterList.value.length && isShow.value]]);
    };
  }
};