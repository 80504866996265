import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "sort-content"
};
var _hoisted_2 = {
  key: 0,
  class: "loading-div"
};
var _hoisted_3 = {
  class: "left-div"
};
var _hoisted_4 = {
  class: "img-div"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "item-name"
};
var _hoisted_7 = {
  class: "right-div"
};
var _hoisted_8 = ["src", "onClick"];
var _hoisted_9 = ["src", "onClick"];
import { ref, toRefs, watch } from "vue";
import { getAppSort, sortApp } from '@/api/home.js';
import downDisabled from '@/assets/icons/down-disabled.svg';
import down from '@/assets/icons/down.svg';
import upDisabled from '@/assets/icons/up-disabled.svg';
import up from '@/assets/icons/up.svg';
import { MessagePlugin as message } from 'tdesign-vue-next';
export default {
  __name: 'sortDialog',
  props: /*@__PURE__*/_mergeModels({
    tabType: {
      type: String,
      default: ''
    }
  }, {
    "sortVisible": false,
    "sortVisibleModifiers": {}
  }),
  emits: /*@__PURE__*/_mergeModels('refreshAppList', ["update:sortVisible"]),
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var _toRefs = toRefs(props),
      tabType = _toRefs.tabType;
    var emit = __emit;
    var visible = _useModel(__props, 'sortVisible', false);

    /** 初始化相关 */
    var appDatas = ref([]);
    var getApp = function getApp() {
      getAppSort(tabType.value).then(function (res) {
        if (res.data.code === 0) {
          appDatas.value = res.data.data || [];
        }
      });
    };
    watch(visible, function (nval) {
      if (nval) {
        getApp();
      }
    });

    /** 事件相关 */
    /**
     * 降序
     */
    var handleDown = function handleDown(item, index, isDisabled) {
      if (isDisabled) return;
      appDatas.value.splice(index, 1);
      appDatas.value.splice(index + 1, 0, item);
    };

    /**
     * 升序
     */
    var handleUp = function handleUp(item, index, isDisabled) {
      if (isDisabled) return;
      appDatas.value.splice(index, 1);
      appDatas.value.splice(index - 1, 0, item);
    };

    /**
     * 确认
     */
    var handleSure = function handleSure() {
      var data = appDatas.value.map(function (i, index) {
        return {
          appId: i.appId,
          sort: index + 1
        };
      });
      sortApp(tabType.value, data).then(function (res) {
        if (res.data.code === 0) {
          emit('refreshAppList');
          message.success('调整成功');
          visible.value = false;
        }
      });
    };
    return function (_ctx, _cache) {
      var _component_t_loading = _resolveComponent("t-loading");
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        visible: visible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return visible.value = $event;
        }),
        header: "应用顺序调整",
        width: "432",
        destroyOnClose: true,
        class: "sort-dialog",
        onConfirm: handleSure
      }, {
        body: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [!appDatas.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_t_loading, {
            size: "small"
          })])) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(appDatas.value, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              class: "item-sort"
            }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              class: "img-icon"
            }, null, 8, _hoisted_5)]), _createElementVNode("span", _hoisted_6, _toDisplayString(item.appName), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
              src: index + 1 === appDatas.value.length ? _unref(downDisabled) : _unref(down),
              alt: "",
              class: _normalizeClass({
                'sort-icon': true,
                'disabled-icon': index + 1 === appDatas.value.length
              }),
              onClick: function onClick($event) {
                return handleDown(item, index, index + 1 === appDatas.value.length);
              }
            }, null, 10, _hoisted_8), _createElementVNode("img", {
              src: index === 0 ? _unref(upDisabled) : _unref(up),
              alt: "",
              class: _normalizeClass({
                'sort-icon': true,
                'disabled-icon': index === 0
              }),
              onClick: function onClick($event) {
                return handleUp(item, index, index === 0);
              }
            }, null, 10, _hoisted_9)])]);
          }), 128))])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};