import { renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/icons/arrow-right.svg';
var _hoisted_1 = {
  class: "module-title"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "title-left"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "title-right"
};
var _hoisted_6 = {
  class: "bottom"
};
import { toRefs } from 'vue';
export default {
  __name: 'module',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['title-click'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var applicationSchedule = require('@/assets/application/schedule.svg'); // 日程
    /* 变量 */
    var props = __props;
    var emits = __emit;
    var _toRefs = toRefs(props),
      title = _toRefs.title;
    var handleToMore = function handleToMore() {
      emits('title-click');
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, function () {
        return [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          class: "title-img",
          src: _unref(applicationSchedule),
          alt: ""
        }, null, 8, _hoisted_4), _cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "title-text"
        }, "日程和待办", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(title)), 1)])];
      }), _createElementVNode("img", {
        class: "arrow-right",
        onClick: handleToMore,
        src: _imports_0,
        alt: ""
      })]), _createElementVNode("div", _hoisted_6, [_renderSlot(_ctx.$slots, "default")])]);
    };
  }
};