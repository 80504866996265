import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "copyright"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "header"
};
import { ref } from "vue";

/* 变量 */

export default {
  __name: 'copyright',
  props: {
    visable: {
      type: Boolean,
      default: false
    }
  },
  emits: "update:visable",
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */

    var emits = __emit;

    /* 方法 */
    var hide = function hide() {
      emits("update:visable", false);
    };
    return function (_ctx, _cache) {
      var _component_t_icon = _resolveComponent("t-icon");
      return __props.visable ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "title"
      }, "权利声明", -1)), _createVNode(_component_t_icon, {
        name: "close",
        class: "close",
        onClick: hide
      })]), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "txt"
      }, [_createElementVNode("p", null, "本平台上的所有资源均系使用本平台者上传或发布，上传或发布资源者须确保所有作品系其原创或者已征得权利人书面许可或授权转发或使用，如侵犯第三人权利，本公司有权直接删除而不承担任何责任。"), _createElementVNode("p", null, "本平台上的所有作品未经权利人书面许可或授权不得转发或使用，否则导致侵犯本公司或他人权利的，由行为人承担法律责任。"), _createElementVNode("p", null, "如您认为平台所载资源侵犯您的有关权利，可以联系本公司协助处理，但本平台不因此承担任何责任，相关法律责任由行为人承担。本声明长期有效，欢迎您浏览本平台信息。"), _createElementVNode("p", {
        class: "contact-way"
      }, [_createElementVNode("span", null, "本公司联系方式：李得祺"), _createElementVNode("span", null, "电话：15563859280"), _createElementVNode("span", null, "邮箱：zhixuehuijiao@163.com")]), _createElementVNode("p", null, "祝您身体康健、工作/生活愉快！")], -1))]), _createElementVNode("div", {
        class: "mask",
        onClick: hide
      })])) : _createCommentVNode("", true);
    };
  }
};