import request from "@/api/axios";
import { apiList as api } from "./apiList";
/**
 * 资源筛选字典
 * @param {*} code
 * @returns
 */
export function getDictItem(code) {
  return request({
    url: api.excersise.getDictItem + code,
    method: "get"
  });
}
/**
 * 获取教材
 * @param {*} data
 * @returns
 */
export function getTutorial(data) { 
  return request({
    url: api.excersise.getTutorial,
    method: "post",
    data
  });
}
/**
 * 获取单元
 * @param {*} code
 * @returns
 */
export function getDirByTutorial(code) { 
  return request({
    url: api.excersise.getDirByTutorial + code,
    method: "get"
  });
}
