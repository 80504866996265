import "core-js/modules/es.function.name.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "source-detail"
};
var _hoisted_2 = {
  class: "info"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  class: "detail-content-text"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  class: "tags-box"
};
var _hoisted_7 = {
  key: 0,
  class: "tags-box-item"
};
var _hoisted_8 = {
  class: "tags-box-item"
};
var _hoisted_9 = {
  class: "tags-box-item"
};
import sourceCover from "./sourceCover";
/* 工具 */

import { formatBytes, getFileName } from "@/views/sourcePreview/courseDetail/const/utils";
/* 变量 */

export default {
  __name: 'sourceDetail',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:visible", "preview"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 组件 */
    var emit = __emit;

    /* 方法 */
    var hide = function hide() {
      emit("update:visible", false);
    };
    var ok = function ok() {
      emit("update:visible", false);
      emit("preview");
    };
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        visible: __props.visible,
        closeOnOverlayClick: false,
        cancelBtn: "关闭",
        confirmBtn: "查看资源",
        width: "754px",
        onCancel: hide,
        onConfirm: ok,
        onClose: hide
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_unref(sourceCover), {
            data: __props.sourceData,
            width: "140px"
          }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h4", _hoisted_3, _toDisplayString(__props.sourceData.name), 1), _createElementVNode("div", _hoisted_4, [_cache[0] || (_cache[0] = _createTextVNode(" 培养品质： ")), __props.sourceData.indexCultivateQuality && __props.sourceData.indexCultivateQuality.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(__props.sourceData.indexCultivateQuality, function (itm, index) {
            return _openBlock(), _createElementBlock("span", {
              key: itm.id
            }, [_createTextVNode(_toDisplayString(itm.quality) + " ", 1), index + 1 !== __props.sourceData.indexCultivateQuality.length ? (_openBlock(), _createElementBlock("span", _hoisted_5, "、")) : _createCommentVNode("", true)]);
          }), 128)) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createTextVNode("暂无")], 64))]), __props.sourceData.indexKnowledgeList && __props.sourceData.indexKnowledgeList.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(__props.sourceData.indexKnowledgeList, function (it, index) {
            return _openBlock(), _createElementBlock("p", {
              key: it.id,
              class: "detail-content-text"
            }, "知识点" + _toDisplayString(index + 1) + "：" + _toDisplayString(it.knowledgeName) + "(" + _toDisplayString(it.cognitiveGoal) + "," + _toDisplayString(it.subjectAccomplishment) + ")", 1);
          }), 128)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [__props.sourceData.indexCultivateQuality && __props.sourceData.indexCultivateQuality.length > 0 && __props.sourceData.indexKnowledgeList && __props.sourceData.indexKnowledgeList.length > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(__props.sourceData.resLevel || 'T') + " 级", 1)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(getFileName)(__props.sourceData.type)), 1), _createElementVNode("span", _hoisted_9, "大小：" + _toDisplayString(_unref(formatBytes)(__props.sourceData.size)), 1)])])])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};