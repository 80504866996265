import { unref as _unref, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "base-dialog"
};
var _hoisted_2 = {
  class: "dialog-body"
};
var _hoisted_3 = {
  key: 1,
  class: "dialog-header"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "dialog-footer"
};
import { watch } from "vue";
import { useModel } from "@/hooks/useModel";
export default {
  __name: 'BaseDialog',
  props: {
    title: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:visible", "close"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var visible = useModel({
      props: props,
      emit: emit,
      key: "visible"
    });

    // 监听外部props变化
    watch(function () {
      return props.visible;
    }, function (val) {
      visible.value = val;
    }, {
      immediate: true,
      deep: true
    });
    var handleClose = function handleClose() {
      visible.value = false;
      emit("update:visible", false);
      emit("close");
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_withDirectives(_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "dialog-bg",
        onClick: handleClose
      }), _createElementVNode("div", _hoisted_2, [_ctx.$slots.right ? _renderSlot(_ctx.$slots, "header", {
        key: 0
      }) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(__props.title), 1), _createElementVNode("img", {
        src: require('@/assets/home/closeBtn.svg'),
        onClick: handleClose,
        alt: ""
      }, null, 8, _hoisted_4)])), _renderSlot(_ctx.$slots, "body"), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "footer")])])], 512), [[_vShow, _unref(visible)]])]);
    };
  }
};