import "core-js/modules/es.array.find.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "course-content"
};
var _hoisted_2 = ["id", "data-id"];
import './common.scss';
/* 工具 */
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import WujieVue from "wujie-vue3";
import { flatCourseTree } from '@/views/sourcePreview/courseDetail/const/utils';
/* hooks */
import useCardComponents from '@/views/sourcePreview/courseDetail/hooks/content/useCardComponents'; // 卡片组件

/* 变量 */

export default {
  __name: 'index',
  props: {
    courseData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['scroll-to'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 样式 */
    var route = useRoute();
    var bus = WujieVue.bus;
    var props = __props;
    var emit = __emit;
    var isVip = route.query.isVip;
    var courseData = reactive(flatCourseTree(props.courseData, isVip)); // 学程数据
    var getComponent = useCardComponents(); // 获取学程卡片
    var courseCards = ref();

    /* 方法 */
    onMounted(function () {
      bus.$on('course-content-scroll-to', function (id) {
        document.querySelector("#course-card-".concat(id)).scrollIntoView({
          block: 'center'
        });
      });
      bus.$on('course-content-scroll', function (scrollTop) {
        var clientHeight = document.documentElement.clientHeight;
        var halfHeight = parseInt(clientHeight / 2);
        var cardDom = courseCards.value.find(function (card) {
          var top = card.offsetTop - halfHeight;
          var bottom = card.offsetTop - halfHeight + card.offsetHeight;
          return top <= scrollTop && scrollTop < bottom;
        });
        if (cardDom) {
          var cardId = Number(cardDom.dataset.id);
          var structure = [];
          if (cardId !== props.courseData.id) {
            var findItem = courseData.find(function (e) {
              return Number(e.id) === cardId;
            });
            findItem && (structure = findItem.data.structure);
          }
          bus.$emit('course-current-structure', structure);
        }
      });
    });
    onUnmounted(function () {
      bus.$off('course-content-scroll-to');
      bus.$off('course-content-scroll');
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(courseData, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          ref_for: true,
          ref_key: "courseCards",
          ref: courseCards,
          id: "course-card-".concat(item.id),
          key: index,
          "data-id": item.id
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getComponent)(item.component)), {
          "card-data": item
        }, null, 8, ["card-data"]))], 8, _hoisted_2);
      }), 128))]);
    };
  }
};