import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
export default {
  __name: 'Introduce',
  props: {
    dataObj: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        innerHTML: __props.dataObj.summary
      }, null, 8, _hoisted_1);
    };
  }
};