import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "tree-node"
};
import { reactive } from "vue";
import WujieVue from "wujie-vue3";
import cardMap from "@/views/sourcePreview/courseDetail/const/cardmap";
/* 变量 */

export default {
  __name: 'index',
  props: {
    courseData: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["show-change"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var bus = WujieVue.bus;
    var props = __props;
    var emit = __emit;
    var courseData = reactive(props.courseData);
    var state = reactive({
      isShow: false,
      isOnlyStructure: false,
      defExpandKeys: [],
      treeData: []
    });
    /* 方法 */
    // 处理默认展开节点
    var _formatExpand = function formatExpand(obj, list) {
      if (obj.children) {
        if (obj.children.some(function (e) {
          return e.itemType <= 3;
        })) list.push(obj.id);
        obj.children.forEach(function (e) {
          _formatExpand(e, list);
        });
      }
    };
    _formatExpand(courseData, state.defExpandKeys);
    // 处理无标题卡片
    var format = function format(type) {
      return cardMap[type].name;
    };
    // 处理树形数据
    var _formatArr = function formatArr(list, parentIndex) {
      var arr = JSON.parse(JSON.stringify(list));
      arr.forEach(function (item, index) {
        // 表格卡类型值 已废弃 处理一下
        item.itemType === 6 && (item.itemType = 4);
        // 处理排序
        item.cardSort = parentIndex ? "".concat(parentIndex, ".").concat(index + 1) : "".concat(index + 1);
        // 处理子节点
        if (!item.children || item.children.length === 0) {
          delete item.children;
        } else {
          item.children = _formatArr(item.children, item.cardSort);
        }
        // 处理标题
        !item.title && (item.title = cardMap[item.itemType].name);
        // 处理icon (item.itemType > 3 就是卡片)
        item.isCard = item.itemType > 3;
      });
      return arr;
    };
    state.treeData = _formatArr(courseData.children);
    // 仅显示结构
    var structureChange = function structureChange() {
      var _fn = function fn(list) {
        list.forEach(function (e) {
          e.children && (e.children = _fn(e.children));
        });
        return list.filter(function (e) {
          return e.itemType <= 3;
        });
      };
      state.treeData = state.isOnlyStructure ? _fn(state.treeData) : _formatArr(courseData.children);
    };
    // 树形选中 value, { node }
    var treeSelect = function treeSelect(value, _ref2) {
      var node = _ref2.node;
      bus.$emit("course-content-scroll-to", node.data.id);
    };
    // 展开收起
    var showChange = function showChange() {
      state.isShow = !state.isShow;
      emit("show-change", state.isShow);
    };
    return function (_ctx, _cache) {
      var _component_t_checkbox = _resolveComponent("t-checkbox");
      var _component_t_icon = _resolveComponent("t-icon");
      var _component_t_tree = _resolveComponent("t-tree");
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["slide-bar", state.isShow ? 'slide-show' : 'slide-hidden'])
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("span", null, "学程结构", -1)), _createVNode(_component_t_checkbox, {
        modelValue: state.isOnlyStructure,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return state.isOnlyStructure = $event;
        }),
        onChange: structureChange
      }, {
        default: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createTextVNode("仅显示结构")]);
        }),
        _: 1
      }, 8, ["modelValue"])]), courseData.children.length ? (_openBlock(), _createBlock(_component_t_tree, {
        key: 0,
        data: state.treeData,
        keys: {
          children: 'children',
          label: 'title',
          value: 'id'
        },
        onActive: treeSelect
      }, {
        label: _withCtx(function (_ref3) {
          var node = _ref3.node;
          return [_createElementVNode("div", _hoisted_3, [Number(node.data.itemType) <= 3 ? (_openBlock(), _createBlock(_component_t_icon, {
            key: 0,
            name: "folder",
            class: "tree-file-icon"
          })) : (_openBlock(), _createBlock(_component_t_icon, {
            key: 1,
            name: "file",
            class: "tree-file-icon"
          })), _createElementVNode("span", {
            class: _normalizeClass({
              'font-bold': Number(node.data.itemType) === 1
            })
          }, _toDisplayString(node.data.cardSort) + " - " + _toDisplayString(node.label || format(node.data.itemType)), 3)])];
        }),
        _: 1
      }, 8, ["data"])) : _createCommentVNode("", true)]), _createElementVNode("div", {
        class: _normalizeClass(["swith-btn", state.isShow ? 'swith-open' : 'swith-close']),
        onClick: showChange
      }, [_createVNode(_component_t_tooltip, {
        content: state.isShow ? '收起' : '展开',
        placement: "top-left"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_t_icon, {
            name: "chevron-right",
            class: "icon"
          })];
        }),
        _: 1
      }, 8, ["content"])], 2)], 2);
    };
  }
};