import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '../icons/sourceCard.png';
var _hoisted_1 = {
  class: "source-card card media-card"
};
var _hoisted_2 = {
  class: "media-card-serial"
};
var _hoisted_3 = {
  key: 0,
  class: "media-card-title"
};
var _hoisted_4 = {
  class: "card-title"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 2,
  class: "source-list"
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  key: 0
};
import sourceCover from '../widgets/sourceCover';
import sourceDetail from '../widgets/sourceDetail';
// import resourcePreview from "@/views/sourcePreview/resourcePreview";
import FilePreviewDialog from 'hxhy-file-preview';
/* 工具 */
import { ref, reactive, computed } from "vue";
import { getStore } from "@/util/store";
/* 变量 */

export default {
  __name: 'sourceCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 组件 */
    var state = reactive({
      sourceDetailShow: false,
      // 资源详情
      resourcePreviewShow: false,
      // 资源预览
      sourceData: {}
    });

    /* 计算属性 */
    var desc = computed(function () {
      return __props.cardData.data.attribute.desc || "";
    });
    var sourceList = computed(function () {
      return JSON.parse(__props.cardData.data.item) ? JSON.parse(__props.cardData.data.item).content : {};
    });
    var filePath = ref(''); // 新素材预览url地址
    var token = ref(getStore({
      name: "access_token"
    }));
    var sourceId = ref(''); // 资源id
    var sourceName = ref(''); // 新素材预览资源名称
    /* 方法 */
    var sourceItemClick = function sourceItemClick(item) {
      state.sourceData = item;
      state.sourceDetailShow = true;
      filePath.value = item.url;
      if (!filePath.value) sourceId.value = item.id;
      sourceName.value = item.sourceName || '';
    };
    var preview = function preview() {
      state.resourcePreviewShow = true;
    };
    // const computeRatio = (item) => {
    //   return new Promise((resolve, reject) => {
    //     const img = new Image();
    //     let size = {};
    //     img.src = isImage(item) ? item.url : item.posterUrl || defaultVideoPoster;
    //     img.onload = () => {
    //       const maxWidth = this.maxWidth;
    //       const maxHeight = this.maxHeight;
    //       const width = Math.max(parseInt(img.width, 10), parseInt(img.clientWidth, 10));
    //       const height = Math.max(parseInt(img.height, 10), parseInt(img.clientHeight, 10));
    //       if (img.width > maxWidth) {
    //         const myWidth = maxWidth / img.width;
    //         const myHeight = parseInt(img.height * myWidth, 10);
    //         size = {
    //           width: maxWidth,
    //           height: myHeight,
    //         };
    //       } else if (img.height > maxHeight) {
    //         const myHeight = maxHeight / img.height;
    //         const myWidth = parseInt(img.width * myHeight, 10);
    //         size = {
    //           width: myWidth,
    //           height: maxHeight,
    //         };
    //       } else {
    //         size = {
    //           width: width,
    //           height: height,
    //         };
    //       }
    //       resolve(size);
    //     };
    //     img.onerror = (err) => {
    //       reject(err);
    //     };
    //   });
    // };

    return function (_ctx, _cache) {
      var _component_t_tooltip = _resolveComponent("t-tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.cardData.sort), 1), __props.cardData.data.title && __props.cardData.data.title !== '资源卡' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(__props.cardData.data.title), 1)])) : _createCommentVNode("", true), desc.value ? (_openBlock(), _createElementBlock("span", {
        key: 1,
        innerHTML: desc.value,
        class: "media-card-desc"
      }, null, 8, _hoisted_5)) : _createCommentVNode("", true), sourceList.value && sourceList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sourceList.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "source-item",
          onClick: function onClick($event) {
            return sourceItemClick(item);
          }
        }, [_createVNode(_unref(sourceCover), {
          data: item,
          "is-hover": ""
        }, null, 8, ["data"]), item.name.length < 10 ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.name || '未命名资源'), 1)) : (_openBlock(), _createBlock(_component_t_tooltip, {
          key: 1,
          content: item.name,
          placement: "bottom"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", null, _toDisplayString(item.name || '未命名资源'), 1)];
          }),
          _: 2
        }, 1032, ["content"]))], 8, _hoisted_7);
      }), 128))])) : _createCommentVNode("", true), state.sourceDetailShow ? (_openBlock(), _createBlock(_unref(sourceDetail), {
        key: 3,
        visible: state.sourceDetailShow,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return state.sourceDetailShow = $event;
        }),
        "source-data": state.sourceData,
        onPreview: preview
      }, null, 8, ["visible", "source-data"])) : _createCommentVNode("", true), state.resourcePreviewShow ? (_openBlock(), _createBlock(_unref(FilePreviewDialog), {
        key: 4,
        modelValue: state.resourcePreviewShow,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return state.resourcePreviewShow = $event;
        }),
        path: filePath.value,
        token: token.value,
        "source-id": sourceId.value,
        "source-name": sourceName.value
      }, null, 8, ["modelValue", "path", "token", "source-id", "source-name"])) : _createCommentVNode("", true)]);
    };
  }
};