import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "answer-text"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = ["innerHTML"];

/* 变量 */

export default {
  __name: 'answerText',
  props: {
    type: {
      type: String,
      default: ""
    },
    answer: {
      type: [String, Array]
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [props.type === '填空题' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.answer, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.sign,
          class: "completion-answer"
        }, [_createElementVNode("span", null, _toDisplayString(item.code) + "、", 1), _createElementVNode("span", {
          innerHTML: item.correct
        }, null, 8, _hoisted_3)]);
      }), 128))])) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: "other-answer",
        innerHTML: props.answer
      }, null, 8, _hoisted_4))]);
    };
  }
};