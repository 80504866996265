import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["src"];
var _hoisted_2 = {
  key: 0,
  class: "mask"
};
import { ref, onMounted } from "vue";
/* api */
import { getToolUrl } from "@/api/resources";
/* 变量 */

export default {
  __name: 'interactTool',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:visible"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var maskShow = ref(true);
    var emit = __emit;
    var path = ref("");
    var iframe = ref();
    /* 方法 */
    onMounted(function () {
      var userInfo = JSON.parse(localStorage.getItem("accessInfo")).content;
      var _props$data = __props.data,
        toolType = _props$data.toolType,
        toolBusinessId = _props$data.toolBusinessId;
      var identity = (userInfo.memberType || "").includes("students") ? 2 : 1;
      var userId = userInfo.id.toString();
      var userName = userInfo.name || '';
      var params = {
        toolType: toolType,
        toolBusinessId: toolBusinessId,
        identity: identity,
        teacherId: identity === 1 ? userId : null,
        teacherName: identity === 1 ? userName : null,
        studentId: identity === 2 ? userId : null,
        studentName: identity === 2 ? userName : null
      };
      getToolUrl(params).then(function (res) {
        if (res.data.code === 0) {
          path.value = res.data.data;
          iframeInit();
        }
      });
    });
    var hide = function hide() {
      emit("update:visible", false);
    };
    var iframeInit = function iframeInit() {
      if (iframe.value) {
        if (iframe.value.attachEvent) {
          iframe.value.attachEvent('onload', function () {
            maskShow.value = false;
          });
        } else {
          iframe.value.onload = function () {
            maskShow.value = false;
          };
        }
      }
    };
    var resize = function resize() {
      window.onresize = function () {
        iframeInit();
      };
    };
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createBlock(_component_t_dialog, {
        visible: __props.visible,
        footer: false,
        top: "0",
        width: "1344px",
        class: "interact-tool",
        onClose: hide
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("iframe", {
            ref_key: "iframe",
            ref: iframe,
            allowfullscreen: true,
            src: path.value,
            class: "iframe"
          }, null, 8, _hoisted_1), maskShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [_createElementVNode("span", null, "加载中...", -1)]))) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};