// 对会话进行排序
export function getOrderedSessions(unorderedSessions) {
  return Object.keys(unorderedSessions)
    .map((id) => unorderedSessions[id])
    .sort(sortSessions);
}

export function sortSessions(a, b) {
  if (a.isTop && !b.isTop) {
    return -1;
  } else if (!a.isTop && b.isTop) {
    return 1;
  } else {
    return b.updateTime - a.updateTime;
  }
}
