import "core-js/modules/es.array.includes.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "cover-content"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  key: 1,
  class: "cover-content source-video"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "video-mask"
};
var _hoisted_6 = {
  key: 2,
  class: "source-other"
};
var _hoisted_7 = ["src"];
import { ref, onMounted } from "vue";
import { getFileType, getImageByMimeType } from "@/views/sourcePreview/courseDetail/const/utils";
/* 变量 */
var defaultVideoPoster = "https://course-resource-prod.zhixuehuijiao.cn/b8d11bf9-dee7-40e2-94c7-ffefc5e676ba.png";
export default {
  __name: 'sourceCover',
  props: {
    // 资源数据
    data: {
      type: Object,
      default: function _default() {}
    },
    // 是否有鼠标悬浮效果
    isHover: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '70px'
    }
  },
  setup: function setup(__props) {
    /* 工具 */

    /* 变量 */
    var defFileImg = ref('');
    /* 方法 */
    var isImage = function isImage(data) {
      var suffix = getFileType(data.url) || data.formatType;
      var imageList = ["png", "jpg", "svg", "gif", "jpeg", "raw", "bmp", "tiff", "webp"];
      return imageList.includes(suffix.toLocaleLowerCase());
    };
    var isVideo = function isVideo(data) {
      var suffix = getFileType(data.url) || data.formatType;
      var videoList = ["mp4", "3gp", "avi", "mov", "flv"];
      return videoList.includes(suffix.toLocaleLowerCase());
    };
    onMounted(function () {
      var fileType = getImageByMimeType(__props.data.formatType);
      defFileImg.value = require("./filesImgs/".concat(fileType, ".png"));
    });
    return function (_ctx, _cache) {
      var _component_t_icon = _resolveComponent("t-icon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["source-cover", {
          'source-cover-hover': __props.isHover
        }]),
        style: _normalizeStyle({
          width: __props.width,
          height: __props.height
        })
      }, [isImage(__props.data) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: __props.data.url
      }, null, 8, _hoisted_2)])) : isVideo(__props.data) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("img", {
        src: __props.data.posterUrl || defaultVideoPoster
      }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createVNode(_component_t_icon, {
        name: "play-circle-stroke",
        class: "play-icon"
      })])])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("img", {
        src: defFileImg.value
      }, null, 8, _hoisted_7)]))], 6);
    };
  }
};