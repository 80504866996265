import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "user-info-body"
};
var _hoisted_2 = {
  class: "content-left"
};
var _hoisted_3 = {
  class: "user-info"
};
var _hoisted_4 = {
  class: "info-top"
};
var _hoisted_5 = {
  class: "user-name"
};
var _hoisted_6 = {
  class: "info-bottom"
};
var _hoisted_7 = {
  class: "user-school"
};
var _hoisted_8 = {
  class: "grade-list"
};
var _hoisted_9 = {
  class: "user-id"
};
import { MessagePlugin } from 'tdesign-vue-next';
import { useRouter } from "vue-router";
import Avatar from "./Avatar.vue";
export default {
  __name: 'userInfoView',
  props: {
    userInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    clickRightValue: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var baseImg = require('@/assets/home/baseImg.svg');
    var router = useRouter();
    var copyId = function copyId(id) {
      var text = document.createElement('textarea');
      text.value = id;
      document.body.appendChild(text);
      text.select();
      document.execCommand('copy');
      MessagePlugin.success('复制成功');
      text.remove();
    };
    //  个人简介
    var goToPeople = function goToPeople(val) {
      if (!val) {
        router.push('/userInfo');
      }
    };
    var textShadow = function textShadow(val) {
      if (!val) {
        if (props.clickRightValue !== 1) return '';
        return '点击此处去完善个人简介';
      } else {
        return val;
      }
    };
    return function (_ctx, _cache) {
      var _props$userInfo;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Avatar, {
        avatarUrl: __props.userInfo.avatar || _unref(baseImg),
        size: "50px"
      }, null, 8, ["avatarUrl"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(__props.userInfo.name), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.userInfo.subjects, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "subject-item"
        }, _toDisplayString(item), 1);
      }), 128))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(__props.userInfo.schoolName), 1), _createElementVNode("div", _hoisted_8, _toDisplayString((_props$userInfo = __props.userInfo) === null || _props$userInfo === void 0 ? void 0 : _props$userInfo.teachingPeriod), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, "主页ID：" + _toDisplayString(__props.userInfo.spaceAccount), 1), _createElementVNode("span", {
        style: {
          "margin-left": "8px"
        },
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return copyId(__props.userInfo.spaceAccount);
        })
      }, "复制")])])])]), _createElementVNode("div", {
        class: "introduce-body",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return goToPeople(__props.userInfo.personalProfile);
        })
      }, _toDisplayString(textShadow(__props.userInfo.personalProfile)), 1)]);
    };
  }
};