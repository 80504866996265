import { NIM } from "@yxim/nim-web-sdk";
// import { Message } from "tdesign-mobile-vue";
import { MessagePlugin } from "tdesign-vue-next";
import { chatConfig } from "@/const/chatConfig";
import { getOrderedSessions } from "@/utils/chatUtil";
import { defineStore } from "pinia";
const useNIMStore = defineStore("nim", {
  state: () => {
    return {
      nim: null,
      userId: null,
      sessionList: [],
      sessionMap: {},
      currentUserAccount: null,
      currentPassword: null,
      sysMsgs: [], // 系统通知消息
      sysMsgUnread: null, // 未读系统通知消息数
    };
  },
  getters: {
    currSessionLastMsg() {
      // 获取离当前时间最远的一条消息
      // console.log('this.currSessionMsgs', this.currSessionMsgs)
      return (this.currSessionMsgs && this.currSessionMsgs[0]) || {};
    },
  },
  actions: {
    // 登录chat
    loginChat(account, token) {
      if (!this.nim) {
        if (account && token) {
          console.log(account, token);
          this.initChat(account, token);
        } else {
          MessagePlugin.error("当前用户没有云信账号或密码,请联系管理员");
        }
      }
    },
    logoutChat() {
      if (this.nim) {
        this.nim.disconnect();
        this.nim.destroy();
        this.nim = null;
        this.sessionList = [];
        this.sessionMap = {};
      }
    },
    initChat(account, token) {
      this.currentUserAccount = account;
      this.currentPassword = token;
      this.nim = NIM.getInstance({
        debug: false,
        appKey: chatConfig.test.appKey,
        account: account,
        token: token,
        db: true,
        authType: 0, // token的认证类型，0：token是固定的， 只有主动调用接口修改才会更改，1：token是动态的，有过期事件，token过期后已登录状态的链接不受影响，但是之后的登录需要信token，对于自动重连场景，开发者可在onwillreconnect中，判断token是否过期，若过期可调用setOptions更新token
        syncSessionUnread: true, // 是否同步会话的未读数, 默认不同步
        rollbackDelMsgUnread: true, // 是否回滚删除的消息的未读数，默认不同步 比如有两条未读消息。消息发送方撤回了其中一条。如果该参数为 false，则撤回后，未读数为2。如果该参数为 true，则撤回后，未读数为1
        reconnectionAttempts: 3, // 自动重连次数
        customClientType: 10, // 自定义客户端类型，大于0的整数；可实现自定义多端登录策略，具体策略可在云信管理后台配置
        onconnect: this.onConnect,
        onwillreconnect: this.onWillReconnect,
        ondisconnect: this.onDisconnect,
        onerror: this.onError,
        onloginportschange: this.onLoginPortsChange, // 多端登录状态变化的回调，可获取登录端列表
        // 会话
        onsessions: this.onSessions,
        onupdatesessions: this.onUpdateSessions,
        // 系统通知
        onsysmsgunread: this.onSysMsgUnread, // 系统通知未读数更新
        onupdatesysmsgunread: this.onUpdateSysMsgUnread, // 系统通知未读数更新
        onsyncdone: this.onSyncDone,
      });
      this.nim.connect();
    },
    onConnect() {
      console.log("连接成功");
    },
    onWillReconnect(obj) {
      // 此时说明 SDK 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
      console.log("即将重连", obj);
    },
    onDisconnect(error) {
      // 此时说明 SDK 处于断开状态, 开发者此时应该根据错误码提示相应的错误信息, 并且跳转到登录页面
      console.log("丢失连接");
      console.log(error);
      // if (error.code === 'logout') {
      //   this.initChat(this.currentUserAccount, this.currentPassword)
      // }
      MessagePlugin.error(error.message);
      if (error) {
        switch (error.code) {
          // 账号或者密码错误, 请跳转到登录页面并提示错误
          case 302:
            break;
          // 重复登录, 已经在其它端登录了, 请跳转到登录页面并提示错误
          case 417:
            break;
          // 被踢, 请提示错误后跳转到登录页面
          case "kicked":
            break;
          default:
            break;
        }
      }
    },
    onerror(error) {
      console.log(error, 146);
    },
    dealErrorSocket(error, obj) {
      console.log("socket错误", error, obj);
    },
    onLoginPortsChange() {},
    /**
     * 初始化阶段收到会话列表
     * 该回调函数返回的会话不包含isTop属性
     */
    onSessions(sessions) {
      // console.log('sessions', sessions)
      for (const session of sessions) {
        this.sessionMap[session.id] = session;
      }
      this.sessionList = getOrderedSessions(this.sessionMap);
    },
    /**
     * 会话更新，sessionArr中的msgReceiptTime属性为对端最后一条已读回执的事件
     * 根据msgReceiptTime，在渲染段判断每条消息是否已读
     */
    onUpdateSessions(sessions) {
      // console.log('会话更新了', sessions)
      for (const session of sessions) {
        this.sessionMap[session.id] = session;
        // if (session.id === this.currSessionId) {
        // }
      }
      this.sessionList = getOrderedSessions(this.sessionMap);
      // 标记会话中每条消息的已读回执是否收到
    },
    onSysMsgUnread(obj) {
      console.log("系统通知未读数", obj);
      this.sysMsgUnread = obj;
    },
    onUpdateSysMsgUnread(obj) {
      console.log("系统通知未读数更新", obj);
      this.sysMsgUnread = obj;
    },
    // 在同步结束后，利用会话的msgReceiptTime字段，设置消息的已读回执状态
    async onSyncDone() {
      console.log("同步完成");
      // try {
      //   // 初始化时获取本地会话信息
      //   if (!this.syncInitialized) {
      //     this.syncInitialized = true;
      //     const res = await this.getLatestNLocalSessions(200);
      //     for (const session of res.sessions) {
      //       this.sessionMap[session.id] = session;
      //     }
      //     this.sessionList = getOrderedSessions(this.sessionMap);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    getAllContactUsers(accIdsChunk) {
      return new Promise((resolve, reject) => {
        this.nim.getUsers({
          accounts: accIdsChunk,
          done: (error, users) => {
            if (!error) {
              resolve(users);
            } else {
              reject(error);
            }
          },
        });
      });
    },
  },
});

export default useNIMStore;
