import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../icons/textImageCard.png';
var _hoisted_1 = {
  class: "image-text-card card media-card"
};
var _hoisted_2 = {
  class: "media-card-serial"
};
var _hoisted_3 = {
  key: 0,
  class: "media-card-title"
};
var _hoisted_4 = {
  class: "card-title"
};
var _hoisted_5 = {
  class: "card-content"
};
var _hoisted_6 = ["innerHTML"];
import { computed } from "vue";
import { utils } from "@/views/sourcePreview/courseDetail/const/utils";

/* 变量 */

export default {
  __name: 'imageTextCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */

    /* 计算属性 */
    var html = computed(function () {
      var item = "";
      if (!utils.isEmpty(__props.cardData.data) && utils.isString(__props.cardData.data.item)) {
        var _JSON$parse = JSON.parse(__props.cardData.data.item),
          content = _JSON$parse.content;
        item = content;
      }
      return item;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.cardData.sort), 1), __props.cardData.data.title && __props.cardData.data.title !== '图文卡' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(__props.cardData.data.title), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "preview",
        innerHTML: html.value
      }, null, 8, _hoisted_6)])]);
    };
  }
};