import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
import _imports_0 from '@/assets/paper/icon-left-arrow.svg';
var _hoisted_1 = {
  class: "navbar"
};
var _hoisted_2 = {
  class: "navbar-left"
};
var _hoisted_3 = {
  key: 1,
  class: "navbar-left-text"
};
var _hoisted_4 = {
  key: 0,
  class: "navbar-right"
};
import { useRouter } from "vue-router";
import svgIcon from "./svgIcon.vue";

/**
 * 顶部公共头部导航组件
 */

export default {
  __name: 'navBar',
  props: {
    // 左侧icon是否显示
    leftIconShow: {
      type: Boolean,
      default: true
    },
    // 左侧文字
    leftText: {
      type: String,
      default: ""
    },
    // 是否返回上一页
    isBack: {
      type: Boolean,
      default: true
    }
  },
  emits: ["back"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var router = useRouter();
    var onClickBack = function onClickBack() {
      if (props.isBack) {
        router.go(-1);
      }
      emit("back");
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.leftIconShow ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_0,
        class: "navbar-left-icon",
        alt: "",
        onClick: onClickBack
      })) : _createCommentVNode("", true), __props.leftText ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.leftText), 1)) : _createCommentVNode("", true)]), _ctx.$slots.right ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "right")])) : _createCommentVNode("", true)]);
    };
  }
};