export const EXERCISE_CODE_MAP = {
  'SINGLE_CHOICE': '单选题',
  'MULTIPLE_CHOICE': '多选题',
  'JUDGE': '判断题',
  'COMPLETION': '填空题',
  'ANSWER_QUESTIONS': '简答题',
  'COMPREHENSIVE': '综合题',
}

export const EXERCISE_NAME_MAP = {
  '单选题': 'SINGLE_CHOICE',
  '多选题': 'MULTIPLE_CHOICE',
  '判断题': 'JUDGE',
  '填空题': 'COMPLETION',
  '简答题': 'ANSWER_QUESTIONS',
  '综合题': 'COMPREHENSIVE',
}

export const SINGLE_CHOICE_DATA = {
  exercise: {
    id: 1,
    type: "SINGLE_CHOICE",
    content:
      "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
    analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
    options: [
      {
        sign: "1",
        code: "A",
        content: "选项1",
        isCorrect: false,
      },
      {
        sign: "2",
        code: "B",
        content: "选项2",
        isCorrect: true,
      },
      {
        sign: "3",
        code: "C",
        content: "选项3",
        isCorrect: false,
      },
      {
        sign: "4",
        code: "D",
        content: "选项4",
        isCorrect: false,
      },
    ],
  },
  knowledgePoints: "我是知识点",
  cultivateQualities: "我是培养品质",
}

export const JUDGE_DATA = {
  exercise: {
    id: 1,
    type: "JUDGE",
    content:
      "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
    analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
    answer: {
      sign: '1',
      answer: true
    }
  },
  knowledgePoints: "我是知识点",
  cultivateQualities: "我是培养品质",
}

export const COMPLETION_DATA = {
  exercise: {
    id: 1,
    type: "COMPLETION",
    content:
      "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
    analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
    fillBlanks: [
      {
        sign: '1',
        code: '1',
        correct: '我是第一个空答案'
      },
      {
        sign: '2',
        code: '2',
        correct: '我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案'
      },
      {
        sign: '3',
        code: '3',
        correct: '我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案'
      },
    ]
  },
  knowledgePoints: "我是知识点",
  cultivateQualities: "我是培养品质",
}

export const ANSWER_QUESTIONS_DATA = {
  exercise: {
    id: 1,
    type: "ANSWER_QUESTIONS",
    content:
      "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
    analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
    answer: {
      sign: '1',
      answer: '简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下'
    }
  },
  knowledgePoints: "我是知识点",
  cultivateQualities: "我是培养品质",
}

export const COMPREHENSIVE_DATA = {
  exercise: {
    id: 1,
    type: "COMPREHENSIVE",
    content:
      "我是综合题",
    analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
    childs: [
      {
        id: 2,
        type: "SINGLE_CHOICE",
        score: 1,
        content:
          "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
        analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
        options: [
          {
            sign: "1",
            code: "A",
            content: "选项1",
            isCorrect: false,
          },
          {
            sign: "2",
            code: "B",
            content: "选项2",
            isCorrect: true,
          },
          {
            sign: "3",
            code: "C",
            content: "选项3",
            isCorrect: false,
          },
          {
            sign: "4",
            code: "D",
            content: "选项4",
            isCorrect: false,
          },
        ],
      },
      {
        id: 3,
        type: "JUDGE",
        score: 1,
        content:
          "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
        analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
        answer: {
          sign: '1',
          answer: true
        }
      },
      {
        id: 4,
        type: "COMPLETION",
        score: 1,
        content:
          "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
        analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
        fillBlanks: [
          {
            sign: '1',
            code: '1',
            correct: '我是第一个空答案'
          },
          {
            sign: '2',
            code: '2',
            correct: '我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案我是第二个空答案'
          },
          {
            sign: '3',
            code: '3',
            correct: '我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案我是第三个空答案'
          },
        ]
      },
      {
        id: 5,
        type: "ANSWER_QUESTIONS",
        score: 2,
        content:
          "下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是下列诗句与注释不一致的一项是（  ）",
        analysis: "我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析我是解析",
        answer: {
          sign: '1',
          answer: '简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下简答一下'
        }
      }
    ]
  },
  knowledgePoints: "我是知识点",
  cultivateQualities: "我是培养品质",
}
