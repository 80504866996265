/**
 * 双向绑定
 */
import { computed } from 'vue'
export const useModel = ({ props, key, emit }) => {
    return computed({
      get() {
        return props[key]
      },
      set(val) {
        emit(`update:${key.toString()}`, val)
      },
    })
  }
  