import "core-js/modules/es.array.sort.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "task-card card"
};
var _hoisted_2 = {
  class: "serial"
};

/* 变量 */

export default {
  __name: 'taskCard',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.cardData.sort), 1), _createElementVNode("h3", null, _toDisplayString(__props.cardData.data.title), 1)]);
    };
  }
};