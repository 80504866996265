import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from './arrow.svg';
var _hoisted_1 = {
  class: "left-bar"
};
var _hoisted_2 = {
  key: 0,
  class: "quality"
};
var _hoisted_3 = {
  class: "quality-item"
};
var _hoisted_4 = {
  key: 1,
  class: "knowledge"
};
var _hoisted_5 = {
  class: "tags"
};
import { toRefs, reactive, computed, onMounted } from "vue";
import { formatBytes } from '@/util/util';

/* 变量 */

export default {
  __name: 'leftBar',
  props: {
    data: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    /* 工具 */
    var props = __props;
    var _toRefs = toRefs(props),
      data = _toRefs.data;
    var state = reactive({
      isHide: true
    });

    /* 计算属性 */
    var showQuality = computed(function () {
      return data.value.indexCultivateQuality && data.value.indexCultivateQuality.length > 0;
    });
    var showKnowledge = computed(function () {
      return data.value.indexKnowledgeList && data.value.indexKnowledgeList.length > 0;
    });

    /* 事件 */

    /* 钩子 */
    onMounted(function () {});
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(["panel", {
          hide: state.isHide
        }])
      }, [showQuality.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "培养品质：", -1)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).indexCultivateQuality, function (item, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index
        }, _toDisplayString(item.quality), 1);
      }), 128))])])) : _createCommentVNode("", true), showKnowledge.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).indexKnowledgeList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "knowledge-item"
        }, [_createElementVNode("span", null, "知识点" + _toDisplayString(index + 1) + "：", 1), _createElementVNode("span", null, _toDisplayString(item.knowledgeName) + "（" + _toDisplayString(item.cognitiveGoal) + "，" + _toDisplayString(item.subjectAccomplishment) + "）", 1)]);
      }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_unref(data).resLevel || 'T'), 1), _createElementVNode("span", null, _toDisplayString(_unref(data).fileType), 1), _createElementVNode("span", null, "大小：" + _toDisplayString(_unref(formatBytes)(_unref(data).size)), 1)])], 2), _createElementVNode("div", {
        class: _normalizeClass(["arrow", {
          rotate: state.isHide
        }]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return state.isHide = !state.isHide;
        })
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]), 2)]);
    };
  }
};